import React, {useEffect} from "react";
import ReactDOM from "react-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import cl from "./Payment.module.sass";
import { useAuth } from "../../hooks/useAuth";
import {
  PaymentPage,
  Title,
  NavLink,
  Hr,
  SubTitle,
} from "./style";
import Container from "../../components/ui/ui kit/Container";
import CardForm from "../../components/ui/ui kit/CardForm";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Route } from "react-router-dom";


const stripePromise = loadStripe("pk_test_51KP6TQDxbT4ngxXn19MQhwIOq4Nby3eumUoSdTnRdclWiLlL2HulP4ZIIzJ1SqfkNOuMEEhhMVX2A7B9OHF1Xrzj006YM8A83L");

export const Payment = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }, []);

  const { dataDataForm, loadingDataForm, errorDataForm } = useTypedSelector(
    (state) => state.dataForm
  );
  const isAuth = useAuth();
  
  let price = 0;
  if (dataDataForm){
    price = dataDataForm["price"];
  }

  // useEffect(() => {
  //   window.location.href = "https://buy.stripe.com/test_5kAbJe05U0LI8qQ001";
  // }, []);

  return (

    <Container>
      {isAuth && (
        <PaymentPage>
          <div className={cl["title_wrap"]}>
            <Title>LEI Registration - Checkout</Title>
            {/* <div className={cl["title_inner-button"]}>
              <NavLink to="/relationship">
                <button className={cl["button_back"]}>
                  <NextIcon className={cl["button_next-rotate"]} />
                  <span className={cl["button_text"]}>Back</span>
                </button>
              </NavLink>
              <NavLink to="/records">
                <button className={cl["button_cancel"]}>
                  <CancelIcon />
                  <span className={cl["button_text"]}>Cancel</span>
                </button>
              </NavLink>
            </div> */}
          </div>
          <Hr/>
          <div className={cl["card_wrap"]}>
            <div className={cl["card_body"]}>
              <span className={cl["payment_text"]}>€{price} payment for LEI registration</span>
              <Elements stripe={stripePromise}>
                <CardForm price={price}/>
              </Elements>
            </div>
          </div>

          <Hr />
            <div className={cl["title_inner-button"]}>
              <NavLink to="/relationship">
                <button className={cl["button_back"]}>
                  <NextIcon className={cl["button_next-rotate"]} />
                  <span className={cl["button_text"]}>Back</span>
                </button>
              </NavLink>
              <NavLink to="/records">
                <button className={cl["button_cancel"]}>
                  <CancelIcon />
                  <span className={cl["button_text"]}>Cancel</span>
                </button>
              </NavLink>
            </div>
        </PaymentPage>
      )}
    </Container>
  ); 
};