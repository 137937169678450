import { INavigateRoute, IRoute } from "../types/router";
import paths from "../constants/paths";

import Home from "../pages/Home";
import { Navigate } from "react-router-dom";
import ErrorPage from "../pages/Error";
import Contacts from "../pages/Contacts";
import Registration from "../pages/Registration";
import Authorization from "../pages/Authorization";
import GoogleOAuth from "../pages/Google";
import Search from "../pages/Search";
import Services from "../pages/Services";
import Faq from "../pages/Faq";
import Records from "../pages/Records";
import RegistrationLei from "../pages/RegistrationLei";
import RegistrationSubmit from "../pages/RegistrationSubmit";
import Settings from "../pages/Settings";
import Orders from "../pages/Orders";
import LoadedLei from "../pages/LoadedLei";
import Lei from "../pages/Lei";
// import Address from "../pages/Lei/components/Address";
// import Information from "../pages/Lei/components/Informatrion";
import Payment from "../pages/Payment";
import Lerd from "../pages/Lerd";
import OrderDetails from "../pages/OrderDetails";
import OrderAddress from "../pages/OrderDetails/components/OrderAddress";
import OrderInformation from "../pages/OrderDetails/components/OrderInformation";
import RecordDetails from "../pages/RecordDetails";
import RecordAddress from "../pages/RecordDetails/components/RecordAddress";
import RecordInformation from "../pages/RecordDetails/components/RecordInformation";
import PasswordReset from "../pages/PasswordReset";
import TransferLei from "../pages/TransferLei";
import TransferRequest from "../pages/TransferRequest";
import RenewLei from "../pages/RenewLei";


export const privateRoutes: IRoute[] = [
  {
    component: Orders,
    path: paths.REGISTRATION,
    exact: true,
  },
  {
    component: Orders,
    path: paths.AUTHORIZATION,
    exact: true,
  },
  {
    component: Settings,
    path: paths.PASSWORD_RESET,
    exact: true,
  },
  {
    component: Records,
    path: paths.CARDS_ROUTES.userRecords,
    exact: true,
  },
  {
    component: Orders,
    path: paths.CARDS_ROUTES.userOrders,
    exact: true,
  },
  {
    component: RegistrationLei,
    path: paths.CREATE_LEI,
    exact: true,
  },
  {
    component: RenewLei,
    path: paths.RENEW_LEI,
    exact: true,
  },
  {
    component: TransferLei,
    path: paths.TRANSFER_LEI,
    exact: true,
  },
  {
    component: TransferRequest,
    path: paths.TRANSFER_LEI_REQUEST,
    exact: true,
  },
  {
    component: RegistrationSubmit,
    path: paths.REGISTER_RELATIONSHIP,
    exact: true,
  },
  {
    component: Settings,
    path: paths.SETTINGS,
    exact: true,
  },
  {
    component: LoadedLei,
    path: paths.LOADED_LEI,
    exact: true,
  },
  {
    component: Payment,
    path: paths.PAYMENT,
    exact: true,
  },
  {
    component: Lerd,
    path: paths.LERD,
    exact: true,
  },
];

export const publicRoutes: IRoute[] = [
  /* Routing of private pages when user is not logged in */
  {
    component: Authorization,
    path: paths.CARDS_ROUTES.userRecords,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.CARDS_ROUTES.userOrders,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.CREATE_LEI,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.RENEW_LEI,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.TRANSFER_LEI,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.TRANSFER_LEI_REQUEST,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.REGISTER_RELATIONSHIP,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.SETTINGS,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.LOADED_LEI,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.PAYMENT,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.LERD,
    exact: true,
  },
  /* Regular Public Pages */
  {
    component: Contacts,
    path: paths.CONTACTS,
    exact: true,
  },
  {
    component: Registration,
    path: paths.REGISTRATION,
    exact: true,
  },
  {
    component: Authorization,
    path: paths.AUTHORIZATION,
    exact: true,
  },
  {
    component: PasswordReset,
    path: paths.PASSWORD_RESET,
    exact: true,
  },
  {
    component: GoogleOAuth,
    path: paths.GOOGLE_OAUTH,
    exact: true,
  },
];

export const commonRoutes: IRoute[] = [
  {
    component: Home,
    path: paths.HOME,
    exact: true,
  },
  {
    component: Search,
    path: paths.SEARCH,
    exact: true,
  },
  {
    component: Services,
    path: paths.SERVICES,
    exact: true,
  },
  {
    component: Faq,
    path: paths.FAQ,
    exact: true,
  },
  {
    component: Navigate,
    path: "/",
    exact: true,
    props: {
      to: paths.HOME,
    },
  } as INavigateRoute,
  {
    component: Lei,
    path: "/lei-details/:lei",
    exact: true,
    // nestedRoutes: [
    //   {
    //     component: Address, 
    //     path: "address",
    //     exact: true
    //   },
    //   {
    //     component: Information, 
    //     path: "information",
    //     exact: true
    //   }
    // ]
  },  
  {
    component: OrderDetails,
    path: "/order-details/:lei",
    exact: true,
    nestedRoutes: [
      {
        component: OrderAddress, 
        path: "address",
        exact: true
      },
      {
        component: OrderInformation, 
        path: "information",
        exact: true
      }
    ]
  },
  {
    component: RecordDetails,
    path: "/record-details/:lei",
    exact: true,
    nestedRoutes: [
      {
        component: RecordAddress, 
        path: "address",
        exact: true
      },
      {
        component: RecordInformation, 
        path: "information",
        exact: true
      }
    ]
  },
  //This element must always be last in the array!!!
  {
    component: ErrorPage,
    path: "*",
    exact: true,
  },
];
