import React, { useEffect} from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import cl from "./Home.module.sass";
import Container from "../../components/ui/ui kit/Container";
// import { ReactComponent as LeiPapaIcon } from "../../assets/icons/home/leiPapa.svg";
// import { ReactComponent as GLEIFlogo } from "../../assets/icons/home/gleif-logo.svg";
// import { ReactComponent as RapidLEIlogo } from "../../assets/icons/home/rapidlei-ubisecure-logo.svg";
import { HomePage, Title, TextLarge } from "./style";
import { Card } from "../Cards/Cards";

export const Home = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const location = useLocation();
  const pageUrl = window.location.origin + location.pathname;
  return (
    <Container>
      <Helmet>
        {/* When making changes here, make also changes to server.js file accordingly */}
        <title>Register an LEI code | LEIpapa Registration Agent</title>
        <meta 
          name="description" 
          content="Register an LEI code, get LEI verified, LEI digital certificate, LEI number for company and fund. LEIpapa – official LEI Registration Agent Ubisecure RapidLEI. " 
        />
        <meta name="keywords" content="Register LEI code, Get an LEI, LEI Search, LEI identifier, LEIpapa, Renew LEI code, Transfer LEI, Registration Agent Estonia" />
        <link rel="canonical" href="https://app.leipapa.com/" />
        {/* OpenGraph */}
        <meta property="og:title" content="LEIpapa Web Application" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://app.leipapa.com/leipapa.png" />
        <meta property="og:url" content="https://app.leipapa.com" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta name="twitter:site" content="@leipapa_com" />
        <meta name="twitter:title" content="LEIpapa Web Application" />
        <meta name="twitter:description" content="Register an LEI with LEIpapa, the official LEI Registration Agent of Ubisecure RapidLEI." />
        <meta name="twitter:image" content="https://app.leipapa.com/leipapa.png" />
              
      </Helmet>
      <HomePage>
        <Title>Register an LEI code with LEIpapa</Title>
        <p className={cl["description_centered"]}>The first LEI Registration Agent with the Web 3.0 approach</p>
        <Card/>
        <TextLarge>
          <div>
            <p className={cl["text_tab"]}>About LEIpapa</p>
          </div>
           <div className={cl["text__large"]}>
            <div>
              <p>
                LEIpapa is a European tech company headquartered in Tallinn, Estonia 🇪🇪 controlled 
                by a team experienced in fintech project development 
                (crypto projects, alternative funds, KYC automation, retail management), 
                with a strong focus on customer success and life-long customer care. 
                LEIpapa is in the business since 2021 guiding LEI applicants 
                (from micro-organizations to corporations) through the world of Legal Entity Identifiers (LEIs). <a href="https://leipapa.com/about/" rel="noopener noreferrer" title="More information about LEIpapa" target="_blank">More information about LEIpapa...</a>
              </p>
            </div>
            <div className={cl["text"]}>
              <p>
                <a href="https://leipapa.com/docs/what-is-an-lei-number/" rel="noopener noreferrer" title="What is an LEI code?" target="_blank">What is an LEI code?</a> Briefly, 
                an LEI code (LEI number) is used for the legal entity identification in
                regulatory reporting and supervision. The LEI code is a unique
                20-character alphanumeric code for legal entities participating
                in the financial markets.
              </p>
            </div>
            <div className={cl["text"]}>
              <p>
                All legal entities who are participating in the financial
                markets are obliged to have an LEI code starting from January 3,
                2018. <a href="https://leipapa.com/docs/who-needs-an-lei-number/" title="Who needs an LEI number" target="_blank" rel="noopener noreferrer">Get to know who needs an LEI number</a>.
              </p>
            </div>
          </div>
        </TextLarge>

        <TextLarge>
          <div>
            <p className={cl["text_tab"]}>Permission</p>
          </div>
          <div>
            <div className={cl["text__large"]}>
              <p>
              LEIpapa is the first LEI Registration Agent with the <a href="https://leipapa.com/get-free-nft-lei-certificate/" title="Introducing LEI NFT" target="_blank" rel="noreferrer">Web 3.0 approach</a> working
              on the basis of the license agreement concluded 
              with RapidLEI (Ubisecure OY, LEI <a href="/lei-details/529900T8BM49AURSDO55" title="RapidLEI Ubisecure Oy LEI reference Data" target="_blank" rel="nofollow noreferrer">529900T8BM49AURSDO55</a>) and GLEIF (Global Legal Entity Identifier Foundation, LEI <a href="/lei-details/506700GE1G29325QX363" title="GLEIF LEI Reference Data" target="_blank" rel="nofollow noreferrer">506700GE1G29325QX363</a>) providing fast and affordable LEI registration assistance for legal entities. 
              As an official LEI Registration Agent of Ubisecure RapidLEI, LEIpapa provides assistance and performs certain tasks in the process of obtaining, renewing, or transferring of an LEI for an LEI Issuer (RapidLEI, Ubisecure OY), but the issuing, transferring or renewing of the LEI is always done by the LEI Issuer. 
              The <a href="https://leipapa.com/lei-pricing/" rel="nofollow noreferrer" title="LEI pricing" target="_blank">LEI pricing</a> varies 
              for some jurisdictions, so please check the total LEI fee during the checkout process.
              </p>
            </div>
            {/* <div className={cl["img"]}>
              <a className={cl["img_position"]} href="//leipapa.com" title="LEIpapa"><LeiPapaIcon /></a>
              <a className={cl["img_position"]} rel="nofollow" href="//gleif.org" target="_blank" title="GLEIF"><GLEIFlogo /></a>
              <a className={cl["img_position"]} rel="nofollow" href="//rapidlei.com" target="_blank" title="RapidLEI"><RapidLEIlogo /></a>
            </div> */}
          </div>
        </TextLarge>

        <TextLarge>
          <div>
            <p className={cl["text_tab"]}>LEI & Regulation</p>
          </div>
          <div className={cl["text__large"]}>
          <div>
            <p>
            Legal entities that have been assigned the right to provide investment services are obliged to notify surveillance authorities of transactions made with the securities of a legal entity client by using the LEI code. In case a legal entity client does not have an LEI code, the reporting obligation cannot be performed and as a result the transaction orders of such legal entities cannot be fulfilled.
            </p>
          </div>
          <div className={cl["text"]}>
            <p>
            In accordance with the European regulation, the LEI code must be used to identify the contracting parties to financial market transactions worldwide and make it easier for regulators to recognize possible systemic risks at an early stage. The ultimate aim is to better manage financial risks.
            </p>
          </div>
          </div>
        </TextLarge>

      </HomePage>
    </Container>
  );
};
