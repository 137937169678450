import React, {useEffect} from "react"
import cl from "./OrderAddress.module.css"
import { useOutletContext } from "react-router-dom"
import Loader from "../../../../components/ui/Loader"
import { Helmet } from "react-helmet"

export const OrderAddress = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
      }, []);  

    const context:any = useOutletContext()
    const ctx = context.orderData

    if(!ctx) return <Loader />
    return <div className={cl["wrapper"]}>

        <Helmet> {/* Add the Helmet component */}
            <title>Address | Order {ctx.order_tracking_code} LEI {ctx.lei_number}</title>
            <meta 
            name="description" 
            content={`Address for LEI ${ctx.lei_number}, Order ${ctx.order_tracking_code}, legal name ${ctx.company_name}, Entity status ${ctx.entity_status}, Next renewal date ${ctx.next_renewal_date.slice(0, 19).replace("T", " ")}`} 
            />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet> 

        <div className={cl["card"]}>
            <div className={cl["header"]}><h3>Legal Address</h3></div>
            <ul>
                <li>{ctx.legal_address_street + " " + (ctx.legal_address_house || "") + " " + (ctx.legal_address_suite || "")}</li>
                <li>{ctx.legal_address_city + " " + ctx.legal_address_postal_code}</li>
                <li>{ctx.legal_address_country}</li>
            </ul>
        </div>  
        <div className={cl["card"]}>
            <div className={cl["header"]}><h3>Headquaters Address</h3></div>
            <ul>
                <li>{ctx.headquarter_address_street + " " + (ctx.headquarter_address_house || "") + " " + (ctx.headquarter_address_suite || "")}</li>
                <li>{ctx.headquarter_address_city + " " + ctx.headquarter_address_postal_code}</li>
                <li>{ctx.headquarter_address_country}</li>
            </ul>
        </div>        
    </div>
}