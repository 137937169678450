import cl from "./Authorization.module.sass"
//import { Title } from "./style"
import AuthorizationForm from "../../components/functional/AuthorizationForm"
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export const Authorization = () => {
    const location = useLocation();
    const pageUrl = window.location.origin + location.pathname;

    return (
        //<div className={cl["page"]}>
            <div>
                <Helmet>
                {/* When making changes here, make also changes to server.js file accordingly */}
                <title>Log In to the LEIpapa account</title>
                <meta name="description" content="Log In to the LEIpapa account to apply for an LEI, renew your existing LEI, or transfer your LEI to LEIpapa" />
                <link rel="canonical" href={pageUrl} />
                {/* OpenGraph */}
                <meta property="og:title" content="Log In to the account at LEIpapa" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://app.leipapa.com/leipapa.png" />
                <meta property="og:url" content={pageUrl} />
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" /> 
                <meta name="twitter:site" content="@leipapa_com" />
                <meta name="twitter:title" content="Log In to the account at LEIpapa" />
                <meta name="twitter:description" content="Log In to the Account at LEIpapa to apply for, renew, or transfer an LEI" />
                <meta name="twitter:image" content="https://app.leipapa.com/leipapa.png" />
                </Helmet>
                <AuthorizationForm />
            </div>
        //</div>
    )
}