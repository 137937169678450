import { theme } from "../../../../constants/theme";
import styled from "styled-components";

export const InputStyled = styled.input<{ color?: string }>`
  text-indent: 0.4em;
  opacity: 0.9;
  border: 1px solid #ced4da;
  color: #ffffff;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  color: ${theme.colors.input.black};
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  background-clip: padding-box;
  display: block;
  width: 100%;
  &:focus {
    // border: 1px solid ${(props) => props.color || theme.colors.blue.normal};
  }
`;


