import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const LoadingIconStyled = styled.div`
  border: 0.1em solid rgba(255, 2555, 255, 0.5);
  border-top: 0.1em solid #767676;
  border-radius: 50%;
  width: 1.15rem;
  height: 1.15rem;
  animation: ${spin} 0.6s linear infinite;
  position: relative;
`