import axios from "axios";

const $api = axios.create({
  // baseURL: "http://143.198.183.54:8000/api/v1",
  // baseURL: "http://127.0.0.1:8000/api/v1",
  // baseURL: "http://188.225.31.87:8000/api/v1",
  baseURL: "https://data.leipapa.com/api/v1",
});

export const $leiApi = axios.create({
  baseURL: "https://api.gleif.org/api/v1/",
  headers: {
    'Content-Type': 'application/vnd.api+json'
  },
})

$api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token && config.headers) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

export default $api;
