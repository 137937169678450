import React, { useCallback } from "react";
import {
  Title,
  FaqPage,
  SubTitle,
  List,
  Li,
  NavLink
} from "./style";
import cl from "./Faq.module.scss";
import Container from "../../components/ui/ui kit/Container";


export const Faq = () => {
  
  return (
    <Container>
      <FaqPage>
        <Title>FAQ</Title>
        {/* <SubTitle>
            Search for a Legal Entity Identifier code. Users can search within
            whole LEI database. Searches can be performed by Legal Entity, LEI
            code and several other values.
          <br /><br />
          <span className={cl["subtitle__text-bottom"]}>To initiate transfer</span>
        </SubTitle>
        <List>
          <Li>Search here for the company's LEI code</Li>
          <Li>Click on the LEI code</Li>
          <Li>Choose "Transfer" or "Transfer and Renew".</Li>
          <Li>
            The application will open, correct information where necessary,
            choose "Next" until the application is completed and can be
            submitted.
          </Li>
          <Li>
            If "Transfer and Renew" was chosen, pay for the renewal. A transfer
            without renewal is free of charge
          </Li>
          <Li>
            We will process the application within 2 business days and contact
            you for further information
          </Li>
        </List> */}
      </FaqPage>
    </Container>
  );
};
