import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Title,
  SearchPage,
  SubTitle,
  NavLink
} from "./style";
import cl from "./Search.module.scss";
import Container from "../../components/ui/ui kit/Container";
import { SearchLeiCode } from "../../api/services/search-lei";
import SearchLei from "../../components/functional/SearchLei";
import { ILei } from "../../types/lei";
import { transformLei } from "../../utils/lei";
import { joinClasses } from "../../utils/joinClasses";
import AdSearch from "../../components/functional/Ads/Search/AdSearch";


export const Search = () => {
  const requestLei = useCallback(async (page: number) => {
    const res = await SearchLeiCode.requestSearchLeiCode(page)
    const lei = transformLei(res.data.data)
    return lei
  }, [])
  const location = useLocation();
  const pageUrl = window.location.origin + location.pathname;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  const renderLei = (leis: ILei[]) => {
    return leis.map(
      ({ lei, name, country, status, date }, index) => (
        <div className={cl["wrap__items"]} key={`${index}`}>
          <div className={[cl["line"], cl["child__line"]].join(" ")}>
            <NavLink to={`/lei-details/${lei}`} className={[cl["item"], cl["lei"]].join(" ")}>{lei}</NavLink>
            <div className={cl["item-name"]}>{name}</div>
            <div className={cl["item"]}>{country}</div>
            <div className={joinClasses(cl["item"], cl["status"], !(status === "ISSUED") ? cl["status-rejected"] : "") }>{status ? status: 'null'}</div>
            <div className={cl["item"]}>{date.slice(0, 19).replace("T", " ")}</div>
          </div>
        </div>
      )
    )
  }
  return (
    <Container>
      <Helmet>
        {/* When making changes here, make also changes to server.js file accordingly */}
        <title>Search LEI Records with LEI Search at LEIpapa</title>
        <meta name="description" content="Search LEI Records with LEIpapa LEI Search module. Users can search LEI within the whole LEI database. Search by Legal Entity or LEI code." />
        <link rel="canonical" href={pageUrl} />
        {/* OpenGraph */}
        <meta property="og:title" content="Search LEI Records with LEI Search at LEIpapa" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://app.leipapa.com/leipapa.png" />
        <meta property="og:url" content={pageUrl} />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta name="twitter:site" content="@leipapa_com" />
        <meta name="twitter:title" content="Search LEI Records with LEI Search at LEIpapa" />
        <meta name="twitter:description" content="Search LEI Records with LEIpapa LEI Search module. Users can search LEI within the whole LEI database. Search by Legal Entity or LEI code." />
        <meta name="twitter:image" content="https://app.leipapa.com/leipapa.png" />
      </Helmet>
      <SearchPage>
        <div className={cl["title_wrap"]}>
          <Title>Search for LEI Records</Title>
        </div>
        <SubTitle>
          Search for an LEI record. Users can search within the whole LEI database. <br />
          Searches can be performed by Legal Entity, LEI code, and several other values. <br />
          LEI codes that have "LAPSED" status for more than 1 year may not be visible in the search.
          {/* <span className={cl["subtitle__text-bottom"]}>To initiate transfer:</span> */}
        </SubTitle>
      <SearchLei
        titles={["LEI", "Legal Name", "Country", "Status", "Next renewal date"]}
        renderedLei={renderLei}
        isPagination={true}
        requestLeis={requestLei}
        enableInput={true}
      />
      <hr className={cl["mt-8"]}/>
      <AdSearch />
      
      </SearchPage>
    </Container>
  );
};
