import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';
import { theme } from "../../constants/theme";

export const RecordsPage = styled.div`
    max-width: 100%;
    color: ${theme.colors.input.black};
`

export const Title = styled.h1`
    max-width: 960px;
    display: contents;  
    margin: 2rem auto 0;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    // line-height: 1.2;
    @media(max-width: 1100px) {
        display: block;
        text-align: center;
        margin-top: 0
    }
`

export const SubTitle = styled.p`
    margin: 15px auto;
    color: ${theme.colors.input.black};
    text-align: justify;
    // margin-top: 2em;
    // font-size: 1rem;
    // line-height: 1.5;

    @media (max-width: 1100px){
        text-align: center;
    }
`

export const NavLink = styled(Link)`
    color: hsla(0,0%,100%,.5);
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
        color: hsla(0,0%,100%,1);
    }
`

export const List = styled.ol`
    margin: 20px 0 0 20px
`

export const Li = styled.li`
    margin: 0 auto;
    color: ${theme.colors.input.black};
    text-align: left;
    font-size: 1rem;
`

export const TextLei = styled.h3`
    margin: 0 auto;
    color: ${theme.colors.blue.normal};
    text-align: left;
    margin-top: 40px;
    font-size: 1.25rem;
`

export const Hr = styled.hr`
      margin-top: 30px;
      opacity: .4;
`

