import React, {useState, useEffect} from "react"
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom"
import ReactDOM from 'react-dom'
import { CreateLeiService } from "../../api/services/create-lei"
import { SearchLeiCode } from "../../api/services/search-lei"
// import Button from "../../components/ui/ui kit/Button"
import { joinClasses } from "../../utils/joinClasses"
import cl from "./style.module.css"
import { Helmet } from "react-helmet";
import Loader from "../../components/ui/Loader";
import { AxiosError } from 'axios';
import AdSearchA from "../../components/functional/Ads/Lei/AdSearchA";
import AdSearchB from "../../components/functional/Ads/Lei/AdSearchB";
import CheckLeiNumber from "../../components/functional/CheckLeiNumber/CheckLeiNumber";

export const Lei = () => {
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation();
    const pageUrl = window.location.origin + location.pathname;
    console.log(params)
    const [leiData, setLeiData] = useState<any>()
    const [countries, setCountries] = useState<any>([])
    const [LouName, setLouName] = useState<string>("");
    const [LeiIssuerName, setLeiIssuerName] = useState<string>("");
    const [LeiIssuerCode, setLeiIssuerCode] = useState<string>("");
    const [DirectParentName, setDirectParentName] = useState<string>("");
    const [DirectParentCode, setDirectParentCode] = useState<string>("");
    const [UltimateParentName, setUltimateParentName] = useState<string>("");
    const [UltimateParentCode, setUltimateParentCode] = useState<string>("");
    const [directChildren, setDirectChildren] = useState<{ legalName: string, lei: string }[]>([]);
    const [legalFormTransliteratedName, setLegalFormTransliteratedName] = useState<string | null>(null);
    const [raInternationalOrganizationName, setRaInternationalOrganizationName] = useState<string | null>(null);
    const [raCountry, setRaCountry] = useState<string | null>(null);
    const [vaInternationalOrganizationName, setVaInternationalOrganizationName] = useState<string | null>(null);
    const [vaCountry, setVaCountry] = useState<string | null>(null);

    const lei = params.lei

    const [leiExists, setLeiExists] = useState<boolean | null>(null);
    const handleStatusChecked = (exists: boolean) => {
        setLeiExists(exists);
        console.log(`LEI number exists: ${exists}`);
    };

    const requestLei = async () => {
        if(lei){
            const res = await SearchLeiCode.getLei(lei)
            setLeiData(res.data)
        }
    }

    const requestCountries = async () => {
        try {
            const countries = await CreateLeiService.requestCountries()
            setCountries(countries.data.data)
        } catch (error) {
            console.log(error)
        }
        
    }

    function getFlag(countryCode: string): string {
        const OFFSET = 127397;
        return countryCode
            .toUpperCase()
            .replace(/./g, 
              (char: string) => String.fromCodePoint(char.charCodeAt(0) + OFFSET)
            );
    }

    function getValueOrNA(value: any) {
    if (Array.isArray(value)) {
        if (value.length > 0) {
        return value.map((val) => val.name).join(", ");
        }
        return "N/A";
    } else {
        return value || "N/A";
    }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 
 
    useEffect(() => {
        requestLei()
        requestCountries()
    }, [lei])

    const ctx = leiData

    useEffect(() => {
        const getManagingLou = async (lei: string) => {
            try {
                const response = await SearchLeiCode.getManagingLou(lei);
    
                if (response.data) {
                    // Access the legal name
                    const LouName = response.data.data.attributes.entity.legalName.name;
    
                    // Update the LouName state
                    setLouName(LouName);
                } else {
                    console.log("No data for this LEI's managing Lou.");
                    setLouName("No data");
                }
            } catch (error) {
                console.error("Error fetching managing Lou:", error);
            }
        };
    
        // Call the async function
        if (ctx) {
            getManagingLou(ctx.data.attributes.lei);
        }
    }, [ctx]); // Re-run this effect whenever ctx changes
    
    
    useEffect(() => {
        const getLeiIssuer = async (lei: string) => {
            try {
                const response = await SearchLeiCode.getLeiIssuer(lei);
    
                if (response.data) {
                    // Access the legal name
                    const LeiIssuerName = response.data.data.attributes.name;
                    const LeiIssuerCode = response.data.data.attributes.lei;
    
                    // Update the states
                    setLeiIssuerName(LeiIssuerName);
                    setLeiIssuerCode(LeiIssuerCode);
                } else {
                    console.log("No data for this LEI issuer.");
                    setLeiIssuerName("No data");
                    setLeiIssuerCode("No data");
                }
            } catch (error) {
                console.error("Error fetching LEI issuer:", error);
            }
        };
    
        // Call the async function
        if (ctx) {
            getLeiIssuer(ctx.data.attributes.lei);
        }
    }, [ctx]); // Re-run this effect whenever ctx changes
    
    
    // Getting Direct Parent info

    useEffect(() => {
        const getDirectParent = async (lei: string) => {
            try {
                const response = await SearchLeiCode.getDirectParent(lei);
    
                if (response.data) {
                    // Access the data
                    const DirectParentName = response.data.data.attributes.entity.legalName.name;
                    const DirectParentCode = response.data.data.attributes.lei;
        
                    // Update the states
                    setDirectParentName(DirectParentName);
                    setDirectParentCode(DirectParentCode);
                } else {
                    // Handle the case when response data is null
                    console.log("No data for this LEI's direct parent.");
                    setDirectParentName("N/A");
                    setDirectParentCode("");
                }
            } catch (error) {
                const axiosError = error as AxiosError; // Asserting the error as an AxiosError (if you are using Axios)
                
                if (axiosError.response && axiosError.response.status === 404) {
                    console.log("Direct parent not found for this LEI.");
                    setDirectParentName("N/A");
                    setDirectParentCode("");
                } else {
                    console.error("Error fetching direct parent:", axiosError);
                    // Optionally, handle other kinds of errors or set state differently
                }
            }
        };
    
        if (ctx) {
            getDirectParent(ctx.data.attributes.lei);
        }
    }, [ctx]);     // Re-run this effect whenever ctx changes
    
    // Getting Ultimate Parent Info

    useEffect(() => {
        const getUltimateParent = async (lei: string) => {
            try {
                const response = await SearchLeiCode.getUltimateParent(lei);
    
                if (response.data) {
                    // Access the data
                    const UltimateParentName = response.data.data.attributes.entity.legalName.name;
                    const UltimateParentCode = response.data.data.attributes.lei;
        
                    // Update the states
                    setUltimateParentName(UltimateParentName);
                    setUltimateParentCode(UltimateParentCode);
                } else {
                    // Handle the case when response data is null
                    console.log("No data for this LEI's ultimate parent.");
                    setUltimateParentName("N/A");
                    setUltimateParentCode("");
                }
            } catch (error) {
                const axiosError = error as AxiosError; // Asserting the error as an AxiosError
    
                if (axiosError.response && axiosError.response.status === 404) {
                    console.log("Ultimate parent not found for this LEI.");
                    setUltimateParentName("N/A");
                    setUltimateParentCode("");
                } else {
                    // Handle other kinds of errors
                    console.error("Error fetching ultimate parent:", axiosError);
                }
            }
        };
    
        if (ctx) {
            getUltimateParent(ctx.data.attributes.lei);
        }
    }, [ctx]); // Re-run this effect whenever ctx changes

    interface ChildEntity {
        type: string;
        id: string;
        attributes: {
            lei: string;
            entity: {
                legalName: {
                    name: string;
                    language: string;
                };
            };
        };
    }

    // Getting info for children
    useEffect(() => {
        const fetchDirectChildren = async () => {
            if (ctx && ctx.data.attributes.lei) {
                try {
                    const response = await SearchLeiCode.getDirectChildren(ctx.data.attributes.lei);
                    const childrenData: ChildEntity[] = response.data.data; // get array data
                    // map over the array and create new array with names and leis
                    const children = childrenData.map((child: ChildEntity) => {
                        return {
                            legalName: child.attributes.entity.legalName.name,
                            lei: child.attributes.lei
                        }
                    });
                    setDirectChildren(children); 
                } catch (error) {
                    console.error("Error fetching Direct Children:", error);
                }
            }
        };
        fetchDirectChildren();
    }, [ctx]);

    // Getting info for legal form
    useEffect(() => {
        const fetchLegalForm = async () => {
            if (ctx?.data?.attributes?.entity?.legalForm?.id) {
                try {
                    const response = await SearchLeiCode.getLegalForm(ctx.data.attributes.entity.legalForm.id);
                    const legalFormTransliteratedName = response.data.data.attributes.names[0].transliteratedName;
                    setLegalFormTransliteratedName(legalFormTransliteratedName);
                } catch (error) {
                    console.error("Error fetching legal form:", error);
                }
            }
        };
        fetchLegalForm();
    }, [ctx]);
    
    // Getting info for reg authority
    useEffect(() => {
        const fetchRegistrationAuthority = async () => {
          if (ctx && ctx.data.attributes.entity.registeredAt.id) {
            try {
              const response = await SearchLeiCode.getRegistrationAuthority(ctx.data.attributes.entity.registeredAt.id);
              const raInternationalOrganizationName = response.data.data.attributes.internationalOrganizationName;
              const raCountry = response.data.data.attributes.jurisdictions[0]?.country; // Access the country
              setRaInternationalOrganizationName(raInternationalOrganizationName);
              setRaCountry(raCountry);
            } catch (error) {
              console.error("Error fetching Registration Authority:", error);
            }
          }
          if (ctx && ctx.data.attributes.registration.validatedAt.id) {
            try {
              const response = await SearchLeiCode.getRegistrationAuthority(ctx.data.attributes.registration.validatedAt.id);
              const vaInternationalOrganizationName = response.data.data.attributes.internationalOrganizationName;
              const vaCountry = response.data.data.attributes.jurisdictions[0]?.country; // Access the country
              setVaInternationalOrganizationName(vaInternationalOrganizationName);
              setVaCountry(vaCountry);
            } catch (error) {
              console.error("Error fetching Registration Authority:", error);
            }
          }
        };
      
        fetchRegistrationAuthority();
      }, [ctx]);
    

    if(!ctx) return <Loader />

        const leiNumber = ctx.data.attributes.lei
        const leiStatus = ctx.data.attributes.registration.status
        const leiNextRenewalDate = ctx.data.attributes.registration.nextRenewalDate.slice(0,19).replace("T"," ")
        const leiManagingLOU = ctx.data.attributes.registration.managingLou
        const leiCorroborationLevel = ctx.data.attributes.registration.corroborationLevel.replace("_"," ")
        const entityLegalName = getValueOrNA(ctx.data.attributes.entity.legalName.name)
        const entityStatus = getValueOrNA(ctx.data.attributes.entity.status)
        const entityJurisdiction = getValueOrNA(ctx.data.attributes.entity.jurisdiction)
        const entityOtherNames = getValueOrNA(ctx?.data?.attributes?.entity?.otherNames)
        const entityTransliteratedOtherNames = getValueOrNA(ctx.data.attributes.entity.transliteratedOtherNames)
        const entityRegisteredAtId = getValueOrNA(ctx.data.attributes.entity.registeredAt.id)
        const entityRegisteredAs = getValueOrNA(ctx.data.attributes.entity.registeredAs)
        const entityValidatedAtId = ctx.data.attributes.registration.validatedAt.id
        const entityValidatedAs = ctx.data.attributes.registration.validatedAs
        const entityLegalAddressCity = ctx.data.attributes.entity.legalAddress.city
        const entityLegalAddressCountry = ctx.data.attributes.entity.legalAddress.country
        const entityLegalAddressRegion = ctx.data.attributes.entity.legalAddress.region
        const entityLegalAddressLines = ctx.data.attributes.entity.legalAddress.addressLines.join(", ")
        const entityLegalAddressPostalCode = ctx.data.attributes.entity.legalAddress.postalCode
        const entityHeadquartersAddressCity = ctx.data.attributes.entity.headquartersAddress.city
        const entityHeadquartersAddressCountry = ctx.data.attributes.entity.headquartersAddress.country
        const entityHeadquartersAddressRegion = ctx.data.attributes.entity.headquartersAddress.region
        const entityHeadquartersAddressLines = ctx.data.attributes.entity.headquartersAddress.addressLines.join(", ")
        const entityHeadquartersAddressPostalCode = ctx.data.attributes.entity.headquartersAddress.postalCode
        const entityFlag = getFlag(ctx.data.attributes.entity.legalAddress.country)
        const entityLegalFormId = getValueOrNA(ctx.data.attributes.entity.legalForm.id)
        const entityCreationDate = ctx.data.attributes.entity.creationDate ? ctx.data.attributes.entity.creationDate.slice(0,10).replace("T"," ") : "N/A"
        const entityCreationDateFull = ctx.data.attributes.entity.creationDate ? ctx.data.attributes.entity.creationDate.slice(0,19).replace("T"," ") : "N/A"
        const entityLastUpdate = ctx.data.attributes.registration.lastUpdateDate ? ctx.data.attributes.registration.lastUpdateDate.slice(0,10).replace("T"," ") : "N/A"
        const entityLastUpdateFull = ctx.data.attributes.registration.lastUpdateDate ? ctx.data.attributes.registration.lastUpdateDate.slice(0,19).replace("T"," ") : "N/A"

        
        const schema = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": pageUrl
            },
            "headline": `${entityLegalName} from ${entityLegalAddressCity}, ${entityLegalAddressCountry} ${entityFlag} LEI ${leiNumber}`,
            "image": "https://app.leipapa.com/logo512.png",  
            "author": {
              "@type": "Organization",
              "name": "LEIpapa",
              "url": "https://leipapa.com"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "LEIpapa",
              "logo": {
                "@type": "ImageObject",
                "url": "https://app.leipapa.com/logo512.png"
              }
            },
            "datePublished": entityCreationDate,
            "dateModified": entityLastUpdate
        };


    return (
        
        <div className={cl["page"]}>

            <script type="application/ld+json">
                {JSON.stringify(schema)}
            </script>

            <Helmet> {/* Add the Helmet component */}
                <title>{entityLegalName} from {entityLegalAddressCity}, {entityLegalAddressCountry} {entityFlag} LEI {leiNumber}</title>
                <meta 
                name="description" 
                content={`${entityLegalName} registered at ${entityLegalAddressCity}, ${entityLegalAddressCountry} ${entityFlag} on ${entityCreationDate}. LEI ${leiNumber}, LEI ${leiStatus}, Entity ${entityStatus}, Company Information`} 
                />
                {/* <meta name="publisher" content="LEIpapa"/> */}
                <meta name="keywords" 
                content={`LEI information, Company Information, ${entityLegalName}, LEI ${leiNumber}, Jurisdiction ${entityLegalAddressCountry}, ${entityLegalAddressCity} city, official company page, official company data`}/>
                <link rel="canonical" href={pageUrl} />
                {/* OpenGraph */}
                <meta
                        property="og:title" 
                        content={`${entityLegalName} from ${entityLegalAddressCity}, ${entityLegalAddressCountry} ${entityFlag} LEI ${leiNumber}`} 
                    />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://app.leipapa.com/leipapa.png" />
                <meta property="og:url" content={pageUrl} />
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" /> 
                <meta name="twitter:site" content="@leipapa_com" />
                <meta
                        name="twitter:title" 
                        content={`${entityLegalName} from ${entityLegalAddressCity}, ${entityLegalAddressCountry} ${entityFlag} LEI ${leiNumber}`} 
                    />
                <meta name="twitter:description" content={`${entityLegalName} registered at ${entityLegalAddressCity}, ${entityLegalAddressCountry} ${entityFlag} on ${entityCreationDate}. LEI ${leiNumber}, LEI status ${leiStatus}, Entity Status ${entityStatus}`} />
                <meta name="twitter:image" content="https://app.leipapa.com/leipapa.png" />
            </Helmet>

            <div className={cl["container"]}>
                
                <div className={cl["lei-title"]}>
                    <h1>{entityLegalName}</h1>
                </div>

                <div className={cl["lei-hero"]}>
                    Complete information about the company
                </div>
                
                <div className={cl["table-info"]}>
                    <div className={cl["row"]}>
                        <div className={cl["item-head"]}>LEI code:&nbsp;</div>
                        <div className={cl["item-head"]}>{leiNumber}&nbsp;</div>
                    </div>
                    <div className={cl["row"]}>
                        <div className={cl["item-head"]}>LEI status:&nbsp;</div>
                        <div className={cl["item-head"]}>
                            <span className={cl["status-" + leiStatus]}>
                                {leiStatus}
                            </span>
                        </div>
                    </div>
                    <div className={cl["row"]}>
                        <div className={cl["item-head"]}>Country:&nbsp;</div>
                        <div className={cl["item-head"]}>{entityFlag + " " + entityLegalAddressCountry + ", " + entityLegalAddressCity + "" + ", " + countries[entityLegalAddressCountry]}</div>
                    </div>
                </div>

                <div>
                    <CheckLeiNumber leiNumber={leiNumber} entityLegalName={entityLegalName} onStatusChecked={handleStatusChecked} />
                </div>

                <h2 className={cl["subtitle"]}>Main Information</h2>

                <div>
                    <table className={cl["striped-table"]}>
                        <thead>
                            <tr>
                                <th className={joinClasses(cl["item-head"], cl["left"], cl["col-left"])}>Legal Name (Primary)</th>
                                <th className={joinClasses(cl["left"], cl["col-right"])}>
                                    {entityLegalName}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* !!!!! */}
                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>Other Names</td>
                                <td className={cl["left"]}>
                                {entityOtherNames}
                                </td>

                            </tr>

                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>Transliterated Names</td>
                                <td className={cl["left"]}>
                                    {entityTransliteratedOtherNames}
                                </td>
                            </tr>

                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>Registered At</td>
                                <td className={cl["left"]}>
                                    <p>{raInternationalOrganizationName}</p>
                                    <p>{raCountry}</p>
                                    <p>{entityRegisteredAtId}</p>
                                </td>
                            </tr>

                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>Registered As</td>
                                <td className={cl["left"]}>
                                    {entityRegisteredAs}
                                </td>
                            </tr>

                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>Jurisdiction Of Formation</td>
                                <td className={cl["left"]}>
                                    {entityJurisdiction}
                                </td>
                            </tr>

                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>Entity Legal Form</td>
                                <td className={cl["left"]}>
                                    <p>{legalFormTransliteratedName}</p>
                                    <p>{entityLegalFormId}</p>
                                </td>
                            </tr>

                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>Entity Status</td>
                                <td className={cl["left"]}>
                                    {entityStatus}
                                </td>
                            </tr>
                            
                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>BIC</td>
                                <td className={cl["left"]}>
                                {Array.isArray(ctx.data.attributes.bic)
                                ? ctx.data.attributes.bic.map((value:string, index:number) => (
                                    <div key={index}>{value}</div>
                                ))
                                : "N/A"}
                            </td>
                            </tr>

                            <tr>
                                <td className={joinClasses(cl["item-head"], cl["left"])}>ISIN codes</td>
                                <td className={cl["left"]}>
                                {ctx.data.relationships.isins && ctx.data.relationships.isins.links && ctx.data.relationships.isins.links.related 
                                    ? <a href={ctx.data.relationships.isins.links.related} title="ISIN data json" target="_blank" rel="noopener noreferrer">ISIN data json</a>
                                    : "N/A"}
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    
                    <h2 className={cl["subtitle"]}>Addresses</h2>

                    <table className={cl["striped-table"]}>
                    <thead>
                    <tr>
                        <th className={joinClasses(cl["left"], cl["col-50"])}><h3 className={cl["item-head"]}>Legal</h3></th>
                        <th className={joinClasses(cl["left"], cl["col-50"])}><h3 className={cl["item-head"]}>Headquarters</h3></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={joinClasses(cl["left"])}>{entityLegalAddressLines}</td>
                        <td className={cl["left"]}>{entityHeadquartersAddressLines}</td>
                    </tr>
                    <tr>
                        <td className={joinClasses(cl["left"])}>{"ZIP " + entityLegalAddressPostalCode}</td>
                        <td className={cl["left"]}>{"ZIP " + entityHeadquartersAddressPostalCode}</td>
                    </tr>
                    <tr>
                        <td className={joinClasses(cl["left"])}>{entityLegalAddressCity}</td>
                        <td className={cl["left"]}>{entityHeadquartersAddressCity}</td>
                    </tr>
                    <tr>
                        <td className={joinClasses(cl["left"])}>{entityLegalAddressRegion}</td>
                        <td className={cl["left"]}>{entityHeadquartersAddressRegion}</td>
                    </tr>
                    <tr>
                        <td className={joinClasses(cl["left"])}>{entityLegalAddressCountry +  " - " + countries[entityLegalAddressCountry]}</td>
                        <td className={joinClasses(cl["left"])}>{entityHeadquartersAddressCountry +  " - " + countries[entityHeadquartersAddressCountry]}</td>                    
                    </tr>
                    </tbody>
                    </table>

                    <div className = {joinClasses(cl["w-full"], cl["px-8"])}>
                        <hr className={cl["mt-8"]} />
                        <AdSearchA />
                        <hr className={cl["mt-4"]} />
                    </div>

                    <h2 className={cl["subtitle"]}>Registration details</h2>

                    <table className={cl["striped-table"]}>
                        <tbody>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["col-left"], cl["left"])}>Registration Date</td>
                            <td className={joinClasses(cl["col-right"], cl["left"])}>{entityCreationDateFull}</td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>Last LEI Update Date</td>
                            <td className={cl["left"]}>{entityLastUpdateFull}</td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>Current LEI Status</td>
                            <td className={cl["left"]}>
                                <span className={cl["status-" + leiStatus]}>
                                {leiStatus}
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>Next LEI Renewal Date</td>
                            <td className={cl["left"]}>{leiNextRenewalDate}</td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>LEI issuer</td>
                            <td className={cl["left"]}>
                                <p>{LeiIssuerName}</p>
                                <a href={`https://app.leipapa.com/lei-details/${LeiIssuerCode}`} title='LEI issuer Information' target="_blank" rel="noopener noreferrer">{LeiIssuerCode}</a>
                            </td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>Managing LOU</td>
                            <td className={cl["left"]}>
                                <p>{LouName}</p>
                                <a href={`https://app.leipapa.com/lei-details/${leiManagingLOU}`} title='Managing LOU Information' target="_blank" rel="noopener noreferrer">{leiManagingLOU}</a>
                            </td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>Corroboration Level</td>
                            <td className={cl["left"]}>{leiCorroborationLevel}</td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>Data Validated At</td>
                            <td className={cl["left"]}>
                                <p>{vaInternationalOrganizationName}</p>
                                <p>{vaCountry}</p>
                                <p>{entityValidatedAtId}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["left"])}>Data Validated As</td>
                            <td className={cl["left"]}>{entityValidatedAs}</td>
                        </tr>
                        </tbody>
                    </table>

                    <h2 className={cl["subtitle"]}>Parents</h2>

                    <table className={cl["striped-table"]}>
                        <tbody>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["col-left"], cl["left"])}>Direct Parent</td>
                            <td className={joinClasses(cl["left"], cl["col-right"])}>
                                <p>{DirectParentName}</p>
                                {DirectParentCode !== "" && (
                                <a href={`https://app.leipapa.com/lei-details/${DirectParentCode}`} title='Direct Parent Information' target="_blank" rel="noopener noreferrer">{DirectParentCode}</a>
                            )}
                            </td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["col-left"], cl["left"])}>Ultimate Parent</td>
                            <td className={joinClasses(cl["left"], cl["col-right"])}>
                                <p>{UltimateParentName}</p>
                                {UltimateParentCode !== "" && (
                                <a href={`https://app.leipapa.com/lei-details/${UltimateParentCode}`} title='Ultimate Parent Information' target="_blank" rel="noopener noreferrer">{UltimateParentCode}</a>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h2 className={cl["subtitle"]}>Children</h2>
        
                    
                            <table className={cl["striped-table"]}>
                                <tbody>
                                <tr>
                                <td className={joinClasses(cl["col-left"], cl["item-head"], cl["left"])}>Direct Children</td>
                                <td className={joinClasses(cl["left"], cl["col-right"])}>
                                {directChildren.length > 0 ? 
                                    directChildren.map((child, index) => (
                                        <div key={index}>
                                            <p>{child.legalName || "N/A"}</p>
                                            <p><a href={`/lei-details/${child.lei}`} title={`LEI information ${child.lei}`} target="_blank" rel="noreferrer">{child.lei || "N/A"}</a></p>
                                        </div>
                                    ))
                                    :
                                    <p>No direct children available</p>
                                }
                                </td>
                                </tr>
                                </tbody>
                            </table>
                        

                    <h2 className={cl["subtitle"]}>External Links</h2>

                    <table className={cl["striped-table"]}>
                        <tbody>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["col-left"], cl["left"])}>LEIprofile</td>
                            <td className={joinClasses(cl["left"], cl["col-right"])}>
                            <a href={`https://leiprofile.com/lei/${leiNumber}`} title={`Information for ${leiNumber} on LEIprofile Global Corporate Index`} target="_blank" rel="noopener noreferrer">{leiNumber}</a></td>
                        </tr>
                        <tr>
                            <td className={joinClasses(cl["item-head"], cl["col-left"], cl["left"])}>GLEIF</td>
                            <td className={joinClasses(cl["left"], cl["col-right"])}>
                            <a href={`https://search.gleif.org/#/record/${leiNumber}`} title={`Information for ${leiNumber} on GLEIF Website`} target="_blank" rel="noopener noreferrer">{leiNumber}</a></td>
                        </tr>
                        </tbody>
                    </table>

                    <h2 className={cl["subtitle"]}>FAQ</h2>
                    <div className={cl["striped-table"]}>
                        <details>
                            <summary><h3>What is the legal address of {entityLegalName}?</h3></summary>
                            <p>{entityLegalName} has it legal address at {entityLegalAddressLines + ", " + entityLegalAddressCity + ", " + entityLegalAddressRegion + " - " + countries[entityLegalAddressCountry] + ", " + entityLegalAddressPostalCode}</p>
                        </details>

                        <details>
                            <summary><h3>Where is {entityLegalName} headquartered?</h3></summary>
                            <p>{entityLegalName} has it headquarters (HQ) address at {entityHeadquartersAddressLines + ", " + entityHeadquartersAddressCity + ", " + entityHeadquartersAddressRegion + " - " + countries[entityHeadquartersAddressCountry] + ", " + entityHeadquartersAddressPostalCode}</p>
                        </details>    

                        <details>
                            <summary><h3>What is {entityLegalName} legal form?</h3></summary>
                            <p>{entityLegalName} is registered as a {legalFormTransliteratedName}</p>
                        </details>    

                        <details>
                            <summary><h3>When {entityLegalName} was registered?</h3></summary>
                            <p>{entityLegalName} was registered on {entityCreationDate}.</p>
                        </details>

                        <details>
                            <summary><h3>How to register an LEI code in {countries[entityLegalAddressCountry]}?</h3></summary>
                            <p>To register an LEI code for the entity from {countries[entityLegalAddressCountry]}, please go to the <a href="https://app.leipapa.com/create" title="Register an LEI">"Register an LEI code"</a> section of the app and enter the details of your entity. After the payment, you will get your LEI code shortly.</p>
                        </details>  

                        <details>
                            <summary><h3>How to transfer my LEI code to LEIpapa?</h3></summary>
                            <p>To transfer your existing LEI code to LEIpapa, please go to the <a href="https://app.leipapa.com/transfer" title="Transfer an LEI">"Transfer an LEI code"</a> section of the app and enter the details of your entity. Also, <a href="https://leipapa.com/docs/transfer-an-lei/" title="Transfer an LEI" target="_blank">read the full information</a> on LEI transfer on our website.</p>
                        </details> 
                        
                        <div className={cl["callout"]} style={{textAlign: "justify", marginTop: "2rem"}}>
                            <strong>💡 Best Practice</strong>
                            <p style={{marginTop: "1rem"}}>
                                Assign a unique <a href="https://leipapa.com/docs/what-is-an-lei-number/" target="_blank" title="Get to know what is an LEI number">LEI number</a> to each contact you have to identify and avoid transaction risks linked to contacts with similar names. This company <strong>{entityLegalName}</strong> has an LEI number <strong>{leiNumber}</strong>.
                            </p>
                        </div>

                        <div className={cl["contact"]}>
                        <p>
                            If {entityLegalName} is your company and you would like to add any updates to the registry, please <a href="https://leipapa.com/contact" title="Contact LEIpapa" target="_blank">contact us</a>.  
                        </p>   
                        </div> 
                    </div>
                </div>

                <div className={cl["title_inner-button"]}>
                  <NavLink to="/search" className={cl["button_back"]}>⏪ Back to Search</NavLink>
                  <a href="https://leipapa.com/contact" className={cl["button_back"]} title="Contact LEIpapa">Contact Us ✉️</a>
                </div>  

                <div className = {joinClasses(cl["w-full"], cl["px-8"])}>
                    <hr className={cl["mt-8"]} />
                    <AdSearchB />
                </div>

                <div className={cl["contact"]}>
                    <p style={{textAlign: "justify"}}>
                        In today’s fast-paced business world, understanding the dynamics and details of leading companies and counterparts is more crucial than ever. Whether you're a job seeker, investor, financial market participant, or curious consumer, getting accurate information about a company can significantly impact your decisions and strategies.
                    </p>
                    <p style={{float: "right", fontSize: "small"}}><a style={{fontSize: "small"}} href="https://leipapa.com/authors/makhmud-efendi/" target="_blank" title="Go to Makhmud's page at LEIpapa">M. Efendi</a>, founder of LEIpapa</p>
                </div>

            </div>
        </div>
    )
} 
