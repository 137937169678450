import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const RegistrationLeiPage = styled.div`
    max-width: 100%;
    color: black;
    // font-weight: 300;
    // font-family: Roboto, sans-serif;
`

export const Title = styled.h1`
    display: contents;  
    margin: 2rem auto 0;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    // line-height: 1.2;
    @media(max-width: 768px) {
        display: block;
        text-align: center;
        margin-top: 0
      }
`

export const NavLink = styled(Link)`
    text-decoration: none;
`;

export const Hr = styled.hr`
      margin-top: 30px;
      opacity: .4;
      @media(max-width: 768px) {
        display: none;
      }
`

export const SubTitle = styled.p`
    // font-weight: 500;
    // line-height: 1.5;
    // color: #212529;
    // font-size: 1rem;
`

export const TabName = styled.h3`
    // font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
    // line-height: 1.5;
    // color: #212529;
    // font-size: 1rem;
    @media(max-width: 768px) {
        text-align: center;
        margin: 15px auto;
      }
`

