import { FC, useState } from "react";
import { Routes } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { useAuth } from "../hooks/useAuth";
import { render } from "../router/render";
import { commonRoutes, privateRoutes, publicRoutes } from "../router/router";
import Navbar from './header/navbar';
import Footer from './footer';

export const AppRouter:FC = () => {
    const [errorModal, setErrorModal] = useState(false)
    const [errorModalMessage, setModalMessage] = useState("")
    const { isAuth } = useAuth()

    const toggleErrorModal = () => {
        setErrorModal(prev => !prev)
    }
    
    return (
        <AppContext.Provider value={{
            setErrorModal: toggleErrorModal,
            setErrorModalMessage: setModalMessage
        }}>
            <Navbar/>
                <Routes>
                    {isAuth ? privateRoutes.map(render) : publicRoutes.map(render)}
                    {commonRoutes.map(render)}
                </Routes>
                {errorModal && <div className="error">error modal {errorModalMessage}</div>}
                <Footer/>
        </AppContext.Provider>
    )
}
