import { AxiosResponse } from "axios";
import { $leiApi } from "../..";

export class SearchLeiCode {
  static async requestSearchLeiCode(page:number): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records?page[size]=20&page[number]=${page}`);
  }
  static async searchLeiByCompanyName(name: string, jurisdiction: string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records?filter[entity.names]=${encodeURIComponent(name)}&filter[entity.jurisdiction]=${jurisdiction}`);
  }
  static async searchLeiByLei(lei: string, jurisdiction: string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records?filter[lei]=${encodeURIComponent(lei)}&filter[entity.jurisdiction]=${jurisdiction}`);
  }
  static async getLei(lei: string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records/${lei}`);
  }
  static async getManagingLou(lei:string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records/${lei}/managing-lou`);
  }
  static async getLeiIssuer(lei:string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records/${lei}/lei-issuer`);
  }
  static async getDirectParent(lei:string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records/${lei}/direct-parent`);
  }
  static async getUltimateParent(lei:string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records/${lei}/ultimate-parent`);
  }
  static async getDirectChildren(lei:string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records/${lei}/direct-children`);
  }
  static async searchLei(lei:string): Promise<AxiosResponse<any>> {
    //return $leiApi.get(`fuzzycompletions?field=entity.legalName&q=${lei}`);
    return $leiApi.get(`autocompletions?field=fulltext&q=${lei}`);
  }
  static async getLegalForm(id: string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`entity-legal-forms/${id}`);
  }
  static async getRegistrationAuthority(id: string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`registration-authorities/${id}`);
  }
}
