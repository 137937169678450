import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';
import { theme } from "../../constants/theme";

export const CardPage = styled.div`
    font-weight: 300;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 40px;
    gap: 20px;

    @media (max-width: 1100px) {
        flex: 100%;
        flex-wrap: wrap;
    }
`;

export const CardItem = styled.div`
    cursor: pointer;
    width: 100%;
    padding: 20px 20px;
    border-radius: 5px;
    background-color: ${theme.colors.blue.light};
    color: ${theme.colors.input.blue};
    text-align: left;
    :not(:first-child){
        margin-left: 30px;
    }
    transition: all 0.3s;
    &:hover {
    background-color: ${theme.colors.blue.dark};
    color: ${theme.colors.input.white};
    box-shadow: 0 0 5px .2rem rgba(0,123,255,.25);
    }

`;

export const Title = styled.p`
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    }
    transition: all 0.3s;
    &:hover {
    color: #CF0010;
    }
`;

export const Subtitle = styled.h2`
    font-size: 1.2rem;
    margin: 0;
    margin-top: 10px;
    }
    transition: all 0.3s;
    &:hover {
    color: #CF0010;
    }
`;

export const Description = styled.p`
    font-size: 1rem;
    margin: 0;
    margin-top: 30px;
`;

export const NavLink = styled(Link)`
    display: block;
    text-decoration: none;
    width: 100%;
`;