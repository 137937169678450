import RegistrationForm from "../../components/functional/RegistrationForm";
import cl from "./Registration.module.sass";
import { Title } from "./style";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export const Registration = () => {
  const location = useLocation();
  const pageUrl = window.location.origin + location.pathname;
  
  return (
    // <div className={cl["page"]}>
      <div>
        <Helmet>
          {/* When making changes here, make also changes to server.js file accordingly */}
          <title>Create an account with LEIpapa</title>
          <meta name="description" content="Create an Account with LEIpapa to apply for an LEI, renew your existing LEI, or transfer your LEI to LEIpapa" />
          <link rel="canonical" href={pageUrl} />
          {/* OpenGraph */}
          <meta property="og:title" content="Create an account at LEIpapa" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://app.leipapa.com/leipapa.png" />
          <meta property="og:url" content={pageUrl} />
          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" /> 
          <meta name="twitter:site" content="@leipapa_com" />
          <meta name="twitter:title" content="Create an account at LEIpapa" />
          <meta name="twitter:description" content="Create an Account at LEIpapa to apply for, renew, or transfer an LEI" />
          <meta name="twitter:image" content="https://app.leipapa.com/leipapa.png" />
        </Helmet>
        <RegistrationForm />
      </div>
    //</div>
  );
};
