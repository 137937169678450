import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'
/* Header styles
default padding: 0.2em 1em; */

export default styled.div`
width: 100%;
max-width: 1200px;
`;


export const Header = styled.header`
    width: 100%;
    position: sticky;
    top: 0px;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(128, 128, 128, 0.5);
    display: flex;
    height: 4rem;
    justify-content: space-between;
    padding: 0.2em 4em;
    z-index: 10;
    align-items: center;
    border-top: 4px solid;
    border-image-source: linear-gradient(90deg, red, #2B3956, #4B7196, #CFE2F3 75%);
    border-image-slice: 1;
    // border-bottom: 0.5px solid lightgrey;
    // &:hover {
    //   box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, .1);
    // }
    @media (max-width: 768px) {
      padding: 0.2em 2em;
    }
`;

export const Ul = styled.ul`
    display: flex;
    list-style-type: none;
    margin-left: 1em;
`;
/* Color of links in Header*/
export const NavLink = styled(Link)`
    color: #2B3956;
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
      color: #CE0000; 
      
    }
`;

export const Li = styled.li`
    padding-right: 0.5em;
    padding-left: 0.5em;
    @media (max-width: 768px) {
      padding-right: 0;
      padding-left: 0;
    }
`;