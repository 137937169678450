export const calculateEntityTypeSurcharge = (entityType) => {
    let entityTypeSurcharge = 0;
  
    if (entityType === "FUND" || entityType === "TRUST" || entityType === "WILL_PENSION") {
      entityTypeSurcharge = 30;
    }
  
    return entityTypeSurcharge;
  };
  
  export const calculateLevel2Surcharge = (isLevel2DataAvailable) => {
    let level2Surcharge = 0;
  
    if (isLevel2DataAvailable) {
      level2Surcharge = 30;
    }
  
    return level2Surcharge;
  };
  
