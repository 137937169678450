import React, { useCallback } from "react";
import {
  Title,
  ServicesPage,
  SubTitle,
  List,
  Li,
  NavLink
} from "./style";
import cl from "./Services.module.scss";
import Container from "../../components/ui/ui kit/Container";


export const Services = () => {
  
  return (
    <Container>
      <ServicesPage>
        <Title>Services</Title>
        <SubTitle>
        LEIpapa (LEIpapa OÜ) is an Official LEI Registration Agent of Ubisecure RapidLEI (LEI Issuer). We are here to assist legal entities apply for a new LEI and renew their existing LEIs. LEIpapa validates client registration data in cooperation with RapidLEI and provides fast, simple, automated, accurate, and affordable service. Such great cooperation allows LEIpapa to provide the highest LEI data quality standards. 
          <br /><br />
          <span className={cl["subtitle__text-bottom"]}>Our services:</span>
        </SubTitle>
        <List>
          <Li>Apply for an LEI code</Li>
          <Li>Keep your LEI code active</Li>
          <Li>Request a review of a legal entity's records</Li>
          <Li>Transfer your LEI code to LEIpapa</Li>
          <Li>Apply and Renew multiple records via file upload</Li>
        </List>
      </ServicesPage>
    </Container>
  );
};
