import {CardElement, useElements, useStripe, PaymentElement} from "@stripe/react-stripe-js";
import React, {useState, useEffect, useCallback} from "react";
import Loader from "../../Loader";
import "./cardform.css";
import { CreateLeiService } from "../../../../api/services/create-lei";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { fetchDataForm } from "../../../../redux/action-creators/dataFormReducer";
import Select from "../../../../components/ui/ui kit/Select";

const countries = [
    "United Kingdom",
    "Denmark",
    "Estonia",
    "Finland",
    "Lithuania",
    "Latvia",
];


const CardForm = (price: any) => {
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState<boolean>(false);
    const [paymentResponse, setPaymentResponse] = useState(<span></span>);
    const navigate = useNavigate();
    const [trackingId, setTrackingId] = useState("");
    const [country, setCountry] = useState("");
    const { dataDataForm, loadingDataForm, errorDataForm } = useTypedSelector(
        (state) => state.dataForm
    );
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
      }, []);

    const country_options = countries.map((text, index) => {
        return <option key={index}>{text}</option>;
    });

    // Handle real-time validation errors from the card Element.
    const handleChange = (event: any) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError("");
        }
    }

    const handleError = (error: any) => {
        console.log(error.response.data)
        if ("non_field_errors" in error.response.data) {
            return "You have already applied for this LEI."
        }
        return error["message"];
    };


    const SaveOrderStatus = useCallback(async () => {
        if (trackingId !== ""){
        await dispatch(fetchDataForm({"trackingId":trackingId}))
        navigate("/lerd");
        }
    }, [trackingId]);

    useEffect(() => {
        SaveOrderStatus();
    }, [trackingId]);


    // Handle form submission.
    const handleSubmit = async (event:any) => {
        setLoading(true);
        event.preventDefault();
        const card = elements!.getElement(CardElement);
        const {paymentMethod, error} = await stripe!.createPaymentMethod({
            type: "card",
            card: card!,
        });

        if (error) {
            setError(error.type)
            setPaymentResponse(<span style={{color: "red", fontStyle: "italic"}}>Payment system error</span>);
        }
        else {
            await CreateLeiService.createLeiCode({...dataDataForm, price: price["price"], email: email, country: country, payment_method_id: paymentMethod.id}).then(response => {
                console.log("response", response.data);
                setPaymentResponse(<span style={{color: "blue", fontStyle: "italic"}}>Payment success</span>);
                setLoading(false);
                setTrackingId(response.data.order_tracking_code);
                console.log("trackingId", trackingId);
            }).catch(error => {
                setPaymentResponse(<span style={{color: "red", fontStyle: "italic"}}>Error: {handleError(error)}</span>);
                setLoading(false);
                console.log("trackingId", trackingId);
                setTrackingId("");
            });
        };            
    };

    return (
        <form onSubmit={handleSubmit} className="stripe-form">
            <div className="form-row">
                <label>
                    Email Address
                </label>
                <input
                    className="form-input"
                    id="email"
                    name="name"
                    type="email"
                    placeholder="abc@example.com"
                    required
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                />
            </div>
            <div className="form-row">
                <label htmlFor="card-element">
                    Credit or debit card
                </label>

                <CardElement
                    id="card-element"
                    onChange={handleChange}
                />
                <div className="card-errors" role="alert">{error}</div>
            </div>
            <div className="form-row">
                <label>
                    Country
                </label>

                <select 
                    value={country} 
                    onChange={(event) => setCountry(event.target.value)}
                    className="form-select"
                >
                    {country_options}
                </select>
            </div>
            <button type="submit" className="button">Submit Payment</button>
            <div className="payment-status">{paymentResponse}</div>
            {loading ? <Loader /> : ""}
        </form>
    );
};

export default CardForm;