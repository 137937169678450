import { useEffect, useState } from "react";
import cl from "./GoogleOAuth.module.sass"
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import User from "../../api/services/users";
import { useAuth } from "../../hooks/useAuth";

export const GoogleOAuth = () => {
    const { setAuth } = useAuth();
    const location = useLocation();
    const pageUrl = window.location.origin + location.pathname;
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const values = queryString.parse(location.search);
        const code = values.code;
        if (typeof code === "string") googleLoginHandler(code);
    }, []);

    const googleLoginHandler = (code: string) => {
        User.google_login(code).then((token) => {
            localStorage.setItem("token", token.data.auth_token);
            setAuth(true);
            navigate("/");
        }).catch((err) => {
            setError(err);
        });
    }

    return (
        //<div className={cl["page"]}>
            <div>
                <Helmet>
                {/* When making changes here, make also changes to server.js file accordingly */}
                <title>Log In to the LEIpapa account</title>
                <meta name="description" content="Log In to the LEIpapa account to apply for an LEI, renew your existing LEI, or transfer your LEI to LEIpapa" />
                <link rel="canonical" href={pageUrl} />
                {/* OpenGraph */}
                <meta property="og:title" content="Log In to the account at LEIpapa" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://app.leipapa.com/leipapa.png" />
                <meta property="og:url" content={pageUrl} />
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" /> 
                <meta name="twitter:site" content="@leipapa_com" />
                <meta name="twitter:title" content="Log In to the account at LEIpapa" />
                <meta name="twitter:description" content="Log In to the Account at LEIpapa to apply for, renew, or transfer an LEI" />
                <meta name="twitter:image" content="https://app.leipapa.com/leipapa.png" />
                </Helmet>
            </div>
        //</div>
    )
}