import React, { useCallback, useEffect, useRef, useState} from "react";
import { Helmet } from "react-helmet";
import { Dropdown } from 'flowbite-react';

import { OrdersPage, Title, SubTitle, NavLink, Hr } from "./style";
import Container from "../../components/ui/ui kit/Container";
import cl from "./Orders.module.sass";
// import { ReactComponent as PlusIcon } from "../../assets/icons/home/records/plus.svg";
// import { ReactComponent as TriangleDownIcon } from "../../assets/icons/home/records/triangledown.svg";
// import { ReactComponent as UploadIcon } from "../../assets/icons/home/records/upload.svg";
import SearchLei from "../../components/functional/SearchLei";
import { useAuth } from "../../hooks/useAuth";
// import { useDispatch } from "react-redux";
// import { fetchLeiCode } from "../../redux/action-creators/createLeiCodeReducer";
// import { useNavigate } from "react-router-dom";
import { CreateLeiService } from "../../api/services/create-lei";
import { ILei } from "../../types/lei";
// import { LeiCode } from "../../data/SearchLeiCode";
// import { SearchLeiCode } from "../../api/services/search-lei";
import { joinClasses } from "../../utils/joinClasses";
import LoadingIcon from "../../components/ui/LoadingIcon";

export const Orders = () => {
  const searchLeiRef = useRef();

  const [isCreationsShow, setCreationsShow] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);
  const { isAuth } = useAuth();
  const [isItemLoading, setIsItemLoading] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(0);

  const callSearchLeiRef = (method: string, ...args: any[]) => {
    if (!searchLeiRef || !searchLeiRef.current) {
      return;
    }

    (searchLeiRef.current as any)[method](...args);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const requestLei = useCallback(async () => {
    const res = await CreateLeiService.requestOrderList();
    // console.log("res", res);
    const lei = res.data.map((el: any) => ({
      order_id: el.id,
      order_type: el.order_type,
      lei: el.order_tracking_code,
      status: el.status,
      date: el.created_at,
      name: el.company_name,
      country: el.legal_jurisdiction,
    }));
    return lei;
  }, []);

  const handleCheckStatus = async (orderTrackingCode: string, index: number, orderId?: number) => {
    console.log(orderTrackingCode, orderId);
    let requestBody: any;
    if (orderTrackingCode) {
      requestBody = {
        order_tracking_code: orderTrackingCode
      }
    } else {
      requestBody = {
        order_id: orderId
      };
    }

    setSelectedOrderId(orderId || 0);
    setIsItemLoading(true);

    try {
      const res = await CreateLeiService.checkOrderStatus(requestBody);
      // console.log("res", res);

      if (res.data.data) {
        const el = res.data.data;
        const orderItem = {
          order_id: el.id,
          order_type: el.order_type,
          lei: el.order_tracking_code,
          status: el.status,
          date: el.created_at,
          name: el.company_name,
          country: el.legal_jurisdiction,
        };

        callSearchLeiRef('onLeiChanged', orderItem, index);
      }
    } catch (e) {
      console.log('-> Error in handleCheckStatus:', e);
    } finally {
      setIsItemLoading(false);
      setSelectedOrderId(0);
    }
  };

  const renderLei = (leis: ILei[]) => {
    return leis.map(({ order_id, order_type, lei, name, country, status, date }, index) => (
      <div className={cl["wrap__items"]} key={`${index}`}>
        <div className={[cl["line"], cl["child__line"]].join(" ")}>
          <div className={cl["item"]}>{order_type}</div>
          {(status === "Complete") ? (
              <NavLink to={`/order-details/${lei}`} className={[cl["item"], cl["lei"]].join(" ")}>
                {lei}
              </NavLink>
            ) : (
              <div className={cl["item"]}>{lei}</div>
            )
          }
          <div className={cl["item-name"]}>{name}</div>
          <div className={cl["item"]}>{country}</div>
          <div className={joinClasses(cl["item"], cl["status"], (status === "Complete") ? cl["status-complete"] : "", (status === "Manual") ? cl["status-manual"] : "",  !(status === "Submitted") ? cl["status-rejected"] : "") }>{status ? status: 'null'}</div>
          {date && (
            <div className={cl["item"]}>{date.slice(0, 19).replace("T", " ")}</div>
          )}
          <div className={joinClasses(cl["item"], cl["dropdown-item"])}>
            {isItemLoading && selectedOrderId === order_id
              ? (
                <div className={cl["loading-wrapper"]}>
                  <LoadingIcon />
                </div>
              )
              : (
                <Dropdown label="Actions">
                  <Dropdown.Item onClick={() => handleCheckStatus(lei, index, order_id)}>
                    Update the Status
                  </Dropdown.Item>
                </Dropdown>
              )
            }
          </div>
        </div>
      </div>
    ));
  };
      
  return (
    <Container>
      <Helmet> {/* Add the Helmet component */}
        <title>Orders submitted to LEIpapa by the applicant</title>
        <meta 
          name="description" 
          content="Orders submitted to LEIpapa. LEIpapa is an official LEI Registration Agent of Ubisecure RapidLEI. Press Create LEI Application to Register, Renew or Transfer an LEI." 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <OrdersPage>
          <div className={cl["title_wrap"]}>
            <Title>Orders</Title>
          </div>
          <SubTitle>
            Press "Create LEI Application"👇 to Register, Renew or Transfer an LEI.
          </SubTitle>
          <div className={cl["create_lei-wrap"]}>
            <div className={cl["inner_button-lei"]}>
              <button
                className={cl["button"]}
                onClick={() => setCreationsShow((prev) => !prev)}
              >
                Create LEI Application
              </button>
            </div>
            {isCreationsShow && (
              <div className={cl["create_leicode"]}>
                <div className={cl["hidden_top"]}>
                  <div className={cl["wrap_button"]}>
                    <NavLink to="/create">
                      <button className={cl["button_top"]}>
                        <span className={cl["button_text"]}>Create new LEI</span>
                      </button>
                    </NavLink>
                  </div>
                  <p className={cl["top_text"]}>
                    Submit your request using a standard web template. This
                    option is useful for single or low volume submissions.
                  </p>
                </div>

                <Hr />

                <div className={cl["hidden_bottom"]}>
                  <div className={cl["wrap_button"]}>
                    <NavLink to="/renew">
                      <button className={cl["button_top"]}>
                        <span className={cl["button_text"]}>Renew LEI</span>
                      </button>
                    </NavLink>
                  </div>
                  <p className={cl["bottom_text"]}>
                    Renew here any existing LEI. To keep an LEI active, it must be renewed every year. We use a fast, automated LEI renewal process.
                  </p>
                </div>

                <Hr />

                <div className={cl["hidden_bottom"]}>
                  <div className={cl["wrap_button"]}>
                    <NavLink to="/transfer">
                      <button className={cl["button_top"]}>
                        <span className={cl["button_text"]}>Transfer LEI</span>
                      </button>
                    </NavLink>
                  </div>
                  <p className={cl["bottom_text"]}>
                    Transfer your LEI to LEIpapa for full service. If another service provider manages your LEI, you can transfer it to LEIpapa for free.
                  </p>
                </div>
              </div>
            )}

            <SubTitle>
              Below is the list of your orders (if any).
            </SubTitle>

            <SearchLei
              ref={searchLeiRef}
              titles={["Order Type", "Order Tracking Code", "Legal Name", "Country", "Status", "Order Creation Date", ""]}
              headClassName={cl["head_line"]}
              renderedLei={renderLei}
              isPagination={false}
              requestLeis={requestLei}
              enableInput={false}
            />
          </div>
        </OrdersPage>
      )}
    </Container>
  );
};
