import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Container from "../../components/ui/ui kit/Container";
import cl from "./Settings.module.sass";
import { useAuth } from "../../hooks/useAuth";
import { SettingsPage, Title, SubTitle } from "./style";
import Form from "../../components/functional/Form";
import InputError from "../../components/ui/ui kit/InputError";
import Input from "../../components/ui/ui kit/Input";
import { useForm } from "../../hooks/useForm";
import { ISettings } from '../../types/settings';
import User from '../../api/services/users';
import Button from '../../components/ui/ui kit/Button';
import Modal from '../../components/ui/ui kit/Modal';
import Done from "../../assets/icons/done.png"
import Loader from '../../components/ui/Loader';

const UIInputProps = {
  width: "500px",
  margin: "10px 0px",
};

const defaultErrors = {
  current_password_pass: "",
};

export const Settings = () => {
  const { isAuth } = useAuth();
  const [errors, setErrors] = useState<any>(defaultErrors);
  const [profile, setProfile] = useState({
    first_name: '-',
    last_name: '-',
    email: '-',
    phone_country: '-',
    phone_number: '-',
    company: '-',
    job_title: '-',
    id: '-',
  })
  console.log('profile',profile);

  const { register, handleSubmit, setForm } = useForm<ISettings>({
    preventDefault: true,
    submitValidate: true,
    defaultState: profile,
  });

  const { register: pass, handleSubmit: PassFormSubmit } = useForm({
    preventDefault: true,
    submitValidate: true,
  })

  const [succesModal, setSuccesModal] = useState(false)
  const [isEmailLoading, setEmailLoading] = useState(false)
  const [isPasswordLoading, setPasswordlLoading] = useState(false)

  const requestUser = async () => {
    try {
      const user = await User.getMe()
      setProfile(user.data)
    } catch (error) {

    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    requestUser()
  }, [])

  useEffect(() => {
    console.log(profile);
    setForm(
      {
        email: profile.email,
        first_name: profile.first_name,
        last_name: profile.last_name,
        id: profile.id,
      }
    )
  }, [profile])


  const onSubmit = async (data: any) => {
    try {
      setEmailLoading(true)
      const res = await User.updateUserData(data.email, data.first_name, data.last_name, data.id)
      requestUser()
      setSuccesModal(true)
    } catch (error:any) {
      console.log(error)
      setErrors((prev: any) => ({ ...prev, current_password_email: error?.response?.data?.current_password }))
    }finally{
      setEmailLoading(false)
    }
  }

  const changePass = async (data: any) => {
    try {
      setPasswordlLoading(true)
      const res = await User.updatePassword(data.new_password, data.current_password)
      setSuccesModal(true)
    } catch (error: any) {
      console.log(error.response)
      setErrors((prev: any) => ({ ...prev, current_password_pass: error?.response?.data?.current_password }))
    }finally{
      setPasswordlLoading(false)
    }
  }

  return (
    <Container>
        <Helmet> {/* Add the Helmet component */}
        <title>My Profile Page at LEIpapa</title>
        <meta 
          name="description" 
          content="My Profile and Profile Settings Page at LEIpapa, the official LEI registration agent of Ubisecure RapidLEI." 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <SettingsPage>
          <div className={cl["settings__title-wrap"]}>
            <Title>My Profile</Title>
          </div>
          <div className={cl["container"]}>
          <SubTitle>
              <p>Take control of your online presence and make sure your details are up to date.</p>
          </SubTitle>
            <Form onSubmit={handleSubmit(onSubmit)} uiProps={{ width: "100%" }}>
              <Input
                placeholder='Email'
                uiProps={{ margin: "1rem 0px 0px" }}
                {...register("email", {
                  validation: (val: string) => val.length > 0,
                  errorMessage: "The field must not be empty"
                })}
              />
              <Input
                placeholder='First Name'
                uiProps={{ margin: "1rem 0px 0px" }}
                {...register("first_name", {
                  validation: (val: string) => val.length > 0,
                  errorMessage: "The field must not be empty"
                })}
              />
              <Input
                placeholder='Last Name'
                uiProps={{ margin: "1rem 0px 10px" }}
                {...register("last_name", {
                  validation: (val: string) => val.length > 0,
                  errorMessage: "The field must not be empty"
                })}
              />
              <span className={cl["buttons"]}>
              <Button styleType="primary" float='left' width='200px' height='40px'>{isEmailLoading ? <Loader background='white' /> : "Save" }</Button>
              </span>
            </Form>
            <Form onSubmit={PassFormSubmit(changePass)} uiProps={{ width: "100%" }}>
              <Input
                placeholder='New Password'
                uiProps={{ margin: "2rem 0px 0px" }}
                {...pass("new_password", {
                  validation: (val: string) => val.length > 0,
                  errorMessage: "The field must not be empty"
                })}
                type="password"
              />
              <Input
                uiProps={{ margin: "1rem 0px 10px" }}
                placeholder='Current Password'
                {...pass("current_password", {
                  validation: (val: string) => val.length > 0,
                  errorMessage: "The field must not be empty"
                })}
                type="password"
              />
              {errors.current_password_pass && <InputError >{errors.current_password_pass}</InputError>}
              <span className={cl["buttons"]}>
              <Button styleType="primary" float='left' width='200px' height='40px'>{isPasswordLoading ? <Loader background='white' /> : "Change Password" }</Button>
              </span>
            </Form>
          </div>

          <Modal isOpen={succesModal} closeModal={() => setSuccesModal(false)}>
            <div className={cl["wrap"]}>
              <img src={Done} alt="done" style={{ width: "20px", height: "20px" }} /> <span>Successfully changed</span>
            </div>
          </Modal>

        </SettingsPage>
      )}
    </Container>
  )
}

