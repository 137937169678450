export interface DataFormState {
  dataDataForm: any;
  loadingDataForm: boolean;
  errorDataForm: null | string;
}

export enum DataFormTypes {
  FETCH_DATAFORM = "FETCH_DATAFORM",
  FETCH_DATAFORM_SUCCESS = "FETCH_DATAFORM_SUCCESS",
  FETCH_DATAFORM_ERROR = "FETCH_DATAFORM_ERROR",
}

interface FetchDataFormAction {
  type: DataFormTypes.FETCH_DATAFORM;
}

interface FetchDataFormSuccessAction {
  type: DataFormTypes.FETCH_DATAFORM_SUCCESS;
  payload: any[];
}

interface FetchDataFormErrorAction {
  type: DataFormTypes.FETCH_DATAFORM_ERROR;
  payload: string;
}

export type DataFormAction =
  | FetchDataFormAction
  | FetchDataFormSuccessAction
  | FetchDataFormErrorAction;
