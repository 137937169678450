import styled from "styled-components";
/* default
max-width: 1100px;
*/

export default styled.div`
color: #ffffff;
max-width: 1200px;
padding: 0.2em 4em;
  margin: 0 auto;
  @media (max-width: 1100px) {
    padding: 0.2em 4em;
  }
  @media (max-width: 900px) {
    padding: 0.2em 3em;
  }
  @media (max-width: 600px) {
    padding: 0.2em 2em;
  }
`;
