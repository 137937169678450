import { useForm } from "../../../hooks/useForm";
import Input from "../../ui/ui kit/Input";
import Form from "../Form";
import regulars from "../../../constants/regulars";
import Button from "../../ui/ui kit/Button";
import cl from "./AuthorizationForm.module.sass";
import { IAuthorization } from "../../../types/user";
import User from "../../../api/services/users";
import { useState, MouseEvent } from "react";
import Loader from "../../ui/Loader";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import InputError from "../../ui/ui kit/InputError";

//margin between fields
const UIInputProps = {
  margin: "1.5rem 0px 0px",
};


export const AuthorizationForm = () => {
  const { setAuth } = useAuth();
  const { register, handleSubmit, resetForm } = useForm<IAuthorization>({
    preventDefault: true,
    submitValidate: true,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (data: IAuthorization) => {
    try {
      setLoading(true);
      const token = await User.login(data);
      resetForm();
      localStorage.setItem("token", token.data.auth_token);
      setAuth(true);
      navigate("/");
    }
    catch(e: any){
      setError("Unable to log in with provided credentials")
    }
    finally {
      setLoading(false);
    }
   
  };

  const onChange = () => {
    setError("")
  }

  const onClickLoginWithGoogle = async (event: MouseEvent<HTMLAnchorElement>) => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    // TODO: Надо что-то с этим придумать, код дублируется
    const params = new URLSearchParams({
      response_type: "code",
      client_id: "720644816731-fm4p4ej3jovrq4fddnpqirl6vbp2tg4p.apps.googleusercontent.com",
      redirect_uri: "https://app.leipapa.com/google",
      prompt: "select_account",
      access_type: "offline",
      scope,
    });

    window.location.href = `${googleAuthUrl}?${params}`;
  }


  return (

    <div className={cl["container"]}>
    <div className={cl["box-left"]}>
    <Form
      onSubmit={handleSubmit(onSubmit)}
      //form internal values
      uiProps={{ width: "100%", margin: "40px 0" }}
    >
      <h1 className={cl["title"]}>Log In</h1>

      <div className={cl["form-text"]}>
        <Input 
          {...register("email", {
            validation: (value) => regulars.EMAIL.test(value),
            errorMessage:  "Incorrect Email",
            onChange
          })}
          placeholder="Email"
          uiProps={UIInputProps}
        />

        <Input
          {...register("password", {
            validation: (value) => value.length >= 8,
            errorMessage: "Password should consist of more than 8 symbols",
            onChange
          })}
          placeholder="Password"
          uiProps={UIInputProps}
          type="password"
        />
        {error && <InputError>{error}</InputError>}
      </div>
      <footer className={cl["footer"]}>
        <Button type="submit" styleType="primary" width="100%">Log In</Button>
        {loading && <Loader/>}
        <div className={cl["pm-awesome-divider"]} data-label="or"></div>
        <a onClick={onClickLoginWithGoogle} className={cl["google-button"]}>
          <div className={cl["google-logo"]}>
            <img src="https://assets.getpostman.com/common-share/google-logo-icon-sign-in.svg" width="38px" height="38px"/>
          </div>
          <div className={cl["google-text"]}>Sign in with Google</div>
        </a>
        <br />
        <p className={cl["footer-text"]}>New to LEIpapa? <a href="/registration" title="Create an Account at LEIpapa">Create an Account</a></p>
        <br />
        <p className={cl["footer-text-forgot"]}><a href="/reset_password" title="Reset Password">Forgot Password?</a></p>
      </footer>
    </Form>
    </div>
      <div className={cl["box-right"]}>
        <p className={cl["box-right-title"]}>Enjoy the benefits of a complimentary NFT LEI certificate when you order an LEI registration for a 5 year term. </p>
        <p className={cl["box-right-text"]}><a href="https://leipapa.com/get-free-nft-lei-certificate/" title="Get free digital LEI certificate" target="_blank">Learn More</a></p>
      </div>
    </div>
  );
};
