import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'
import { theme } from "../../constants/theme";

export const SettingsPage = styled.div`
    max-width: 100%;
    color: ${theme.colors.input.black};
`

export const Title = styled.h1`
    max-width: 960px;
    display: contents;  
    margin: 2rem auto 0;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    // line-height: 1.2;
    @media(max-width: 767px) {
        display: block;
        text-align: center;
        margin-top: 0
    }
`

export const SubTitle = styled.p`
    margin: 15px auto;
    color: ${theme.colors.input.black};
    text-align: justify;
    // margin-top: 2em;
    // font-size: 1rem;
    // line-height: 1.5;

    @media (max-width: 767px){
        text-align: center;
    }
`
/* height: 61vh: styled.div */