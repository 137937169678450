const HOME = "/home";
const CONTACTS = "/contacts";
const REGISTRATION = "/registration";
const AUTHORIZATION = "/authorization";
const SEARCH = "/search";
const SERVICES = "/services";
const FAQ = "/faq";
const CARDS_ROUTES = {
  userRecords: "/records",
  userOrders: "/orders",
  publicStorage: "/storage",
};
const CREATE_LEI = "/create";
const RENEW_LEI = "/renew";
const TRANSFER_LEI = "/transfer";
const TRANSFER_LEI_REQUEST = "/transfer-request"
const LOADED_LEI = "/load";
const REGISTER_RELATIONSHIP = "/relationship";
const SETTINGS = "/settings";
const PAYMENT = "/payment";
const LERD = "/lerd";
const PASSWORD_RESET = "/reset_password";
const GOOGLE_OAUTH = "/google";


const paths = {
  HOME,
  CONTACTS,
  REGISTRATION,
  AUTHORIZATION,
  SEARCH,
  SERVICES,
  FAQ,
  CARDS_ROUTES,
  CREATE_LEI,
  RENEW_LEI,
  TRANSFER_LEI,
  TRANSFER_LEI_REQUEST,
  REGISTER_RELATIONSHIP,
  SETTINGS,
  LOADED_LEI,
  PAYMENT,
  LERD,
  PASSWORD_RESET,
  GOOGLE_OAUTH
};

export default paths;
