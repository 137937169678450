import cl from "./AdSearch.module.scss"

const AdSearchB = () => {
  return (
    <div className={cl["banner-wrap-2"]}>
      <div>
        <a href="https://go.leipapa.com/morgen" 
          title="Get Morgen Calendar and Tasks Free" 
          target="_blank" 
          rel="noreferrer noopener">
            <img 
              className={cl["banner"]} 
              src="/ads/ad3.webp" 
              title="Get Morgen Calendar and Tasks Free" 
              alt="Get Morgen Calendar and Tasks Free">
            </img>
        </a>
      </div>
      <div>
        <a 
          href="https://go.leipapa.com/hetzner" 
          title="Get €20 free credit with Hetzner" 
          target="_blank" 
          rel="noreferrer noopener">
            <img 
              className={cl["banner"]} 
              src="/ads/ad4.webp" 
              title="Get €20 free credit with Hetzner" 
              alt="Get €20 free credit with Hetzner">
            </img>
        </a>
        <a className={cl["advertise"]} title="Contact LEIpapa for advertising" href="https://leipapa.com/advertising">Advertise on LEIpapa</a>
      </div>
    </div>
  );
}

export default AdSearchB;
