import React, { useRef, useState, useCallback } from "react";
import { ReactComponent as TriangleDownIcon } from "./../../../assets/icons/header/triangledown.svg";
import { ReactComponent as LeiPapaIcon } from "../../../assets/icons/header/leiPapa.svg";
import { ReactComponent as KeyIcon } from "../../../assets/icons/header/key.svg";
import { ReactComponent as StackIcon } from "../../../assets/icons/header/stack.svg";
import { ReactComponent as EuroIcon } from "../../../assets/icons/header/euro.svg";
import { ReactComponent as HumanIcon } from "./../../../assets/icons/header/HumanBlack.svg";
import { ReactComponent as LoginIcon } from "./../../../assets/icons/header/login.svg";
import { ReactComponent as RegisterIcon } from "./../../../assets/icons/header/register.svg";
import { Header, Ul, Li, NavLink } from "./style";
import paths from "../../../constants/paths";
import { useAuth } from "../../../hooks/useAuth";
import cl from "./Navbar.module.sass";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const { isAuth, setAuth } = useAuth();

  const [modalShowed, setModalShowed] = useState<boolean>(false);
  const closeModal = () => setModalShowed(false);
  const toggleModal = () => setModalShowed(!modalShowed);
  const ref = useRef(null);
  const button = useRef(null);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setAuth(false);
    localStorage.removeItem("token");
    navigate("/");
  }, [isAuth])

  const clickHandler = (e: any) => {
    const tagname = e.target.tagName.toLowerCase();
    if (tagname === "a" || tagname === "span") {
      closeModal();
    }
  };

  useOnClickOutside(ref, closeModal);

  return (
    <Header>
      <div className={cl["container"]}>
        <div className={cl["inner__navigate"]}>
          <NavLink to="/" title="LEIpapa Home">
            <LeiPapaIcon />
          </NavLink>
          <nav>
            <Ul>
              <Li>
                <NavLink to="/" title="LEIpapa Home">
                <span className={cl["homebutton"]}>Home</span>
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/search" title="LEI Search">
                  <span className={cl["button__text"]}>LEI Search</span>
                </NavLink>
              </Li>
              {/* <Li>
                <NavLink to="/services">
                  <h2>Services</h2>
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/faq">
                  <h2>FAQ</h2>
                </NavLink>
              </Li>
              <Li>
              <NavLink to="/about">
                <h2>About Us</h2>
              </NavLink>
            </Li> */}
            </Ul>
          </nav>
        </div>
        <div className={cl["navbar__top-wrapper"]}>
          <button ref={button} className={cl["button"]} onClick={toggleModal}>  
            {isAuth ? (
              <span className={cl["button__text"]}>Account</span>
              ) : (
                <span className={cl["button__text"]}>Login</span>
              )
            }
            <TriangleDownIcon />
          </button>
          {modalShowed && isAuth && (
            <div className={cl["modal"]} ref={ref} onClick={clickHandler}>
              <div className={cl["modal__top-wrap"]}>
                <NavLink to={paths.CARDS_ROUTES.userRecords} className={cl["records"]}>
                  <StackIcon />
                  <span className={cl["text"]}>Records</span>
                </NavLink>

                <NavLink to={paths.CARDS_ROUTES.userOrders} className={cl["orders"]}>
                  <EuroIcon />
                  <span className={cl["text"]}>Orders</span>
                </NavLink>
              </div>
              <div>
              <NavLink to={paths.SETTINGS} className={cl["settings"]}>
                <KeyIcon />
                <span className={cl["text"]}>My Profile</span>
              </NavLink>
              <div ref={button} className={cl["orders"]} onClick={isAuth? logout: toggleModal}>
                <HumanIcon />
                {isAuth ? <span className={cl["text"]}>Logout</span> : <span className={cl["text"]}>login</span>}
                {!isAuth && <TriangleDownIcon />}
              </div>
              </div>

            </div>
          )}
          {modalShowed && !isAuth && (
            <div className={cl["modal"]} ref={ref} onClick={clickHandler}>
              <NavLink to={paths.AUTHORIZATION} className={cl["children"]}>
                  <LoginIcon />
                  <span className={cl["text"]}>Sign in</span>
              </NavLink>
              <NavLink to={paths.REGISTRATION} className={cl["children"]}>
                <RegisterIcon />
                  <span className={cl["text"]}>Register</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </Header>
  );
};
