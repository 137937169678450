import { theme } from "../../../../constants/theme";
import styled from "styled-components";

export type IStyledTypes = {
  styleType: string;
  fullWidth?: boolean;
  float?: string;
};

export const StyledButton = styled.button<IStyledTypes>`
  background-color: ${(props) =>
    props.styleType === "primary"
      ? theme.colors.blue.normal
      : theme.colors.gray.light};
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;
  // letter-spacing: 0.05em;

  display: block;
  float: ${props => props.float ? props.float : 'none'};
  width: 100%;
  margin-top: 20px;
  transition: all 0.3s;
  &:hover {
    background-color: #2b3956;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
