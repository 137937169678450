import React, { useCallback, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { RecordsPage, Title, SubTitle, NavLink, Hr } from "./style";
import Container from "../../components/ui/ui kit/Container";
import cl from "./Records.module.sass";
import SearchLei from "../../components/functional/SearchLei";
import { useAuth } from "../../hooks/useAuth";
// import { useDispatch } from "react-redux";
// import { fetchLeiCode } from "../../redux/action-creators/createLeiCodeReducer";
// import { useNavigate } from "react-router-dom";
import { CreateLeiService } from "../../api/services/create-lei";
import { ILei } from "../../types/lei";
import { joinClasses } from "../../utils/joinClasses";



export const Records = () => {
  const [isCreationsShow, setCreationsShow] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);
  const { isAuth } = useAuth(); 

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const requestLei = useCallback(async () => {
    const res = await CreateLeiService.requestLeiList()
    console.log("res", res);
    const lei = res.data.map((el: any) => ({
      lei: el.lei_number,
      status: el.registration_status,
      date: el.next_renewal_date,
      name: el.company_name,
      country: el.legal_jurisdiction,
    }))
    return lei
  }, [])

  const renderLei = (leis: ILei[]) => {
    return leis.map(
      ({ lei, name, country, status, date }) => (
        <div className={cl["wrap__items"]}>
          <div className={[cl["line"], cl["child__line"]].join(" ")}>
            <NavLink to={`/record-details/${lei}`} className={[cl["item"], cl["lei"]].join(" ")}>{lei}</NavLink>
            <div className={cl["item-name"]}>{name}</div>
            <div className={cl["item"]}>{country}</div>
            <div className={joinClasses(cl["item"], cl["status"], !(status === "ISSUED") ? cl["status-rejected"] : "") }>{status ? status: 'null'}</div>
            {/* <div className={cl["item"]}>{date.slice(0, 19).replace("T", " ")}</div> */}
            {date && (
            <div className={cl.item}>{date.slice(0, 19).replace("T", " ")}</div>
            )}
            
            </div>
        </div>
      )
    )
  }
   
  return (
    <Container>
      <Helmet> {/* Add the Helmet component */}
        <title>Records of LEI codes at LEIpapa</title>
        <meta 
          name="description" 
          content="Below are the records you have either registered, or renewed.
          The records (if any) are presented in order by the records approaching their Next Renewal Date." 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <RecordsPage>
          <div className={cl["title_wrap"]}>
            <Title>Records</Title>
          </div>
          <SubTitle>
              Press "Create LEI Application"👇 to Register, Renew or Transfer an LEI.
          </SubTitle>
          <div className={cl["create_lei-wrap"]}>
            <div className={cl["inner_button-lei"]}>
              <button
                className={cl["button"]}
                onClick={() => setCreationsShow((prev) => !prev)}
              >
                Create LEI Application
              </button>
            </div>
            {isCreationsShow && (
              <div className={cl["create_leicode"]}>
                <div className={cl["hidden_top"]}>
                  <div className={cl["wrap_button"]}>
                    <NavLink to="/create">
                      <button className={cl["button_top"]}>
                        <span className={cl["button_text"]}>Create new LEI</span>
                      </button>
                    </NavLink>
                  </div>
                  <p className={cl["top_text"]}>
                    Submit your request using a standard web template. This
                    option is useful for single or low volume submissions
                  </p>
                </div>

                <Hr />

                <div className={cl["hidden_bottom"]}>
                  <div className={cl["wrap_button"]}>
                    <NavLink to="/renew">
                      <button className={cl["button_top"]}>
                        <span className={cl["button_text"]}>Renew LEI</span>
                      </button>
                    </NavLink>
                  </div>
                  <p className={cl["bottom_text"]}>
                    Renew here any existing LEI. To keep an LEI active, it must be renewed every year. We use a fast, automated LEI renewal process.
                  </p>
                </div>

                <Hr />

                <div className={cl["hidden_bottom"]}>
                  <div className={cl["wrap_button"]}>
                    <NavLink to="/transfer">
                      <button className={cl["button_top"]}>
                        <span className={cl["button_text"]}>Transfer LEI</span>
                      </button>
                    </NavLink>
                  </div>
                  <p className={cl["bottom_text"]}>
                    Transfer your LEI to LEIpapa for full service. If another service provider manages your LEI, you can transfer it to LEIpapa for free.
                  </p>
                </div>

              </div>
            )}
            <SubTitle>
              <p>
                Below are the records you have either registered, or renewed. <br />The
                records (if any) are presented in order by the records approaching their
                Next Renewal Date.
              </p>
            </SubTitle>
            <SearchLei titles={["LEI", "Legal Name", "Country", "Status", "Next renewal date"]} renderedLei={renderLei} isPagination={false} requestLeis={requestLei} enableInput={false}/>
          </div>
        </RecordsPage>
      )}
    </Container>
  );
};
