import React, {useCallback, useState, useEffect} from "react"
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom"
import { CreateLeiService } from "../../api/services/create-lei"
import cl from "./style.module.css"

export const RecordDetails = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
      }, []);  

    const params = useParams()
    const navigate = useNavigate()
    console.log(params)
    const [orderData, setOrderData] = useState<any>()

    
    const lei_number = params.lei

    const requestLei = async () => {
        if(lei_number){
            const res = await CreateLeiService.leiDetails({"lei_number":lei_number});
            setOrderData(res.data)
        }
    }

    useEffect(() => {
        requestLei()
    }, [lei_number])


    useEffect(() => {
        navigate("information")
    }, [])

    return (
        <div className={cl["page"]}>
            <div className={cl["container"]}>
                <div className={cl["lei-title"]}>
                    <h1>Record Details</h1>
                    <NavLink to="/records" className={cl["back"]}>Back</NavLink>
                </div>
                <div className={cl["tabs"]}>
                    <NavLink to="information" className={(nav) => nav.isActive ? cl["active-tab"] : undefined}>Legal Entity Information</NavLink>
                    <NavLink to="address" className={(nav) => nav.isActive ? cl["active-tab"] : undefined}>Address</NavLink>
                </div>
                <Outlet context={{orderData}}/>
            </div>
        </div>
    )
} 