import React, { FC } from "react";
import { cards } from "../../data/HomeCards";
import { useAuth } from "../../hooks/useAuth";
import {
  CardPage,
  CardItem,
  Title,
  Subtitle,
  Description,
  NavLink,
} from "./style";

export const Card: FC = () => {
  const { isAuth } = useAuth();

  return (
    <CardPage>
      {cards.map(({ title, subtitle, description, path }) => 
        isAuth ? (
          <NavLink to={path} key={Math.random()} title={subtitle}>
            <CardItem>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
              <Description>{description}</Description>
            </CardItem>
          </NavLink>
        ) : (
          <NavLink to={'/authorization'} key={Math.random()} title={subtitle}>
            <CardItem>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
              <Description>{description}</Description>
            </CardItem>
          </NavLink>
        )
      )}
    </CardPage>
  );
};
