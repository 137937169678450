import React, { useEffect, useState } from 'react';
import md5 from 'crypto-js/md5';
import cl from "./style.module.css"

const contentfulSpaceId = "s3ld1nv3ic05";
const contentfulAccessToken = "J5p1zFGIfUdTsEE4T6q-tAWKHPwulZ79vb1aGowXxdk";
const contentTypeId = "leiMap";

const getGravatarUrl = (email : string) => {
    const trimmedEmail = email.trim().toLowerCase();
    const hash = md5(trimmedEmail).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=180`; // s=200 sets the size to 200px
}


interface AssetLink {
    sys: {
        id: string;
        linkType: string;
        type: string;
    };
    fields?: {
        file: {
            url: string;
            fileName?: string;
            contentType?: string;
        };
        title?: string;
    };
}

interface LEIInfo {
    email: string;
    phone: string;
    address: string;
    website01: string;
    website02: string;
    description: string;
    alert: string;
    logo?: string;
    file01: { url: string, title: string }[];
    file02: string;
}

interface FileDetails {
    url: string;
    title: string;
}

interface CheckLeiNumberProps {
    leiNumber: string;
    entityLegalName: string;
    onStatusChecked: (exists: boolean) => void; // Callback to pass existence status
}
const CheckLeiNumber: React.FC<CheckLeiNumberProps> = ({ leiNumber, entityLegalName }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [leiData, setLeiData] = useState<LEIInfo | null>(null);

    useEffect(() => {
        const fetchLEIData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://cdn.contentful.com/spaces/${contentfulSpaceId}/entries?access_token=${contentfulAccessToken}&content_type=${contentTypeId}&fields.leiNumber=${leiNumber}&include=2`);
                const data = await response.json();
                if (data && data.items.length > 0) {
                    const assets = (data.includes && data.includes.Asset) ? data.includes.Asset.reduce((acc: {[key: string]: FileDetails}, asset: any) => {
                        acc[asset.sys.id] = {
                            url: asset.fields.file.url,
                            title: asset.fields.title // Include title here
                        };
                        return acc;
                    }, {}) : {};
            
                    const entry = data.items[0].fields;
                    const logoAsset = entry.logo && assets[entry.logo.sys.id] ? assets[entry.logo.sys.id].url : undefined;
                    const fileUrls = entry.file01 ? entry.file01.map((link: { sys: { id: string } }) => ({
                        url: `${assets[link.sys.id]?.url}`,
                        title: assets[link.sys.id]?.title // Include title
                    })).filter((item: FileDetails) => item.url) : [];
        
                    setLeiData({
                        email: entry.email,
                        phone: entry.phone,
                        address: entry.address,
                        website01: entry.website01,
                        website02: entry.website02,
                        description: entry.description,
                        alert: entry.alert,
                        logo: logoAsset,
                        file01: fileUrls,
                        file02: entry.file02
                    });
                } else {
                    setLeiData(null);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLeiData(null);
            }
            setIsLoading(false);
        };
        
        
        if (leiNumber) {
            fetchLEIData();
        }
    }, [leiNumber]);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 
    
    if (isLoading) return <p style={{ textAlign: "center", justifyContent: "center" }}>Checking...🔎</p>;
    if (!leiData) return (
        <div className={cl["qr-row"]}>
            <img className={cl["mt-8"]} src={`https://api.qrserver.com/v1/create-qr-code/?data=https://app.leipapa.com/lei-details/${leiNumber}&size=180x180`} alt={`QR code of ${entityLegalName}, LEI ${leiNumber} at LEIpapa`} title={`QR code of ${entityLegalName}, LEI ${leiNumber} at LEIpapa`} />
            <div>
                <a className={cl["qr-button"]} href={`https://api.qrserver.com/v1/create-qr-code/?data=https://app.leipapa.com/lei-details/${leiNumber}&size=600x600&qzone=1`} target="_blank" title={`Download QR code of ${entityLegalName} in PNG`}>PNG</a>
                <a className={cl["qr-button"]} href={`https://api.qrserver.com/v1/create-qr-code/?data=https://app.leipapa.com/lei-details/${leiNumber}&size=600x600&format=SVG`} target="_blank" title={`Download QR code of ${entityLegalName} in SVG`}>SVG</a>
            </div>
        </div>
    );

    // const gravatarUrl = getGravatarUrl(leiData.email);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(leiData.address)}`;

    return (
        
        <div>
            {leiData?.alert && (
                <div className={cl["callout"]}>
                    <b>🔔 CAUTION!</b>
                    <p style={{marginTop: "1rem"}}></p>
                    {leiData.alert}
                </div>
            )}
            {leiData?.description && (
                <div className={cl["company-row"]}>
                    <div>
                        <img src={leiData && leiData.logo ? leiData.logo : getGravatarUrl(leiData?.email)} alt={`Logo of ${entityLegalName}`} title={`Logo of ${entityLegalName}`} />
                    </div>
                    <div>
                        <div style={{marginBottom: "0.5rem" }}><strong>{entityLegalName}</strong></div>
                        {leiData?.description && <p className={showFullDescription ? '' : cl.truncate} style={{ textAlign: "justify" }}>{leiData.description}</p>}
                        {leiData?.description && (
                                <div style={{ textAlign: "left" }}>
                                    <button style={{ color: "#4B7196" }} onClick={toggleDescription}>
                                        {showFullDescription ? 'View less...' : 'View more...'}
                                    </button>
                                </div>
                            )}
                        {leiData?.description && (
                                <div className={cl["more-info"]}>
                                    🙋‍♂️
                                    <div className={cl["tooltip-content"]}>
                                        <p style={{ fontSize: "small"}}>The information is provided by the company representative.</p>
                                        <p><a style={{ fontSize: "small"}} href="/advertising/" target='_blank' title="Add the information about your company to LEIpapa">Learn more...</a></p>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            )}
            <div className={cl["company-row2"]}>
                <div className={cl["first-row"]}><strong>{entityLegalName}</strong></div>
                <div>
                    <img src={`https://api.qrserver.com/v1/create-qr-code/?data=https://app.leipapa.com/lei-details/${leiNumber}&size=180x180`} alt={`QR code of ${entityLegalName}, LEI ${leiNumber} at LEIpapa`} title={`QR code of ${entityLegalName}, LEI ${leiNumber} at LEIpapa`} />
                    <div className={cl["qr-button"]}>
                        <a className={cl["qr-button"]} href={`https://api.qrserver.com/v1/create-qr-code/?data=https://app.leipapa.com/lei-details/${leiNumber}&size=600x600&qzone=1`} target="_blank" title={`Download QR code of ${entityLegalName} in PNG`}>PNG</a>
                        <a className={cl["qr-button"]} href={`https://api.qrserver.com/v1/create-qr-code/?data=https://app.leipapa.com/lei-details/${leiNumber}&size=600x600&format=SVG`} target="_blank" title={`Download QR code of ${entityLegalName} in SVG`}>SVG</a>
                    </div>
                </div>
                <div className={cl["cell"]}>                   
                    <h3>Contact Information</h3>
                    {leiData?.email && <p>Email: <a href={`mailto:${leiData.email}`} title={`Send an email to ${leiData.email}`}>{leiData.email}</a></p>}
                    {leiData?.phone && <p>Phone: <a href={`mailto:${leiData.phone}`} title={`Call to ${leiData.phone}`}>{leiData.phone}</a></p>}
                    {leiData?.address && (
                        <p>Address: <a href={googleMapsUrl} title={`Open map for ${entityLegalName}`} target="_blank" rel="noopener noreferrer">{leiData.address}</a></p>
                    )}
                </div>
                <div className={cl["cell"]}>
                    {(leiData?.website01 || leiData?.website02) && (
                        <h3>Websites</h3>
                    )}
                    {leiData?.website01 && leiData.website01.length > 0 && (
                        <p><a href={leiData.website01} title={`Online resource of ${entityLegalName}`} target="_blank">{leiData.website01}</a></p>
                    )}
                    {leiData?.website02 && (
                        <p><a href={leiData.website02} title={`Online resource of ${entityLegalName}`} target="_blank">{leiData.website02}</a></p>
                    )}
                </div>
                <div className={cl["cell"]}>
                    {leiData?.file01 && leiData.file01.length > 0 && (
                        <div>        
                            <h3>Files</h3>          
                            <ul>
                            {leiData.file01.map((file, index) => (
                                <li key={index}>
                                    <a href={`https:${file.url}`} target="_blank" rel="noopener noreferrer" title={file.title || `File ${index + 1}`}>
                                        {file.title || `File ${index + 1}`}
                                    </a>
                                </li>
                            ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CheckLeiNumber;
