import paths from "../constants/paths";
import { ICard } from "../types/cards";


export const cards:ICard[] = [
    {
        title: "Apply Here",
        subtitle: "Apply for an LEI code, register new LEI",
        description: "From €59 + VAT",
        path: paths.CREATE_LEI
    },
    {
        title: "Renew Here",
        subtitle: "Renew your LEI code and keep it active",
        description: "From €59 + VAT",
        path: paths.RENEW_LEI
    },
    {
        title: "Transfer Here",
        subtitle: "Transfer your LEI code to us for full service",
        description: "Free of charge",
        path: paths.TRANSFER_LEI
    }
]
