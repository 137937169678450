import { DataFormAction, DataFormState, DataFormTypes } from "../../types/loadingDataForm";

const initialState: DataFormState = {
  dataDataForm: undefined,
  loadingDataForm: false,
  errorDataForm: null,
};

export const dataFormReducer = (
  state = initialState, action:DataFormAction): DataFormState => {
  switch (action.type) {
    case DataFormTypes.FETCH_DATAFORM:
      return { loadingDataForm: true, errorDataForm: null, dataDataForm: undefined };

    case DataFormTypes.FETCH_DATAFORM_SUCCESS:
      return { loadingDataForm: false, errorDataForm: null, dataDataForm: action.payload };

    case DataFormTypes.FETCH_DATAFORM_ERROR:
      return { loadingDataForm: false, errorDataForm: action.payload, dataDataForm: undefined};

    default:
        return state
  }
};
