import React, { FC } from "react"
// import { IInputOptions } from "../../../../types/form"
import cl from "./input.module.sass"
import {IUiProps} from "../../../../types/uiProps"
import InputError from "../InputError"
import { joinClasses } from "../../../../utils/joinClasses"

interface IProps {
    onChange?: (e:any) => void,
    onBlur?: () => void
    name?: string,
    value?: string | number ,
    placeholder?: string,
    uiProps?: IUiProps,
    type?: string,
    isError?: boolean,
    errorMessage?: string,
    disabled?: boolean,
    isRequired?: boolean;
    readOnly?:boolean;
    id?: string;
}

export const Input:FC<IProps> = ({
    name,
    value,
    onChange,
    errorMessage,
    isError,
    onBlur,
    placeholder,
    uiProps,
    type,
    disabled,
    isRequired,
    readOnly
}) => {
    return (
        <div className={joinClasses(cl["input-container"], disabled ? cl["disabled"] : "") } style={uiProps}>
            {!(type === "checkbox") ? 
                <div className={cl["input-wrap"]}>
                    {isRequired
                        ?<div className={cl["placeholder"]}>{placeholder}<span style={{color: 'red'}}> *</span></div>
                        // ?<div className={cl["placeholder"]}>{placeholder}<span style={{color: 'red'}}> *<i style={{fontSize: 14}}>required</i></span></div>
                        :<div className={cl["placeholder"]}>{placeholder}</div>
                    }
                    <input 
                        type={type || "text"} 
                        id={name} name={name} 
                        value={value} 
                        onBlur={onBlur} 
                        onChange={onChange}
                        disabled={disabled} 
                        className ={[isError && cl["input-error"], cl["input"]].join(" ")}
                        readOnly={readOnly}
                        required={isRequired} 
                    />
                    {isError && <InputError htmlFor={name}>{errorMessage}</InputError>}
                </div> : <>
                    <div className={cl["input-wrapper"]}>
                        <input 
                            type={type} 
                            id={name} 
                            value={value} 
                            onChange={onChange}  
                            className ={[isError && cl["input-error"], cl["input-checkbox"]].join(" ")}
                            readOnly={readOnly}
                            required={isRequired}
                        />
                        <div >{placeholder}</div>
                    </div>
                    {isError && <InputError htmlFor={name}>{errorMessage}</InputError>}
                </>
            }
        </div>
    )
}