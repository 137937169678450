import React, { FC } from "react"
import { IUiProps } from "../../../types/uiProps"

interface IProps {
    onSubmit: (e:React.FormEvent) => void,
    children: React.ReactNode,
    uiProps?: IUiProps
}

export const Form:FC<IProps> = ({onSubmit, children, uiProps}) => {
    return <form onSubmit={onSubmit} style={uiProps}>{children}</form>
}