import { LeiCodeAction, LeiCodeState, LeiCodeTypes } from "../../types/loadingLeiCode";

const initialState: LeiCodeState = {
  dataLeiCode: undefined,
  loadingLeiCode: false,
  errorLeiCode: null,
};

export const createLeiCodeReducer = (
  state = initialState, action:LeiCodeAction): LeiCodeState => {
  switch (action.type) {
    case LeiCodeTypes.FETCH_LEICODE:
      return { loadingLeiCode: true, errorLeiCode: null, dataLeiCode: undefined };

    case LeiCodeTypes.FETCH_LEICODE_SUCCESS:
      return { loadingLeiCode: false, errorLeiCode: null, dataLeiCode: action.payload };

    case LeiCodeTypes.FETCH_LEICODE_ERROR:
      return { loadingLeiCode: false, errorLeiCode: action.payload, dataLeiCode: undefined};

    default:
        return state
  }
};
