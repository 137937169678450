import React, { FC } from "react";
import ReactDOM from "react-dom";
import { ModalStyled } from "./style";

interface IClose {
  width?: number;
  height?: number;
  closeModal: () => void;
  children: React.ReactNode;
  isOpen: boolean
}

export const Modal: FC<IClose> = ({ width, height, closeModal, isOpen,  children }) => {

  const clickOverlay = () => {
     closeModal();
  }
  return isOpen ?  ReactDOM.createPortal(
    <ModalStyled>
        <div className="modal" style={{ minWidth: width, minHeight: height }}>
          <div className="children"> {children} </div>
        </div>
      <div className="overlay" onClick={clickOverlay}></div>
    </ModalStyled>,
    document.getElementById("modal")!
  ) : <></>;
};
