import { AxiosResponse } from "axios";
import $api from "../..";
// import { ICreateLeiCode } from '../../../types/registerLeiCode';

export class CreateLeiService {
  static async requestCountries(): Promise<AxiosResponse<any>> {
    return $api.get("order/jurisdictions")
  }

  static async requestOrderList(): Promise<AxiosResponse<any>> {
    return $api.get("order/list")
  }
  
  static async requestLeiList(): Promise<AxiosResponse<any>> {
    return $api.get("order/records")
  }

  static async registrationIdAndEntityLegalForm(countries:string): Promise<AxiosResponse<any>> {
    return $api.get(`order/jurisdictions/details?country_code=${countries}`)
  }

  static async createLeiCode(data={}) {
    return await $api.post("order/create/", data);
  }

  static async createPaymentLink(data: any): Promise<AxiosResponse<any>> {
    return await $api.post("order/payment/create/", data);
  }

  static async createLoadedLeiCode(data: any): Promise<void> {
    return await $api.post("order/import/", data);
  }

  static async preSearch(data: any): Promise<AxiosResponse<any>> {
    return await $api.post("order/presearch/", data);
  }

  static async orderStatus(data: any): Promise<AxiosResponse<any>> {
    return await $api.post("order/status/", data);
  }

  static async checkOrderStatus(data: any): Promise<AxiosResponse<any>> {
    return await $api.post("order/checkstatus/", data);
  }

  static async orderSubmitStatus(data: any): Promise<AxiosResponse<any>> {
    return await $api.post("order/submitstatus/", data);
  }

  static async orderConfirm(data: any): Promise<AxiosResponse<any>> {
    return await $api.post("order/confirm/", data);
  }

  static async orderDetails(data:any): Promise<AxiosResponse<any>> {
    return await $api.post("order/details/", data);
  }

  static async leiDetails(data: any): Promise<AxiosResponse<any>> {
    return await $api.post("order/leidetails/", data);
  }
}