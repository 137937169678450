import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { joinClasses } from "../../../../utils/joinClasses";
import InputError from "../InputError";
import classes from "./select.module.sass";
import {IUiProps} from "../../../../types/uiProps"
import { ReactComponent as TriangleDownlIcon } from "../../../../assets/icons/select/triangledown.svg";

interface ISelect {
  variants: string[];
  onChange: (e: any) => void;
  name?: string;
  showedVars?: number;
  errorMessage?: string;
  isError?: boolean;
  defaultValue?: string;
  placeholder?: string;
  uiProps?: IUiProps,
  isRequired?: boolean;
}

export const Select: FC<ISelect> = React.memo(
  ({
    variants,
    onChange,
    name,
    showedVars,
    errorMessage,
    isError,
    defaultValue,
    placeholder,
    uiProps,
    isRequired
  }) => {
    const [vars, setVars] = useState(variants);
    const [searchInput, setSearchInput] = useState("");
    const [isDropdownShowed, setDropdownShowed] = useState(false);
    let quantityVars = showedVars || 5;

    useEffect(() => {
      if (searchInput === "") {
        setDropdownShowed(false);
        setVars(variants);
      } else {
        const res = variants.filter((el) =>
          el.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
        );

        setVars(res);
      }
    }, [searchInput]);

    useEffect(() => {
      if (defaultValue) {
        setSearchInput(defaultValue);
        onChange({
          target: {
            name,
            value: defaultValue,
          },
        });
      } else {
        setSearchInput(variants[0]);
        onChange({
          target: {
            name,
            value: variants[0],
          },
        });
      }
    }, []);

    const changeHandler = (selected: string) => {
      setDropdownShowed(false);
      setSearchInput(selected);
      setVars(variants);
      onChange({
        target: {
          name,
          value: selected,
        },
      });
    };

    // useEffect(() => {
    //     setDropdownShowed(false);
    //     setSearchInput(defaultValue || "");
    //     setVars(variants);
    // }, [variants])

    useEffect(() => {
      setVars(variants);
      if (variants.length > 0 && !defaultValue) {
        setSearchInput(variants[0]);
      }
    }, [variants]);

    const height = useMemo(() => {
      let height = "";
      if (isDropdownShowed) {
        if (vars.length > 0) {
          if (searchInput.length > 70 && vars.length > quantityVars) {
            return quantityVars * 50 + 4 + "px";
          }
          console.log(vars[0].length)
          if(vars.length === 2 && vars[0].length > 70){
            return "105px"
          }
          if (vars.length === 1 && searchInput.length > 60) {
            return "60px";
          }
          if (vars.length > quantityVars) {
            height = quantityVars * 40 + 4 + "px";
          } else {
            height = vars.length * 40 + 4 + "px";
          }
        } else {
          height = "44px";
        }
      } else {
        height = "0px";
      }

      return height;
    }, [vars, isDropdownShowed, searchInput]);

    return (
      <div className={classes.select} style={uiProps}>
        {isRequired
          ?<div className={classes["placeholder"]}>{placeholder}<span style={{color: 'red'}}> *</span></div>
          // ?<div className={classes["placeholder"]}>{placeholder}<span style={{color: 'red'}}> *<i style={{fontSize: 14}}>required</i></span></div>
          :<div className={classes["placeholder"]}>{placeholder}</div>
        }
        <div className={classes["select-title"]}>
          <input
            type="text"
            value={searchInput}
            onChange={(e: any) => {
              setSearchInput(e.target.value);
              setDropdownShowed(true);
            }}
            className={classes.input}
          />
          <div
            className={joinClasses(
              classes["show"],
              isDropdownShowed ? classes["active"] : ""
            )}
            onClick={() => setDropdownShowed((prev) => !prev)}
          >
            <TriangleDownlIcon />
          </div>
        </div>
        <div
          className={joinClasses(
            classes["select-dropdown"],
            isDropdownShowed ? classes["select-dropdown-active"] : ""
          )}
          style={{
            height,
            overflowY: "scroll",
          }}
        >
          <ul>
            {vars.length > 0 ? (
              vars.map((el) => (
                <li onClick={() => changeHandler(el)} key={el}>
                  {el}
                </li>
              ))
            ) : (
              <li>not found</li>
            )}
          </ul>
        </div>
        {/* {isError && <InputError>{errorMessage}</InputError>} */}
      </div>
    );
  }
);
