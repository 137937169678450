import cl from "./AdSearch.module.scss"

const AdSearchA = () => {
  return (
    <div className={cl["banner-wrap-1"]}>
      <div>
        <a 
          href="https://go.leipapa.com/toptal" 
          title="Get $2000 free credit with Toptal Freelance" 
          target="_blank" 
          rel="noreferrer noopener">
            <img 
              className={cl["banner"]} 
              src="/ads/ad1.webp" 
              title="Get $2000 free credit with Toptal Freelance" 
              alt="Get $2000 free credit with Toptal Freelance">
            </img>
        </a>
        <a className={cl["advertise"]} title="Contact LEIpapa for advertising" href="https://leipapa.com/advertising">Advertise on LEIpapa</a>
      </div>
    </div>
  );
}

export default AdSearchA;
