export const theme = {
  colors: {
    blue: {
      normal: "#4b7196",
      light: "#cfe2f3",
      dark: "#2b3956"
    },
    gray: {
      light: "#5caaed",
    },
    white: {
      white: "#FFFF",
    },
    red: {
      normal: "#e8464e",
    },
    input: {
      black: "#000000",
      white: "#ffff",
      blue: "#2b3956"
    }
  },
};
