import React, { useCallback, useEffect, useMemo, useState } from "react";
// import ReactDOM from "react-dom";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import cl from "./Lerd.module.sass";
import { useAuth } from "../../hooks/useAuth";
import {
  LerdPage,
  Title,
  // NavLink,
  Hr,
  SubTitle,
  TabName,
} from "./style";
import Container from "../../components/ui/ui kit/Container";
// import CardForm from "../../components/ui/ui kit/CardForm";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
// import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Input from "../../components/ui/ui kit/Input";
import { CreateLeiService } from "../../api/services/create-lei";
import Loader from "../../components/ui/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import Form from "../../components/functional/Form";
// import InputError from "../../components/ui/ui kit/InputError";
import { ICreateLeiCode } from "../../types/registerLeiCode";
import { useForm } from "../../hooks/useForm";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { fetchDataForm } from "../../redux/action-creators/dataFormReducer";
import LoadingIcon from "../../components/ui/LoadingIcon";

const UIInputProps = {
  width: "100%",
  margin: "10px 0px",
};

function sleep(ms: any) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const Lerd = () => {
  const { dataDataForm /*, loadingDataForm, errorDataForm*/ } = useTypedSelector(
    (state) => state.dataForm
  );

  const [searchParams] = useSearchParams();

  const isAuth = useAuth();
  const [agree, setAgree] = useState<boolean>(true);
  const [userComment, setUserComment] = useState('');
  const [lerdText, setLerdText] = useState(<span></span>);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [referenceData, setReferenceData] = useState({});
  // const [showPopup, setShowPopup] = useState(false);
  const [checkInitiated, setCheckInitiated] = useState(false);
  const [doneFlag, setDoneFlag] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  // userComment
  const handleUserCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserComment(e.target.value);
  };

  const orderType = useMemo(() => {
    return searchParams.get("order_type") || 'Registration';
  }, [searchParams]);
        
  const handleError = (error: any) => {
    // console.log(error.response.data)
    if (
      error.response.data &&
      typeof error.response.data === "object" &&
      "non_field_errors" in error.response.data
    ) {
      return "You have applied for LEI already."
    }
    return error["message"];
  };

  const { register, setForm /*, handleSubmit, resetForm*/ } = useForm<ICreateLeiCode>({
    preventDefault: true,
    submitValidate: true,
    defaultState: {},
  });

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await CreateLeiService.orderConfirm({
        "order_tracking_code": dataDataForm["trackingId"],
        "confirmation_status": agree ? "true" : "false",
        "user_comments": !agree && userComment.trim() ? userComment.trim() : '',
      });
    } catch (error) {
      console.log(error);
      setLerdText(<span style={{color: "red", fontWeight: "bold"}}>Error: {handleError(error)}</span>);
    } finally {
      setIsSubmitting(false);
    }
    // setShowPopup(true);
    navigate("/orders");
  }

  const requestFormData = useCallback(async (referenceData: any) => {
    try {
      console.log("referenceData", referenceData);

      if (!referenceData || Object.keys(referenceData).length === 0) {
        return;
      }

      setForm(
        { 
          company_name: referenceData.legalName,
          legal_jurisdiction: referenceData.legalJurisdiction,
          entity_type: referenceData.entityCategory,
          entity_legal_form: referenceData.entityLegalFormCode + ": " + referenceData.entityLegalForm,
          entity_status: referenceData.entityStatus,
          entity_creation_date: referenceData.entityCreationDate.slice(0,10),
          registration_authority: referenceData.registrationAuthority,
          registration_authority_id: referenceData.registrationAuthorityId,
          legal_address_street: referenceData.legalAddress.firstAddressLine,
          legal_address_house: referenceData.legalAddress.addressLine1,
          legal_address_suite:  referenceData.legalAddress.addressLine2,
          legal_address_country: referenceData.legalAddress.country,
          legal_address_region: referenceData.legalAddress.state,
          legal_address_city: referenceData.legalAddress.city,
          legal_address_postal_code: referenceData.legalAddress.postalCode,
          headquarter_address_street: referenceData.headquartersAddress.firstAddressLine,
          headquarter_address_house: referenceData.headquartersAddress.addressLine1,
          headquarter_address_suite:  referenceData.headquartersAddress.addressLine2,
          headquarter_address_country: referenceData.headquartersAddress.country,
          headquarter_address_region: referenceData.headquartersAddress.state,
          headquarter_address_city: referenceData.headquartersAddress.city,
          headquarter_address_postal_code: referenceData.headquartersAddress.postalCode,
        }
      );
      
    } catch (error) {
      console.log(error);
    }
  }, [setForm]);

  useEffect(() => {
    if (referenceData) {
      requestFormData(referenceData);
    }
  }, [referenceData, requestFormData]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const checkOrderStatus = useCallback(async () => {
    if (checkInitiated) {
      return;
    }
    
    setCheckInitiated(true);

    try {
      setDoneFlag(false);
      for (let i = 0; i < 50; i++) {
        console.log(i);

        const orderIdStr = searchParams.get("order_id");
        const orderId = orderIdStr ? (parseInt(orderIdStr) || 0) : 0;
        const orderTrackingCode = dataDataForm ? dataDataForm["trackingId"] : null;

        let statusBody: any

        if (orderId) {
          statusBody = {
            order_id: orderId
          };
        } else {
          statusBody = {
            order_tracking_code: orderTrackingCode
          }
        }

        if (orderId || orderTrackingCode) {
          console.log("trackingId", orderTrackingCode);
          console.log("orderId", orderId)
          const res = await CreateLeiService.orderStatus(statusBody);
          console.log(res);

          if (res.data && res.data.message === "Order not submitted yet") {
            setLerdText(<span style={{color: "#2B3956", fontWeight: "bold"}}>Please wait, your data is being processed (usually it takes 1-2 minutes to verify)</span>);
            await sleep(2000);
            continue;
          }

          if (res.data && res.data.data) {
            if (!orderTrackingCode && res.data.data["orderTrackingCode"]) {
              dispatch(fetchDataForm({"trackingId": res.data.data["orderTrackingCode"]}))
            }

            const orderStatus = res.data.data["orderStatus"];
  
            if (orderStatus === "pending") {
              // console.log('--> referenceData:', res.data.data.referenceData);
              setReferenceData(res.data.data.referenceData);
              setLoading(false);
              setDoneFlag(true);
              setLerdText(<span></span>);
              return;
            } else if (orderStatus === "pending_gui") {
              setLerdText(
                <span style={{color: "#2B3956", fontWeight: "bold"}}>
                  Thanks for your order! <br /><br />
                  According to the data provided, your LEI application requires manual processing. <br />
                  We will contact you by email to confirm your Legal Entity data. <br />
                  After that, the LEI code will be issued.
                </span>
              );
              setLoading(false);
              return;
            } else if (orderStatus === "failure") {
              setLerdText(<span style={{color: "red", fontWeight: "bold"}}>
                Failure: {res.data.data.message || res.data.data.adminMessage || "unable to process the requested action"}
              </span>);
              setLoading(false);
              return;
            } else if (orderStatus === "stopped") {
              setLerdText(<span style={{color: "red", fontWeight: "bold"}}>
                The {orderType} process is canceled. <br /><br />
                Please contact us if you believe this is an error which we need to resolve.
              </span>);
              setLoading(false);
              return;
            } else if (orderStatus === "complete") {
              setLerdText(<span style={{color: "#2B3956", fontWeight: "bold"}}>
                The order is completed!<br/><br/>
                {res.data.data.message && (<>{res.data.data.message}<br/><br/></>)}
                {res.data.data.leiNumber && (<>Lei Number: {res.data.data.leiNumber}</>)}
              </span>);
              setLoading(false);
              return;
            } else if (orderStatus in ["invalid_request", "authorization_error", "not_found", "unsupported_grant_type", "server_error"]) {
              setLerdText(<span style={{color: "red", fontWeight: "bold"}}>
                Error: {res.data.data.message || res.data.data.adminMessage || orderStatus}
              </span>);
              setLoading(false);
              return;
            } else {
              setLerdText(<span style={{color: "#2B3956", fontWeight: "bold"}}>
                Please wait, your data is being processed (usually it takes 1-2 minutes to verify)
              </span>);
            }
          }
          await sleep(5000);
        }
      }
      setLoading(false);
      // Case 4
      if (!doneFlag) {
        setLerdText(<span style={{color: "#2B3956", fontWeight: "bold"}}>
          Thanks for your order! <br /><br />
          According to the data provided, your LEI application requires manual processing. <br />
          We will contact you by email to confirm your Legal Entity data. <br />
          After that, the LEI code will be issued.
        </span>);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLerdText(<span style={{color: "red", fontWeight: "bold"}}>Error: {handleError(error)}</span>);
    }
  }, [checkInitiated, dataDataForm, dispatch, doneFlag, orderType, searchParams]);

  useEffect(() => {
    checkOrderStatus();
  }, [checkOrderStatus]);

  return (
    <Container>
      <Helmet> {/* don't forget: import { Helmet } from "react-helmet"; */}
        <title>LEI {orderType} – Legal Entity Reference Data Confirmation</title>
        <meta 
          name="description" 
          content={`LEI ${orderType} Process - Legal Entity Reference Data Confirmation. LEIpapa is an official LEI Registration Agent of Ubisecure RapidLEI. Press Create LEI Application to Register, Renew or Transfer an LEI.`} 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth.isAuth && (
        <LerdPage>
          <div className={cl["title_wrap"]}>
            <Title>Legal Entity Reference Data Confirmation</Title>

            {doneFlag && (
              <SubTitle>Please review the Legal Entity Reference Data (LE-RD) gathered from{" "}
                the Business Registry (Registration Authority) where the Legal Entity is currently 
                {orderType === "Registration"
                  ? " registered"
                  : orderType === "Transfer" || orderType === "RA-to-RA Transfer"
                    ? "transferred to"
                    : "renewed"
                }. If you see incorrect data choose the I DO NOT AGREE option at the bottom of the page to edit it.  
              </SubTitle>
            )}
          </div>
          <Hr/>
          {loading ? <Loader /> : ""}
          <div className={cl["card_errors"]} role="alert">{lerdText}</div>

          {!loading && doneFlag && (
            <Form onSubmit={() => {}} uiProps={{ width: "100%" }}>
              <TabName className={cl["tab_name"]}>Legal Entity</TabName>
              <div className={cl["card_body"]}>
                <div className={cl["input_wrap"]}>             
                  <Input
                    placeholder="Legal Name"
                    {...register("company_name", {})}
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    placeholder="Legal Jurisdiction"
                    {...register("legal_jurisdiction", {})}
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
                <div className={cl["input_wrap"]}>
                  <Input
                    {...register("entity_creation_date", {})}
                    placeholder="Entity Creation Date"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("entity_status")}
                    placeholder="Entity Status"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
                <div className={cl["input_wrap"]}>
                  <Input
                    {...register("registration_authority", {})}
                    placeholder="Registration Authority"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("registration_authority_id", {})}
                    placeholder="Registration Authority ID"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
                <div className={cl["input_wrap"]}>
                  <Input
                    {...register("entity_type")}
                    placeholder="Entity Type"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("entity_legal_form")}
                    placeholder="Entity legal form"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
              </div>

              <TabName className={cl["tab_name"]}>Legal Address</TabName>
              <div className={cl["card_body"]}>
                <div className={cl["input_wrap"]}>
                  <Input
                    {...register("legal_address_country", {})}
                    placeholder="Country"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("legal_address_region", {})}
                    placeholder="Region"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
                <div className={cl["input_wrap"]}>
                  <Input
                    {...register("legal_address_city", {})}
                    placeholder="City"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("legal_address_postal_code", {})}
                    placeholder="Postal code"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
                <div className={cl["input_wreck"]}>
                  <Input
                    {...register("legal_address_street", {})}
                    placeholder="Street"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("legal_address_house", {})}
                    placeholder="House"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("legal_address_suite")}
                    placeholder="Suite"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
              </div>

              <TabName className={cl["tab_name"]}>Headquarter Address</TabName>
              <div className={cl["card_body"]}>
                <div className={cl["input_wrap"]}>
                  <Input
                    {...register("headquarter_address_country", {})}
                    placeholder="Country"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("headquarter_address_region", {})}
                    placeholder="Region"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
                <div className={cl["input_wrap"]}>
                  <Input
                    {...register("headquarter_address_city", {})}
                    placeholder="City"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("headquarter_address_postal_code", {})}
                    placeholder="Postal code"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
                <div className={cl["input_wreck"]}>
                  <Input
                    {...register("headquarter_address_street", {})}
                    placeholder="Street"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("headquarter_address_house", {})}
                    placeholder="House"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                  <Input
                    {...register("headquarter_address_suite")}
                    placeholder="Suite"
                    uiProps={UIInputProps}
                    readOnly={true}
                  />
                </div>
              </div>
              <div style={{textAlign: "justify"}}>

              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '1.5'}}>
                <input
                  style={{ marginRight: '15px' }}
                  id="agree"
                  type="radio"
                  name="agreement"
                  checked={agree}
                  onChange={(e) => setAgree(e.target.checked)}
                />
                <label htmlFor="agree">
                  I AGREE that the Legal Entity Reference Data (LE-RD) shown above is correct and accurate in all respects, and I'd like to progress to the next stage
                </label>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '1.5'}}>
                <input
                  id="notagree"
                  style={{ marginRight: '15px' }}
                  type="radio"
                  name="agreement"
                  checked={!agree}
                  onChange={(e) => setAgree(!e.target.checked)}
                />
                <label htmlFor="notagree">
                  I DO NOT AGREE that the Legal Entity Reference Data (LE-RD) shown above is correct. I'd like to amend it or provide feedback to the vetting team.
                </label>
              </div>
              {!agree && (
                <div style={{marginTop: '10px', lineHeight: '1.5'}}>
                  <label htmlFor="userComment">
                    If you wish to provide some simple feedback notes to our vetting teams on what's missing or what's wrong please do that using this input box. 
                    We'll attempt to make the modifications for you and let you know when we've completed our task.
                  </label>
                  <textarea 
                    className={cl["textarea_custom"]}
                    id="userComment"
                    placeholder="Max 400 characters"
                    maxLength={400}
                    value={userComment}
                    onChange={handleUserCommentChange}
                  ></textarea>
                </div>
              )}

                {/* <Input
                  uiProps={{ margin: "15px 0" }}
                  type="checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree(e.target.checked)}
                  placeholder="I AGREE that the Legal Entity Reference Data (LE-RD) shown above is correct and accurate in all respects and I'd like to progress to the next stage."
                />
                  <Input
                  uiProps={{ margin: "15px 0" }}
                  type="checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotAgree(e.target.checked)}
                  placeholder="I DO NOT AGREE that the Legal Entity Reference Data (LE-RD) shown above is correct. I'd like to amend it or provide feedback to the vetting team."
                /> */}
            </div>
          </Form>
          )}
        </LerdPage>
      )}
      <div className={cl["title_inner-button"]}>
        {/* <NavLink to="/relationship">
          <button className={cl["button_back"]}>
            <NextIcon className={cl["button_next-rotate"]} />
            <span className={cl["button_text"]}>Back</span>
          </button>
        </NavLink> */}
        {!loading && !doneFlag && (
          <button
            className={cl["button_next"]}
            onClick={() => navigate("/orders")}
            disabled={!agree && !userComment}
          >
            <NextIcon />
            <span className={cl["button_text"]}>Go to Orders</span>
          </button>
        )}
        {!loading && doneFlag && (
          <button className={cl["button_next"]} onClick={onSubmit} disabled={isSubmitting}>
            {isSubmitting ? <LoadingIcon /> : <NextIcon />}
            <span className={cl["button_text"]}>Submit to LEIpapa</span>
          </button>
        )}
      </div>
      
      {/* {showPopup && (
        <div className={cl["popup"]}>
          <div className={cl["popup-content"]}>
            <h2>Thank You</h2>
            <p>Message or content of the popup</p>
          </div>
        </div>
      )} */}

    </Container>
  );
    
};