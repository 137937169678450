import { useEffect } from 'react';
import { IFormOptions, IInputOptions, ISelectChangeType, IValidationError, IValidationItem } from './../types/form';
import { FormEvent, useState } from 'react';

export const useForm = <T>(options?:IFormOptions) => {
    const [form, setForm] = useState<T | any>(options?.defaultState || {})
    const [errors, setErrors] = useState<IValidationError>({})
    const validationPatterns:IValidationItem[] = []

    const register = (name: string, options?: IInputOptions) => {
        const onChange = (e:React.ChangeEvent<HTMLInputElement> | ISelectChangeType) => {
            if(options?.onChange){
                options.onChange(e.target.value)
            }
            if(options?.validation){
                const isValid = options.validation<T>(e.target.value , form)
                if(!isValid){
                    setErrors(prev => ({...prev, [name]: true}))
                }
                else{
                    setErrors(prev => ({...prev, [name]: false}))
                }
            }
            
            setForm({...form, [e.target.name]: e.target.value})
        }

        const onBlur = () => {
            const value = form[name]
            if(value === ""){
                setErrors(prev => ({...prev, [name]: false}))
            }
        }

        validationPatterns.push({...options, name})
        
        return {
            name,
            onChange,
            value: form[name] || "",
            errorMessage: options?.errorMessage,
            isError: errors[name],
            onBlur
        }
    }

    const handleSubmit = (callback: (form: T | any) => void) => {
        return (e: FormEvent) => {
            if(options?.preventDefault){
                e.preventDefault()
            }

            if(options?.submitValidate){
                const isAllFieldsValid = validationPatterns.map((el) => {
                    const { name, validation } = el
                    if(validation){
                        const isValid = validation<T>(form[name] || '', form)
                        if(!isValid){
                            setErrors(prev => ({...prev, [name]: true}))
                        } else{
                            setErrors(prev => ({...prev, [name]: false}))
                        }
                        return isValid
                    }
                    return true
                }).every((el) => el)
                

                if(!isAllFieldsValid) return 
                return callback(form)
            }
            callback(form)
        }
    }
    

    const resetForm = () => {
        setForm({})
        setErrors({})
    }

    useEffect(() => {
        return () => {
            setForm({})
            setErrors({})
        }
    },[])

    return { register, handleSubmit, resetForm, setForm }
}