import { createContext } from 'react';
export interface IAuthContext {
    isAuth: boolean,
    setAuth: (value: boolean) => void
}

const initialContext = {
    isAuth: false,
    setAuth: () => {}
}

export const AuthContext = createContext<IAuthContext>(initialContext)