import { IRoute } from "../types/router";
import { Route } from "react-router-dom";

export const render = (route: IRoute) => {
    return route.nestedRoutes ? (
        <Route path={route.path} key={Math.random()} element={<route.component {...route.props} />}>
            {route.nestedRoutes.map(render)}
        </Route>
    ) : (
        <Route path={route.path} key={Math.random()} index={route.index} element={<route.component {...route.props} />} />
    )
}