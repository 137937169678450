import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const FooterPage = styled.footer`
    background-color: #2B3956;
    padding: 3rem 3rem;
    border-top: 1px solid rgba(128, 128, 128, 0.1);
    color: #fff;
    margin-top: 3rem;

    @media (max-width: 800px) {
        padding: 3rem 2rem;
    }
`;

export const Ul = styled.ul`
    display:grid;
    grid-template-columns: 35% 30% 35%;
    list-style-type: none;
    text-align: center;
    margin: 15px auto 0px;
    max-width: 1100px;
    @media (max-width: 800px) {
        display: block;
        width: 100%;
        margin-bottom: 2em;
        text-align: center;
        padding-left: 0;
    }

    `;

export const NavLink = styled(Link)`
    color: #fff;
    text-decoration: none;
`;

export const Li = styled.li`
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 0.9rem;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 800px) {
        text-align: center;
    }
`;

export const Text = styled.div`
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
    @media (max-width: 800px) {
        text-align: center;
        padding: 0 1.5em;
    }
`;
