import React, { useEffect } from "react";
import styled from "styled-components"
import { theme } from "../../constants/theme";
import Container from "../../components/ui/ui kit/Container";
import { Helmet } from "react-helmet";

const messages = [
    <>
        Did you know? LEIpapa is a tech company from Estonia 🇪🇪 an amazing European country where unicorns such as Wise, Skype, Playtech, Bolt, and Pipedrive were born. LEIpapa is controlled by a <a href="https://leipapa.com/about/" target="_blank" rel="noopener noreferrer" title="About LEIpapa">team</a> experienced in fintech project development with a strong focus on customer experience and life-long customer care.
    </>,
    <>
        Did you know? Estonia 🇪🇪 the birthplace of LEIpapa, boasts a vibrant tech scene and a strong e-governance model. It's the land where people vote online and kids learn coding from a young age. Controlled by a <a href="https://leipapa.com/about/" target="_blank" rel="noopener noreferrer" title="About LEIpapa">team</a> experienced in fintech project development and proudly following in the footsteps of this digital nation, LEIpapa brings you a seamless online experience.
    </>,
    <>
        Did you know? Controlled by a <a href="https://leipapa.com/about/" target="_blank" rel="noopener noreferrer" title="About LEIpapa">team</a> experienced in fintech project development, LEIpapa is proud to hail from Estonia 🇪🇪 a Baltic nation that's a digital titan! From introducing the world to e-residency to pioneering digital signatures, Estonia sets the gold standard for digital innovation. At LEIpapa, we're inspired every day by our homeland's tech-driven ethos.
    </>,
    <>
        Did you know? Estonia 🇪🇪 is an amazing country where every school has robots, every citizen has a digital ID, and every startup dreams big. Among those startups is LEIpapa, embodying the Estonian spirit of innovation, trust, and global ambition. LEIpapa is controlled by a <a href="https://leipapa.com/about/" target="_blank" rel="noopener noreferrer" title="About LEIpapa">team</a> experienced in fintech project development with a strong focus on customer experience and life-long customer care.
    </>,
    <>
        Did you know? In Estonia 🇪🇪 we say you're never more than a 30-minute drive from a forest. Our country is an amazing place where, in just a few clicks, you can start a business, pay taxes, or even get medical advice virtually! Controlled by a <a href="https://leipapa.com/about/" target="_blank" rel="noopener noreferrer" title="About LEIpapa">team</a> experienced in fintech project development, LEIpapa is proud to combine this love for nature with a knack for efficient digital solutions.
    </>,
];
const randomMessage = messages[Math.floor(Math.random() * messages.length)];

const Page = styled.div`
max-width: 100%;
color: ${theme.colors.input.black};
`

export const Title = styled.h1`
    // max-width: 960px;
    width: 100%;
    color: ${theme.colors.blue.dark};
    text-align: left;
    margin: 2rem 0 0 0;
    
    @media (max-width: 768px){
        text-align: center;
    }
    `
    export const SubTitle = styled.p`
    margin: 20px auto 0 auto;
    color: ${theme.colors.input.black};
    text-align: justify;
    font-size: 1rem;
    `
    export const List = styled.ol`
    margin: 10px 0 0 1rem
    `

    export const Li = styled.li`
    margin: 0 auto;
    color: ${theme.colors.input.black};
    text-align: left;
    font-size: 1rem;
    `

export const ErrorPage = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
      }, []);  
    
    return (
        <div>
            <Container>

            <Helmet> {/* don't forget: import { Helmet } from "react-helmet"; */}
                <title>Error 404: Page not found | LEIpapa</title>
                <meta 
                name="description" 
                content="Something went wrong! We can't seem to find the page you are looking for. By the way, here is the list of our resources or Press Create LEI Application to Register, Renew or Transfer an LEI." 
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Page>
            <Title>Error 404: Page not found</Title>
            <SubTitle>
                Something went wrong! We can't seem to find the page you are looking for. <br />
                <br />
                By the way, here is the list of our resources:
                <List>
                    <Li><a href="/">App Home Page</a></Li>
                    <Li><a href="https://leipapa.com" title="LEIpapa Website">LEIpapa Website</a></Li>
                    <Li><a href="https://nft.leipapa.com" title="LEIpapa NFT Community Platform" rel="noreferrer noopener" target="_blank">LEIpapa NFT Community Platform</a></Li>
                </List>
            </SubTitle>
            <SubTitle>
                {randomMessage}
            </SubTitle>
            </Page>
            </Container>
        </div>
    )
}