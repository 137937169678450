import { Dispatch } from "redux"
import { DataFormAction, DataFormTypes } from "../../types/loadingDataForm"


export const fetchDataForm = (chargedDataForm: any): any  => {
    return async (dispatch: Dispatch<DataFormAction>) => {
        try {
            dispatch({type:DataFormTypes.FETCH_DATAFORM})
            console.log('ACTION-CREATOR', chargedDataForm)
            dispatch({type:DataFormTypes.FETCH_DATAFORM_SUCCESS, payload:chargedDataForm})
        } catch (e) {
            dispatch({type:DataFormTypes.FETCH_DATAFORM_ERROR, payload: 'An error occurred while loading leicode'})
        }
    }
}