import React, { FC, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";

interface IProps {
    children: React.ReactNode
}

export const AuthContainer:FC<IProps> = ({children}) => {
    const [isAuth, setAuth] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem("token")

        if(token) setAuth(true)
    }, [])

    return (
        <AuthContext.Provider value={{isAuth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}