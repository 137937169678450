import React, { useCallback, useEffect, useState } from "react";
import Container from "../../components/ui/ui kit/Container";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import {
  LoadedLeiPage,
  Title,
  Hr,
  SubTitle,
  TabName,
  NavLink,
} from "./style";
import Input from "../../components/ui/ui kit/Input";
import InputFile from "../../components/ui/ui kit/InputFile";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import cl from "./LoadedLei.module.sass";
import Form from "../../components/functional/Form";
import InputError from "../../components/ui/ui kit/InputError";
import { ICreateLeiCode } from "../../types/registerLeiCode";
import { useForm } from "../../hooks/useForm";
import { CreateLeiService} from "../../api/services/create-lei";
import Select from "../../components/ui/ui kit/Select";
//import Select from 'react-select';
import { useDispatch } from "react-redux";
import { fetchDataForm } from "../../redux/action-creators/dataFormReducer";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { SearchLeiCode } from "../../api/services/search-lei";
import { Helmet } from "react-helmet";

const UIInputProps = {
  width: "100%",
  // margin: "10px 0px",
};

const defaultErrors = {
  company_name: "",
  company_number: "",
  legal_jurisdiction: "",
  first_name: "",
  last_name: "",
  is_level_2_data_available: "",
  reporting_exception_reason: "",
  multi_year_support: "",
  entity_type: "",
  entity_legal_form: "",
  lei_number: "",
  vat_id: "",
  entity_status: "",
  legal_address_street: "",
  legal_address_house: "",
  legal_address_suite: "",
  legal_address_country: "",
  legal_address_region: "",
  legal_address_city: "",
  legal_address_postal_code: "",
  headquarter_address_street: "",
  headquarter_address_house: "",
  headquarter_address_suite: "",
  headquarter_address_country: "",
  headquarter_address_region: "",
  headquarter_address_city: "",
  headquarter_address_postal_code: "",
  loa_file: "",
  corroborating_file_0: "",
  corroborating_file_1: "",
  corroborating_file_2: "",
  corroborating_file_3: "",
  corroborating_file_4: "",
  email: "",
  phone_number: "",
  managing_lou_lei: "",
  lei_registration_status: "",
};

export const LoadedLei = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }, []);  

  const isAuth = useAuth();

  const [errors, setErrors] = useState<ICreateLeiCode>(defaultErrors);
  const [agreeMember, setAgreeMember] = useState<boolean>(false);
  const [agreePower, setAgreePower] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<string[]>([]);
  const [registrationId, setRegistrationId] = useState<string[]>([]);
  const [entityForm, setEntityForm] = useState<string[]>([]);
  const [countryCode, setCountryCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [leiNumber, setLeiNumber] = useState("");
  const [companyNameList, setCompanyNameList] = useState<string[]>([]);
  const navigate = useNavigate();
  const [loaFile, setLoaFile] = useState("");
  const [corrFile_0, setCorrFile_0] = useState("");
  const [corrFile_1, setCorrFile_1] = useState("");
  const [corrFile_2, setCorrFile_2] = useState("");
  const [multi_year_support] = useState(1);

  // var countries = [
  //   "DK - Denmark",
  //   "EE - Estonia",
  //   "FI - Finland",
  //   "LT - Lithuania",
  //   "LV - Latvia",
  // ];

  
  const { register, handleSubmit, resetForm, setForm } = useForm<ICreateLeiCode>({
    preventDefault: true,
    submitValidate: true,
    defaultState: {
      // company_name: '-',
      // company_number: '-',
      // legal_jurisdiction: '-',
      // first_name: '-',
      // last_name: '-',
      is_level_2_data_available: '-',
      reporting_exception_reason: '-',
      multi_year_support:'1',
      entity_type: '-',
      vat_id: '-',
      // entity_legal_form: '-',
      // lei_number: '-',
      // entity_status: '-',
      // legal_address_street: '-',
      // legal_address_house: '-',
      // legal_address_suite:  '-',
      // legal_address_country: '-',
      // legal_address_region: '-',
      // legal_address_city: '-',
      // legal_address_postal_code: '-',
      //loa_file: '',
      //corroborating_file_0: '',
      //corroborating_file_1: '',
      //corroborating_file_2: '',
      // managing_lou_lei: '-',
      // lei_registration_status: '-',
    },
  });

  const onChange = (name: string) => {
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  
  const loaFileOnChange = (e:any) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        setLoaFile(reader.result?.toString() || '');
    };
  };

  const corrFileOnChange_0 = (e:any) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setCorrFile_0(reader.result?.toString() || '');
    };
  };

  const corrFileOnChange_1 = (e:any) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setCorrFile_1(reader.result?.toString() || '');
    };
  };

  const corrFileOnChange_2 = (e:any) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setCorrFile_2(reader.result?.toString() || '');
    };
  };

  const handleOnSearch = (string:any, results:any) => {
    setCompanyName(string);
  };
  
  const handleOnSelect = (item:any) => {
    setCompanyName(item["companyName"]);
    setLeiNumber(item["leiNumber"]);
  };
  
  const handleOnClear = () => {
    setCompanyName("");
  };


  const requestFormData = useCallback(async (string:string) => {
    try {
      setLoading(true);
      const res = await SearchLeiCode.getLei(string);
      console.log("data: ", res);

// проверить direct_parent, если информация есть, то поставить true в l2_data,
// если нет, то запрос по ссылке и указываем причину

      const legal_address_data = res.data.data.attributes.entity.legalAddress;
      const headquarter_address_data = res.data.data.attributes.entity.headquartersAddress;
      const legal_jurisdiction = res.data.data.attributes.entity.jurisdiction;
      const entity_type = res.data.data.attributes.entity.category;
      const entity_legal_form = res.data.data.attributes.entity.legalForm.id;
      const entity_status = res.data.data.attributes.entity.status;
      const lei_number = res.data.data.attributes.lei;
      const company_number = res.data.data.attributes.entity.registeredAs;
      // added
      const managing_lou_lei = res.data.data.attributes.registration.managingLou;
      const lei_registration_status = res.data.data.attributes.registration.status;
      
      setForm(
        {
          company_number: company_number,
          legal_jurisdiction: legal_jurisdiction,
          lei_registration_status: lei_registration_status,
          managing_lou_lei: managing_lou_lei,
          entity_type: entity_type,
          entity_legal_form: entity_legal_form,
          entity_status: entity_status,
          lei_number: lei_number,
          legal_address_street: legal_address_data["addressLines"],
          legal_address_house: legal_address_data["addressNumber"],
          legal_address_suite:  legal_address_data["addressNumberWithinBuilding"],
          legal_address_country: legal_address_data["country"],
          legal_address_region: legal_address_data["region"],
          legal_address_city: legal_address_data["city"],
          legal_address_postal_code: legal_address_data["postalCode"],
          headquarter_address_street: headquarter_address_data["addressLines"],
          headquarter_address_house: headquarter_address_data["addressNumber"],
          headquarter_address_suite:  headquarter_address_data["addressNumberWithinBuilding"],
          headquarter_address_country: headquarter_address_data["country"],
          headquarter_address_region: headquarter_address_data["region"],
          headquarter_address_city: headquarter_address_data["city"],
          headquarter_address_postal_code: headquarter_address_data["postalCode"],
          multi_year_support: multi_year_support,
        }
      );
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [leiNumber]);

  const requestCountries = useCallback(async () => {
    try {
      setLoading(true);
      const res = await CreateLeiService.requestCountries();
      setCountries(
        Object.keys(res.data.data).map((el) => el + " - " + res.data.data[el])
      );
      // var data: any = [];
      // for (const [key, value] of Object.entries(res.data.data)) {
      //   var country = key + " - " + res.data.data[key];
      //   data.push(
      //     {
      //       value:country,
      //       label:country,
      //     }
      //   )  
      // }
      // setCountries(data);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const requestCompanyNameList = useCallback(async (string:string) => {
    try {
      setLoading(true);
      const res = await SearchLeiCode.searchLei(string);

      var data: any = [];
      for (const [key, value] of Object.entries(res.data.data)) {
        var leiNumber = "";
        if ('relationships' in res.data.data[key]) 
        {
          leiNumber = res.data.data[key].relationships["lei-records"].data.id;
        }
        
        if (leiNumber) {
          data.push(
            {
              "id":key,
              "companyName":res.data.data[key].attributes.value,
              "leiNumber":leiNumber,
            }  
          ) 
        }
      }
      setCompanyNameList(data);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [companyName]); //, countryCode

  

  const registrationIdAndEntityLegalForm = useCallback(async () => {
    try {
      setLoading(true);
      const res = await CreateLeiService.registrationIdAndEntityLegalForm(
        countryCode
      );

      //setRegistrationId(res.data.data.registration_authorities);
      setEntityForm(res.data.data.entity_forms);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [countryCode]);

  useEffect(() => {
    requestCountries();
  }, []);

  useEffect(() => {
    console.log(companyNameList);
  }, [companyNameList]);
  

  useEffect(() => {
    if (countryCode) {
      registrationIdAndEntityLegalForm();
    }
  }, [countryCode]);

  useEffect(() => {
    if (companyName) {
      requestCompanyNameList(companyName);
    }
  }, [companyName]);

  useEffect(() => {
    if (leiNumber) {
      requestFormData(leiNumber);
    }
  }, [leiNumber]);

  const dispatch = useDispatch();

  const onSubmit = async (data: ICreateLeiCode) => {
    try {
      console.log("data", data);
      setLoading(true);
      const preparedData = {
        ...data,
        company_number: data.company_number.substring(
          0,
          data.company_number.indexOf(" ")
        ),
        legal_jurisdiction: data.legal_jurisdiction.substring(
          0,
          data.legal_jurisdiction.indexOf(" ")
        ),
        company_name: companyName,
        is_level_2_data_available: data.is_level_2_data_available === "true",
        loa_file: loaFile,
        corroborating_file_0: corrFile_0,
        corroborating_file_1: corrFile_1,
        corroborating_file_2: corrFile_2,
      };
      //const res = await CreateLeiService.createLeiCode(preparedData);
      //console.log("res", res);
      await dispatch(fetchDataForm(preparedData))
      navigate("/relationship");
    } catch (e: any) {
      const {
        company_name,
        company_number,
        legal_jurisdiction,
        first_name,
        last_name,
        is_level_2_data_available,
        reporting_exception_reason,
        multi_year_support,
        entity_type,
        entity_legal_form,
        lei_number,
        lei_registration_status,
        managing_lou_lei,
        entity_status,
        legal_address_street,
        legal_address_house,
        legal_address_suite,
        legal_address_country,
        legal_address_region,
        legal_address_city,
        legal_address_postal_code,
        headquarter_address_street,
        headquarter_address_house,
        headquarter_address_suite,
        headquarter_address_country,
        headquarter_address_region,
        headquarter_address_city,
        headquarter_address_postal_code,
        loa_file,
        corroborating_file_0,
        corroborating_file_1,
        corroborating_file_2,
        email,
        phone_number,
      } = e.response.data;
      if (company_name) {
        setErrors((prev) => ({ ...prev, company_name: company_name[0] }));
      }
      if (company_number) {
        setErrors((prev) => ({ ...prev, company_number: company_number[0] }));
      }
      if (legal_jurisdiction) {
        setErrors((prev) => ({
          ...prev,
          legal_jurisdiction: legal_jurisdiction[0],
        }));
      }
      if (first_name) {
        setErrors((prev) => ({ ...prev, first_name: first_name[0] }));
      }
      if (last_name) {
        setErrors((prev) => ({ ...prev, last_name: last_name[0] }));
      }
      if (email) {
        setErrors((prev) => ({ ...prev, email: email[0] }));
      }
      if (phone_number) {
        setErrors((prev) => ({ ...prev, phone_number: phone_number[0] }));
      }
      if (is_level_2_data_available) {
        setErrors((prev) => ({
          ...prev,
          is_level_2_data_available: is_level_2_data_available[0],
        }));
      }
      if (reporting_exception_reason) {
        setErrors((prev) => ({
          ...prev,
          reporting_exception_reason: reporting_exception_reason[0],
        }));
      }
      if (multi_year_support) {
        setErrors((prev) => ({
          ...prev,
          multi_year_support: multi_year_support[0],
        }));
      }
      if (entity_type) {
        setErrors((prev) => ({ ...prev, entity_type: entity_type[0] }));
      }
      if (entity_legal_form) {
        setErrors((prev) => ({
          ...prev,
          entity_legal_form: entity_legal_form[0],
        }));
      }
      if (lei_number) {
        setErrors((prev) => ({ ...prev, lei_number: lei_number[0] }));
      }
      if (entity_status) {
        setErrors((prev) => ({ ...prev, entity_status: entity_status[0] }));
      }
      if (legal_address_street) {
        setErrors((prev) => ({
          ...prev,
          legal_address_street: legal_address_street[0],
        }));
      }
      if (legal_address_house) {
        setErrors((prev) => ({
          ...prev,
          legal_address_house: legal_address_house[0],
        }));
      }
      if (legal_address_suite) {
        setErrors((prev) => ({
          ...prev,
          legal_address_suite: legal_address_suite[0],
        }));
      }
      if (legal_address_country) {
        setErrors((prev) => ({
          ...prev,
          legal_address_country: legal_address_country[0],
        }));
      }
      if (legal_address_region) {
        setErrors((prev) => ({
          ...prev,
          legal_address_region: legal_address_region[0],
        }));
      }
      if (legal_address_city) {
        setErrors((prev) => ({
          ...prev,
          legal_address_city: legal_address_city[0],
        }));
      }
      if (legal_address_postal_code) {
        setErrors((prev) => ({
          ...prev,
          legal_address_postal_code: legal_address_postal_code[0],
        }));
      }
      if (headquarter_address_street) {
        setErrors((prev) => ({
          ...prev,
          headquarter_address_street: headquarter_address_street[0],
        }));
      }
      if (headquarter_address_house) {
        setErrors((prev) => ({
          ...prev,
          headquarter_address_house: headquarter_address_house[0],
        }));
      }
      if (headquarter_address_suite) {
        setErrors((prev) => ({
          ...prev,
          headquarter_address_suite: headquarter_address_suite[0],
        }));
      }
      if (headquarter_address_country) {
        setErrors((prev) => ({
          ...prev,
          headquarter_address_country: headquarter_address_country[0],
        }));
      }
      if (headquarter_address_region) {
        setErrors((prev) => ({
          ...prev,
          headquarter_address_region: headquarter_address_region[0],
        }));
      }
      if (headquarter_address_city) {
        setErrors((prev) => ({
          ...prev,
          headquarter_address_city: headquarter_address_city[0],
        }));
      }
      if (headquarter_address_postal_code) {
        setErrors((prev) => ({
          ...prev,
          headquarter_address_postal_code: headquarter_address_postal_code[0],
        }));
      }
      if (loa_file) {
        setErrors((prev) => ({
          ...prev,
          loa_file: loa_file[0],
        }));
      }
      if (corroborating_file_0) {
        setErrors((prev) => ({
          ...prev,
          corroborating_file_0: corroborating_file_0[0],
        }));
      }
      if (corroborating_file_1) {
        setErrors((prev) => ({
          ...prev,
          corroborating_file_1: corroborating_file_1[0],
        }));
      }
      if (corroborating_file_2) {
        setErrors((prev) => ({
          ...prev,
          corroborating_file_2: corroborating_file_2[0],
        }));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>

            <Helmet> {/* Add the Helmet component */}
                <title>LEI Transfer - Entity Data | LEIpapa</title>
                <meta 
                name="description" 
                content="Transfer an LEI to LEIpapa and RapidLEI. Let's locate the details of your Company/Legal Entity or Fund to Transfer your LEI."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

      {isAuth && (
        <LoadedLeiPage>
          <Form onSubmit={handleSubmit(onSubmit)} uiProps={{ width: "100%" }}>
            <div className={cl["title_wrap"]}>
              <Title>LEI Transfer - Entity data</Title>

            </div>
            {/* <Hr />
            <div className={cl["checkbox_wrap"]}>
              <SubTitle>User authority</SubTitle>
            </div>
            <div className={cl["checkbox"]}>
              <Input
                uiProps={{ margin: "15px 0px" }}
                type="checkbox"
                value={String(agreeMember)}
                placeholder="Member of board"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAgreeMember(e.target.checked)
                }
              />

              <Input
                uiProps={{ margin: "15px 0px" }}
                type="checkbox"
                value={String(agreePower)}
                placeholder="Power of attorney"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAgreePower(e.target.checked)
                }
              />
            </div> */}

            {/* <div className={cl["checkbox_wrap"]}>
              <SubTitle>User authority</SubTitle>
              <Select
                // placeholder="Legal Jurisdiction"
                variants={["Member of Board","Power of Attorney"]}
                defaultValue={"-"}
                style={cl["input_wrap"]}
                {...register("legal_jurisdiction")}
                
              />
            </div> */}
            <SubTitle>
              Let's locate Legal Entity details and the LEI code <span className={cl["tooltip"]} data-tooltip="Please start typing the officially registered Legal Entity name and choose the one you'd like to transfer.">ℹ️</span>
            </SubTitle>

            <TabName className={cl["tab_name"]}>Legal Entity</TabName>
            <div className={cl["card_body"]}>
              <div className={cl["input_wrap_4"]}>             
                <div>
                  {/* <div>{"Legal Name"}<span style={{color: 'red'}}> *<i style={{fontSize: 14}}>required</i></span></div> */}
                  <div>{"Legal Name"}<span style={{color: 'red'}}> *</span></div>
                    <div className={cl["search_frame"]}>
                      <ReactSearchAutocomplete
                        items={companyNameList}
                        fuseOptions={{ keys: ["companyName", "description"] }} // Search on both fields
                        resultStringKeyName="companyName" // String to display in the results
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        onClear={handleOnClear}
                        showIcon={true}
                        {...register("company_name")}
                        styling={{
                          height: "39px",
                          border: "1px solid #4B7196",
                          borderRadius: "0px",
                          backgroundColor: "white",
                          boxShadow: "none",
                          // fontSize: "12px",
                          // fontFamily: "Courier",
                          clearIconMargin: "3px 8px 0 0",
                          zIndex: 2,
                        }}
                      />
                  </div>
                </div>
                <div>
                  <Input
                    placeholder="Legal Jurisdiction"
                    {...register("legal_jurisdiction", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    uiProps={UIInputProps}
                    isRequired
                  />
                  {errors.legal_jurisdiction && (
                    <InputError>{errors.legal_jurisdiction}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("company_number", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="Company Number"
                    uiProps={UIInputProps}
                    isRequired
                  />
                  {errors.company_number && (
                    <InputError>{errors.company_number}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("entity_legal_form")}
                    placeholder="Entity legal form"
                    readOnly
                    disabled
                    uiProps={UIInputProps}
                  />
                  {errors.entity_legal_form && (
                    <InputError>{errors.entity_legal_form}</InputError>
                  )}
                </div>
              </div>
              
              <div className={cl["input_wrap_5"]}>
                <div>
                  <Input
                    {...register("lei_number", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="LEI Number"
                    uiProps={UIInputProps}
                    isRequired
                  />
                  {errors.lei_number && <InputError>{errors.lei_number}</InputError>}
                </div>
                <div>
                  <Input
                    {...register("lei_registration_status")}
                    placeholder="LEI status"
                    readOnly
                    disabled
                    uiProps={UIInputProps}
                  />
                  {errors.lei_registration_status && (
                    <InputError>{errors.lei_registration_status}</InputError>
                  )}
                </div>
                <div>
                <Input
                    {...register("managing_lou_lei")}
                    placeholder="LEI issuer's code"
                    readOnly
                    disabled
                    uiProps={UIInputProps}
                  />
                  {errors.managing_lou_lei && (
                    <InputError>{errors.managing_lou_lei}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("entity_status")}
                    placeholder="Entity Status"
                    readOnly
                    disabled
                    uiProps={UIInputProps}
                  />
                  {errors.entity_status && (
                    <InputError>{errors.entity_status}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("entity_type")}
                    placeholder="Entity Type"
                    readOnly
                    disabled
                    uiProps={UIInputProps}
                  />
                  {errors.entity_type && (
                    <InputError>{errors.entity_type}</InputError>
                  )}
                </div>
              </div>
              {/* <ul>
                {countries.map(country => <li>{country}</li>)}
              </ul> */}
            </div>
            <SubTitle>
              The LoA should be signed by the "Signing Authority" <span className={cl["tooltip"]} data-tooltip=" A Signing Authority means someone who is authorized to sign on behalf of a Legal Entity – usually an owner, beneficiary, director, executive, lawyer, etc.">ℹ️</span>
            </SubTitle>
            
            <TabName className={cl["tab_name"]}>Signing Authority</TabName>
            <div className={cl["card_body"]}>
              <div className={cl["input_wrap"]}>
                <div>
                  <Input
                    {...register("first_name", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="First name"
                    uiProps={UIInputProps}
                    isRequired
                  />
                  {errors.first_name && (
                    <InputError>{errors.first_name}</InputError>
                  )}
                </div>
                <div>
                  <Input
                      {...register("last_name", {
                        validation: (value) => value.length > 0,
                        errorMessage: "Required field",
                        onChange,
                      })}
                      placeholder="Last name"
                      uiProps={UIInputProps}
                      isRequired
                  />
                  {errors.last_name && (
                    <InputError>{errors.last_name}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("phone_number", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="Phone Number"
                    uiProps={UIInputProps}
                    isRequired
                  />
                  {errors.phone_number && (
                    <InputError>{errors.phone_number}</InputError>
                  )}
                </div>
                <div>
                  <Input
                      {...register("email", {
                        validation: (value) => value.length > 0,
                        errorMessage: "Required field",
                        onChange,
                      })}
                      placeholder="E-mail"
                      uiProps={UIInputProps}
                      isRequired
                  />
                  {errors.email && (
                    <InputError>{errors.email}</InputError>
                  )}
                </div>
              </div>

              <div className={cl["input_file_wrap"]}>
                <form style={{marginTop: "20px"}}>
                  <InputFile
                    type="file" 
                    placeholder="Letter of Authorization (LOA)"
                    onChange={loaFileOnChange}
                  />
                </form>
                <details style={{margin: "10px 0"}}>
                    <summary style={{ lineHeight: "1.5" }}>Do I need to complete the LoA?</summary>
                    <p className={cl["summary_box"]}>When you request an LEI, we must check your ability to sign directly on behalf of each Legal Entity or Fund you need the LEI for. If you cannot sign yourself, you must gain permission from someone who can and prove to us via a Letter of Authorization (LoA). If you have authority (let's say you are the director of the company) then you don't need to tell us, as the account name will suffice, but if you don't, please confirm who permitted you.</p>
                  </details>
                  <details style={{margin: "10px 0"}}>
                    <summary style={{ lineHeight: "1.5" }}>Download template of the LOA</summary>
                    <p className={cl["summary_box"]}>Please <a href="https://leipapa.com/docs/docs/lei-registration-process/lei-applications-requiring-a-letter-of-authorization/" target="_blank" rel="noopener noreferrer" title="Download template of the LOA and find more information about LEI applications requiring a LOA.">use this link</a> to download template of the LOA and find more information about LEI applications requiring a LOA.</p>
                  </details>
              </div>
            </div> 

            <TabName className={cl["tab_name"]}>Legal Address</TabName>
            <div className={cl["card_body"]}>
              <div className={cl["input_wrap_address_4"]}>
                <div>
                  <Input
                    {...register("legal_address_country", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="Country"
                    isRequired
                    uiProps={UIInputProps}
                  />
                  {errors.legal_address_country && (
                    <InputError>{errors.legal_address_country}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("legal_address_region", {
                      // validation: (value) => value.length > 0,
                      // errorMessage: "Required field",
                      // onChange,
                    })}
                    placeholder="Region"
                    uiProps={UIInputProps}
                  />
                  {errors.legal_address_region && (
                    <InputError>{errors.legal_address_region}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("legal_address_city", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="City"
                    isRequired
                    uiProps={UIInputProps}
                  />
                  {errors.legal_address_city && (
                    <InputError>{errors.legal_address_city}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("legal_address_postal_code", {
                      // validation: (value) => value.length > 0,
                      // errorMessage: "Required field",
                      // onChange,
                    })}
                    placeholder="Postal code"
                    uiProps={UIInputProps}
                  />
                  {errors.legal_address_postal_code && (
                    <InputError>{errors.legal_address_postal_code}</InputError>
                  )}
                </div>
              </div>
  
              <div className={cl["input_wrap_address_3"]}>
                <div>
                  <Input
                    {...register("legal_address_street", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="Street"
                    isRequired
                    uiProps={UIInputProps}
                  />
                  {errors.legal_address_street && (
                    <InputError>{errors.legal_address_street}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("legal_address_house", {
                      // validation: (value) => value.length > 0,
                      // errorMessage: "Required field",
                      // onChange,
                    })}
                    placeholder="House"
                    uiProps={UIInputProps}
                  />
                  {errors.legal_address_house && (
                    <InputError>{errors.legal_address_house}</InputError>
                  )}
                </div>

                <div>
                  <Input
                    {...register("legal_address_suite")}
                    placeholder="Suite"
                    uiProps={UIInputProps}
                  />
                  {errors.legal_address_suite && (
                    <InputError>{errors.legal_address_suite}</InputError>
                  )}
                </div>
              </div>
            </div>

            <TabName className={cl["tab_name"]}>Headquarter Address</TabName>
            <div className={cl["card_body"]}>
              <div className={cl["input_wrap_address_4"]}>
                <div>
                  <Input
                    {...register("headquarter_address_country", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="Country"
                    isRequired
                    uiProps={UIInputProps}
                  />
                  {errors.headquarter_address_country && (
                    <InputError>{errors.headquarter_address_country}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("headquarter_address_region", {
                      // validation: (value) => value.length > 0,
                      // errorMessage: "Required field",
                      // onChange,
                    })}
                    placeholder="Region"
                    uiProps={UIInputProps}
                  />
                  {errors.headquarter_address_region && (
                    <InputError>{errors.headquarter_address_region}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("headquarter_address_city", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="City"
                    isRequired
                    uiProps={UIInputProps}
                  />
                  {errors.headquarter_address_city && (
                    <InputError>{errors.headquarter_address_city}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("headquarter_address_postal_code", {
                      // validation: (value) => value.length > 0,
                      // errorMessage: "Required field",
                      // onChange,
                    })}
                    placeholder="Postal code"
                    uiProps={UIInputProps}
                  />
                  {errors.headquarter_address_postal_code && (
                    <InputError>{errors.headquarter_address_postal_code}</InputError>
                  )}
                </div>
              </div>
  
              <div className={cl["input_wrap_address_3"]}>
                <div>
                  <Input
                    {...register("headquarter_address_street", {
                      validation: (value) => value.length > 0,
                      errorMessage: "Required field",
                      onChange,
                    })}
                    placeholder="Street"
                    isRequired
                    uiProps={UIInputProps}
                  />
                  {errors.headquarter_address_street && (
                    <InputError>{errors.headquarter_address_street}</InputError>
                  )}
                </div>
                <div>
                  <Input
                    {...register("headquarter_address_house", {
                      // validation: (value) => value.length > 0,
                      // errorMessage: "Required field",
                      // onChange,
                    })}
                    placeholder="House"
                    uiProps={UIInputProps}
                  />
                  {errors.headquarter_address_house && (
                    <InputError>{errors.headquarter_address_house}</InputError>
                  )}
                </div>

                <div>
                  <Input
                    {...register("headquarter_address_suite")}
                    placeholder="Suite"
                    uiProps={UIInputProps}
                  />
                  {errors.headquarter_address_suite && (
                    <InputError>{errors.headquarter_address_suite}</InputError>
                  )}
                </div>
              </div>
            </div>

            <TabName className={cl["tab_name"]}>Duration and Parent Relationship</TabName>
            <div className={cl["card_body"]}>
              <div className={cl["input_wrap_3"]}>
                <div>
                  <label htmlFor="multiyearsupport">Years to Support</label>
                    <select id="multiyearsupport" className={cl["custom-select"]}
                    {...register("multi_year_support", {
                      validation: (value) => value !== "0",
                      errorMessage: "required field",
                      onChange,
                    })}
                    required
                    >
                    <option value="0" disabled>
                      Years to Support
                    </option>
                    {["1", "2", "3", "4", "5"].map((variant) => (
                      <option key={variant} value={variant}>
                        {variant}
                      </option>
                    ))}
                    </select>
                    {/* <Select
                      variants={["1","2","3","4","5"]}
                      defaultValue={"-"}
                      placeholder="Years to Support"
                      {...register("multi_year_support", {
                        validation: (value) => value.length > 0,
                        errorMessage: "required field",
                        onChange,
                      })}
                      isRequired
                    /> */}
                  {errors.multi_year_support && (
                    <InputError>{errors.multi_year_support}</InputError>
                  )}
                </div>
                <div>
                  <Select
                    variants={["true", "false"]}
                    defaultValue={"-"}
                    uiProps={UIInputProps}
                    placeholder="is_level_2_data_available"
                    {...register("is_level_2_data_available")}
                  />
                  {errors.is_level_2_data_available && (
                    <InputError>{errors.is_level_2_data_available}</InputError>
                  )}
                </div>
                <div>
                  <Select
                    variants={["NATURAL_PERSONS","NON_CONSOLIDATING","NO_KNOWN_PERSON","NON_PUBLIC"]}
                    defaultValue={"-"}
                    uiProps={UIInputProps}
                    {...register("reporting_exception_reason")}
                    placeholder="Reporting Exception Reason"
                  />
                  {errors.reporting_exception_reason && (
                    <InputError>{errors.reporting_exception_reason}</InputError>
                  )}
                </div>
              </div>

              <div className={cl["input_file_wrap"]}>
                <form>
                  <InputFile
                    type="file"
                    placeholder="Corroborating Document 1"
                    onChange={corrFileOnChange_0}
                  />
                </form>
              </div>
              <div className={cl["input_file_wrap"]}>
                <form>
                  <InputFile
                    placeholder="Corroborating Document 2"
                    type="file" 
                    onChange={corrFileOnChange_1}
                  />
                </form>
              </div>
              <div className={cl["input_file_wrap"]}>
                <form>
                  <InputFile
                    placeholder="Corroborating Document 3"
                    type="file" 
                    onChange={corrFileOnChange_2} 
                  />
                </form>
              </div>
            </div>

            <Hr />

            <div className={cl["title_inner-button"]}>
                <NavLink to="/" title="Back to LEIpapa Home Page">
                  <button className={cl["button_cancel"]}>
                    <CancelIcon />
                    <span className={cl["button_text"]}>Cancel</span>
                  </button>
                </NavLink>

                <button className={cl["button_next"]} type="submit">
                  <NextIcon />
                  <span className={cl["button_text"]}>Next</span>
                </button>
              </div>

          </Form>
        </LoadedLeiPage>
      )}
    </Container>
  );
};
