export const countries = [
  { value: "AF", label: "Afghanistan (AF)", price: 59, surcharge: 0 },
  { value: "AI", label: "Anguilla (AI)", price: 59, surcharge: 60 },
  { value: "AR", label: "Argentina (AR)", price: 59, surcharge: 0 },
  { value: "AW", label: "Aruba (AW)", price: 59, surcharge: 0 },
  { value: "AU", label: "Australia (AU)", price: 59, surcharge: 5 },
  { value: "AT", label: "Austria (AT)", price: 59, surcharge: 0 },
  { value: "BS", label: "Bahamas (BS)", price: 59, surcharge: 10 },
  { value: "BH", label: "Bahrain (BH)", price: 59, surcharge: 0 },
  { value: "BD", label: "Bangladesh (BD)", price: 59, surcharge: 0 },
  { value: "BB", label: "Barbados (BB)", price: 59, surcharge: 0 },
  { value: "BY", label: "Belarus (BY)", price: 59, surcharge: 0 },
  { value: "BE", label: "Belgium (BE)", price: 59, surcharge: 0 },
  { value: "BZ", label: "Belize (BZ)", price: 59, surcharge: 0 },
  { value: "BM", label: "Bermuda (BM)", price: 59, surcharge: 0 },
  { value: "BO", label: "Bolivia (BO)", price: 59, surcharge: 0 },
  { value: "BQ", label: "Bonaire, Sint Eustatius and Saba (BQ)", price: 59, surcharge: 15 },
  { value: "BA", label: "Bosnia and Herzegovina (BA)", price: 59, surcharge: 0 },
  { value: "BW", label: "Botswana (BW)", price: 59, surcharge: 0 },
  { value: "BR", label: "Brazil (BR)", price: 59, surcharge: 0 },
  { value: "VG", label: "British Virgin Islands (VG)", price: 59, surcharge: 50 },
  { value: "BG", label: "Bulgaria (BG)", price: 59, surcharge: 0 },
  { value: "KH", label: "Cambodia (KH)", price: 59, surcharge: 0 },
  { value: "CM", label: "Cameroon (CM)", price: 59, surcharge: 335 },
  { value: "CA", label: "Canada (CA)", price: 59, surcharge: 5 },
  { value: "KY", label: "Cayman Islands (KY)", price: 59, surcharge: 35 },
  { value: "CL", label: "Chile (CL)", price: 59, surcharge: 0 },
  { value: "CN", label: "China (CN)", price: 59, surcharge: 0 },
  { value: "CO", label: "Colombia (CO)", price: 59, surcharge: 0 },
  { value: "KM", label: "Comoros (KM)", price: 59, surcharge: 0 },
  { value: "CK", label: "Cook Islands (CK)", price: 59, surcharge: 0 },
  { value: "CR", label: "Costa Rica (CR)", price: 59, surcharge: 0 },
  { value: "CI", label: "Côte d’Ivoire (Ivory Coast) (CI)", price: 59, surcharge: 335 },
  { value: "HR", label: "Croatia (HR)", price: 59, surcharge: 0 },
  { value: "CW", label: "Curaçao (CW)", price: 59, surcharge: 0 },
  { value: "CY", label: "Cyprus (CY)", price: 59, surcharge: 0 },
  { value: "CZ", label: "Czechia (CZ)", price: 59, surcharge: 0 },
  { value: "CD", label: "Democratic Republic of the Congo (CD)", price: 59, surcharge: 0 },
  { value: "DK", label: "Denmark (DK)", price: 59, surcharge: 0 },
  { value: "DM", label: "Dominica (DM)", price: 59, surcharge: 0 },
  { value: "DO", label: "Dominican Republic (DO)", price: 59, surcharge: 0 },
  { value: "EC", label: "Ecuador (EC)", price: 59, surcharge: 0 },
  { value: "EG", label: "Egypt (EG)", price: 59, surcharge: 215 },
  { value: "SV", label: "El Salvador (SV)", price: 59, surcharge: 0 },
  { value: "EE", label: "Estonia (EE)", price: 59, surcharge: 0 },
  { value: "FO", label: "Faroe Islands (FO)", price: 59, surcharge: 0 },
  { value: "FI", label: "Finland (FI)", price: 59, surcharge: 5 },
  { value: "FR", label: "France (FR)", price: 59, surcharge: 0 },
  { value: "GA", label: "Gabon (GA)", price: 59, surcharge: 720 },
  { value: "DE", label: "Germany (DE)", price: 59, surcharge: 0 },
  { value: "GI", label: "Gibraltar (GI)", price: 59, surcharge: 25 },
  { value: "GR", label: "Greece (GR)", price: 59, surcharge: 0 },
  { value: "GL", label: "Greenland (GL)", price: 59, surcharge: 0 },
  { value: "GT", label: "Guatemala (GT)", price: 59, surcharge: 0 },
  { value: "GG", label: "Guernsey (GG)", price: 59, surcharge: 10 },
  { value: "HN", label: "Honduras (HN)", price: 59, surcharge: 0 },
  { value: "HK", label: "Hong Kong (HK)", price: 59, surcharge: 0 },
  { value: "HU", label: "Hungary (HU)", price: 59, surcharge: 0 },
  { value: "IS", label: "Iceland (IS)", price: 59, surcharge: 0 },
  { value: "IN", label: "India (IN)", price: 59, surcharge: 0 },
  { value: "ID", label: "Indonesia (ID)", price: 59, surcharge: 0 },
  { value: "IE", label: "Ireland (IE)", price: 59, surcharge: 4 },
  { value: "IM", label: "Isle of Man (IM)", price: 59, surcharge: 0 },
  { value: "IL", label: "Israel (IL)", price: 59, surcharge: 5 },
  { value: "IT", label: "Italy (IT)", price: 59, surcharge: 5 },
  { value: "JM", label: "Jamaica (JM)", price: 59, surcharge: 0 },
  { value: "JP", label: "Japan (JP)", price: 59, surcharge: 0 },
  { value: "JE", label: "Jersey (JE)", price: 59, surcharge: 10 },
  { value: "JO", label: "Jordan (JO)", price: 59, surcharge: 0 },
  { value: "KZ", label: "Kazakhstan (KZ)", price: 59, surcharge: 0 },
  { value: "KE", label: "Kenya (KE)", price: 59, surcharge: 0 },
  { value: "LV", label: "Latvia (LV)", price: 59, surcharge: 0 },
  { value: "LB", label: "Lebanon (LB)", price: 59, surcharge: 0 },
  { value: "LS", label: "Lesotho (LS)", price: 59, surcharge: 0 },
  { value: "LI", label: "Liechtenstein (LI)", price: 59, surcharge: 10 },
  { value: "LT", label: "Lithuania (LT)", price: 59, surcharge: 0 },
  { value: "LU", label: "Luxembourg (LU)", price: 59, surcharge: 15 },
  { value: "MY", label: "Malaysia (MY)", price: 59, surcharge: 0 },
  { value: "MV", label: "Maldives (MV)", price: 59, surcharge: 0 },
  { value: "MT", label: "Malta (MT)", price: 59, surcharge: 0 },
  { value: "MH", label: "Marshall Islands (MH)", price: 59, surcharge: 0 },
  { value: "MU", label: "Mauritius (MU)", price: 59, surcharge: 0 },
  { value: "MX", label: "Mexico (MX)", price: 59, surcharge: 0 },
  { value: "MC", label: "Monaco (MC)", price: 59, surcharge: 0 },
  { value: "MA", label: "Morocco (MA)", price: 59, surcharge: 0 },
  { value: "NL", label: "Netherlands (NL)", price: 59, surcharge: 0 },
  { value: "NZ", label: "New Zealand (NZ)", price: 59, surcharge: 0 },
  { value: "NG", label: "Nigeria (NG)", price: 59, surcharge: 0 },
  { value: "NO", label: "Norway (NO)", price: 59, surcharge: 0 },
  { value: "OM", label: "Oman (OM)", price: 59, surcharge: 0 },
  { value: "PK", label: "Pakistan (PK)", price: 59, surcharge: 0 },
  { value: "PA", label: "Panama (PA)", price: 59, surcharge: 0 },
  { value: "PG", label: "Papua New Guinea (PG)", price: 59, surcharge: 0 },
  { value: "PY", label: "Paraguay (PY)", price: 59, surcharge: 0 },
  { value: "PE", label: "Peru (PE)", price: 59, surcharge: 0 },
  { value: "PH", label: "Philippines (PH)", price: 59, surcharge: 0 },
  { value: "PL", label: "Poland (PL)", price: 59, surcharge: 0 },
  { value: "PT", label: "Portugal (PT)", price: 59, surcharge: 25 },
  { value: "PR", label: "Puerto Rico (PR)", price: 59, surcharge: 0 },
  { value: "QA", label: "Qatar (QA)", price: 59, surcharge: 0 },
  { value: "CG", label: "Republic of the Congo (CG)", price: 59, surcharge: 0 },
  { value: "RO", label: "Romania (RO)", price: 59, surcharge: 10 },
  { value: "RU", label: "Russia (RU)", price: 59, surcharge: 0 },
  { value: "KN", label: "Saint Kitts and Nevis (KN)", price: 59, surcharge: 30 },
  { value: "LC", label: "Saint Lucia (LC)", price: 59, surcharge: 0 },
  { value: "VC", label: "Saint Vincent and the Grenadines (VC)", price: 59, surcharge: 60 },
  { value: "SM", label: "San Marino (SM)", price: 59, surcharge: 10 },
  { value: "SA", label: "Saudi Arabia (SA)", price: 59, surcharge: 0 },
  { value: "SN", label: "Senegal (SN)", price: 59, surcharge: 0 },
  { value: "RS", label: "Serbia (RS)", price: 59, surcharge: 0 },
  { value: "SC", label: "Seychelles (SC)", price: 59, surcharge: 25 },
  { value: "SG", label: "Singapore (SG)", price: 59, surcharge: 0 },
  { value: "SX", label: "Sint Maarten (SX)", price: 59, surcharge: 0 },
  { value: "SK", label: "Slovakia (SK)", price: 59, surcharge: 0 },
  { value: "SI", label: "Slovenia (SI)", price: 59, surcharge: 0 },
  { value: "ZA", label: "South Africa (ZA)", price: 59, surcharge: 0 },
  { value: "KR", label: "South Korea (KR)", price: 59, surcharge: 0 },
  { value: "ES", label: "Spain (ES)", price: 59, surcharge: 8 },
  { value: "LK", label: "Sri Lanka (LK)", price: 59, surcharge: 0 },
  { value: "SR", label: "Suriname (SR)", price: 59, surcharge: 0 },
  { value: "SE", label: "Sweden (SE)", price: 59, surcharge: 0 },
  { value: "CH", label: "Switzerland (CH)", price: 59, surcharge: 0 },
  { value: "TW", label: "Taiwan (TW)", price: 59, surcharge: 0 },
  { value: "TZ", label: "Tanzania (TZ)", price: 59, surcharge: 0 },
  { value: "TH", label: "Thailand (TH)", price: 59, surcharge: 0 },
  { value: "TT", label: "Trinidad and Tobago (TT)", price: 59, surcharge: 0 },
  { value: "TN", label: "Tunisia (TN)", price: 59, surcharge: 0 },
  { value: "TR", label: "Turkey (TR)", price: 59, surcharge: 0 },
  { value: "TC", label: "Turks & Caicos (TC)", price: 59, surcharge: 45 },
  { value: "UG", label: "Uganda (UG)", price: 59, surcharge: 0 },
  { value: "UA", label: "Ukraine (UA)", price: 59, surcharge: 0 },
  { value: "AE", label: "United Arab Emirates (AE)", price: 59, surcharge: 0 },
  { value: "GB", label: "United Kingdom (GB)", price: 59, surcharge: 0 },
  { value: "US", label: "United States (US)", price: 59, surcharge: 0 },
  { value: "UY", label: "Uruguay (UY)", price: 59, surcharge: 0 },
  { value: "UZ", label: "Uzbekistan (UZ)", price: 59, surcharge: 0 },
  { value: "VE", label: "Venezuela (VE)", price: 59, surcharge: 0 },
  { value: "VN", label: "Vietnam (VN)", price: 59, surcharge: 0 },
  { value: "ZM", label: "Zambia (ZM)", price: 59, surcharge: 0 },
  { value: "ZW", label: "Zimbabwe (ZW)", price: 59, surcharge: 0 }
  ];