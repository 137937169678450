import { useForm } from "../../../hooks/useForm";
import Input from "../../ui/ui kit/Input";
import Form from "../Form";
import regulars from "../../../constants/regulars";
import Button from "../../ui/ui kit/Button";
import cl from "./PasswordResetForm.module.sass";
import { useState } from "react";
import Loader from "../../ui/Loader";
import { useNavigate } from "react-router-dom";
import User from "../../../api/services/users";
import Modal from '../../../components/ui/ui kit/Modal';


export const PasswordResetForm = () => {
  const { register: email, handleSubmit: EmailFormSubmit } = useForm({
    preventDefault: true,
    submitValidate: true,
  })
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const [succesModal, setSuccesModal] = useState(false)
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res = await User.resetPassword(data.email)
      // navigate("/");
      setSuccesModal(true);
    } catch (error: any) {
      console.log(error.response)
      setError("Unknown error")
    }finally{
      setLoading(false);
    }
  }

  const onChange = () => {
    setError("")
  }

  const onCloseModal = () => {
    setSuccesModal(false)
    navigate("/");
  }

  return (

    <div className={cl["container"]}>
      <div className={cl["box-left"]}>

        <Form
          onSubmit={EmailFormSubmit(onSubmit)}
          uiProps={{ width: "100%", margin: "40px 0" }}
        >
          <h1 className={cl["title"]}>Password Reset</h1>
          <div className={cl["form-text"]}>
            <div>
              We will send a new password to your email.
            </div>
            <Input
              placeholder='Email'
              uiProps={{ margin: "20px 0px 0px" }}
              {...email("email", {
                validation: (value) => regulars.EMAIL.test(value),
                errorMessage:  "Incorrect Email",
                onChange
              })}
            />
          </div>
          <footer className={cl["footer"]}>
            <Button type="submit" styleType="primary" width="100%">Reset Password</Button>
          </footer>
          {loading && <Loader />}
          <Modal isOpen={succesModal} closeModal={onCloseModal}>
            <div className={cl["wrap"]}>
              <div>If your email address is registered with us, you should receive the recovery email shortly. Please check your mailbox.</div>
            </div>
          </Modal>
        </Form>
      </div>

      <div className={cl["box-right"]}>
        <p className={cl["box-right-title"]}><b>Useful Information</b></p>
        <p className={cl["box-right-text"]}>
          <p>
            For the convenience of the Clients, LEIpapa offers the option 
            to pay the renewal fee up to 5 years in advance. 
          </p>
          <br />
          <p>
            If the Client has purchased the LEI renewal service for multiple years, 
            LEIpapa will cover the renewal fee for the purchased period. 
            LEIpapa renews the data of the Legal Entity in the GLEIF database annually 
            based on the data provided in the public business registries. 
          </p>
        </p>
      </div>

    </div>
  );
};
