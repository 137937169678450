import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "../../components/ui/ui kit/Container";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import {
  RegistrationLeiPage,
  Title,
  Hr,
  TabName,
  NavLink,
} from "./style";
import "./table_style.css"
import Input from "../../components/ui/ui kit/Input";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import cl from "./RegisterLei.module.sass";
import { CreateLeiService} from "../../api/services/create-lei";
import { useDispatch } from "react-redux";
import { fetchDataForm } from "../../redux/action-creators/dataFormReducer";
// import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../../components/ui/Loader";
import { AxiosResponse } from "axios";
import { $leiApi } from "../../api";
// Import Prices
import { countries } from '../../components/functional/Prices/countriesData';
import { statesUs } from '../../components/functional/Prices/statesUsData';
import { statesCanada } from '../../components/functional/Prices/statesCanadaData';
import { calculateMultiYearPrice } from '../../components/functional/Prices/multiYearPrice';
import {
  calculateEntityTypeSurcharge,
  calculateLevel2Surcharge
} from '../../components/functional/Prices/surchargeUtils.js';
import { Helmet } from "react-helmet";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
// import 'primereact/resources/primereact.css';                       // core css


const defaultCompanyDataPresearch = [{
  companyId: "",
  companyName: "",
  companyNumber: "",
  id: 0,
  legalJurisdiction: "",
}];

const defaultCompanyData = {
  company_name: "",
  company_number: "",
  entity_type: "",
  multi_year_support: 0,
  reporting_exception_reason: "",
  is_level_2_data_available: false,
};

const NON_PUBLIC_USE_CASES = [
  "Obstacles in the laws or regulations of a jurisdiction prevent the child Legal Entity from providing or publishing this information.",
  "The child Legal Entity reports that the consent of the parent was necessary under the applicable legal framework and the parent did not consent or could not be contacted.",
  "Binding legal commitments such as the articles governing the child Legal Entity, or a contract prevent the child Legal Entity from providing or publishing this information. This is distinct from laws or regulations of a jurisdiction that prevent the child Legal Entity from providing or publishing information on its parent.",
  "The child Legal Entity has sought to consult the parent entity about the reporting of the parent information to the Global LEI System but could not confirm the absence of detriment in a way that can appropriately prevent liability risks for the child Legal Entity (or those acting on its behalf) under the applicable legal framework. The disclosure of this information would be detrimental to the child Legal Entity or the relevant parent.",
  "The disclosure of this information would be detrimental to the Legal Entity or the relevant parent. This will include reasons generally accepted by public authorities in similar circumstances, based on a declaration by the entity.",
];

export class SearchLeiCode {
  static async getLeiCompany(name: string): Promise<AxiosResponse<any>> {
    return $leiApi.get(`lei-records?filter[entity.names]=${encodeURIComponent(name)}`);
  }
}

export const RegistrationLei = () => {
  const isAuth = useAuth();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  //const [countries, setCountries] = useState<string[]>([]);

  const [selectedCountry, setSelectedCountry] = useState('');
  
  // States
  const [selectedState, setSelectedState] = useState('');
  const [showStates, setShowStates] = useState(false);
  const [stateOptions, setStateOptions] = useState([] as any[]); // Set initial state as an empty array
  // Company data stored in Session
  const [companyName, setCompanyName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [entityType, setEntityType] = useState("GENERAL");
  const [multiYearSupport, setMultiYearSupport] = useState(1);
  const [surchargePrice, setSurchargePrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [isLevel2DataAvailable, setIsLevel2DataAvailable] = useState<boolean>(false);

  const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState(defaultCompanyData);
  const [companyNameList, setCompanyNameList] = useState(defaultCompanyDataPresearch);
  const [reportingExceptionReason, setReportingExceptionReason] = useState("");
  const navigate = useNavigate();
  const [/*companyStatus*/, setCompanyStatus] = React.useState("");

  const [Q1, setQ1] = React.useState("");
  const [Q2, setQ2] = React.useState("");
  const [Q3, setQ3] = React.useState("");
  const [Q4, setQ4] = React.useState("");
  const [npUseCaseConfirmed, setNpUseCaseConfirmed] = React.useState(false);

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  let multiYearPrice = calculateMultiYearPrice(multiYearSupport);
  let entityTypeSurcharge = calculateEntityTypeSurcharge(entityType);
  let level2Surcharge = calculateLevel2Surcharge(isLevel2DataAvailable);

  const [manualCompanyName, setManualCompanyName] = useState('');
  const [manualCompanyNumber, setManualCompanyNumber] = useState('');
  const [showManualEntry, setShowManualEntry] = useState(false);


  const handlePresearch = () => {
    const company_data = {
      "company_name": companyName,
      "country_code": countryCode,
    }
  
    if (companyName === "" && countryCode === "") {
      alert("Please enter a Company Name or a Company Number, and choose the Country. If you require an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else if (companyName !== "" && countryCode === "") {
      alert("Please choose the Country. If you require an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else if (
      companyName !== "" &&
      (countryCode === "US" || countryCode === "CA") &&
      selectedState === ""
    ) {
      alert("Please choose the State as well. If you require an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else if (companyName === "" && countryCode !== "") {
      alert("Please enter a Company Name. If you require an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else {
      setIsSearchClicked(true);
      requestCompanyNameList(company_data);
    }
  };

  //*********** States *************
  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    setCountryCode(selectedCountry);

    if (selectedCountry === 'US') {
      setShowStates(true);
      setStateOptions(statesUs);
      setSelectedState('');
    } else if (selectedCountry === 'CA') {
      setShowStates(true);
      setStateOptions(statesCanada);
      setSelectedState('');
    } else {
      setShowStates(false);
      setSelectedState('');
    }
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = event.target.value;
    // setSelectedCountry(selectedCountry);
    setSelectedState(selectedState);
    setCountryCode(selectedState); 
  };

  //*********** States End *************
  
  const requestCountries = useCallback(async () => {
    try {
      // const res = await CreateLeiService.requestCountries();
      // setCountries(
      //   Object.keys(res.data.data).map((el) => el + " - " + res.data.data[el])
      // );
      await CreateLeiService.requestCountries();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const requestCompanyNameList = useCallback(async (
    companyDataPresearch: {
      company_name: string,
      country_code: string
    }
  ) => {
    try {
      setCompanyNameList([]);
      setCompanyData(defaultCompanyData);
      setLoading(true);
      const res = await CreateLeiService.preSearch(companyDataPresearch);
      setCompanyNameList(res.data.data);
      // console.log(res.data.data);

    } catch (error) {
      console.log('-> ERROR in requestCompanyNameList:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  //Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleOnQ1Change = (q1Value: any) => {
    if (Q1 !== q1Value) {
      setQ1(q1Value);
      setQ2("");
      setQ3("");
      setQ4("");
      setIsLevel2DataAvailable(false);
      setReportingExceptionReason(q1Value === "yes" ? "NATURAL_PERSONS" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const handleOnQ2Change = (q2Value: any) => {
    if (Q2 !== q2Value) {
      setQ2(q2Value);
      setQ3("");
      setQ4("");
      setIsLevel2DataAvailable(false);
      setReportingExceptionReason(q2Value === "no" ? "NO_KNOWN_PERSON" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const handleOnQ3Change = (q3Value: any) => {
    if (Q3 !== q3Value) {
      setQ3(q3Value);
      setQ4("");
      setIsLevel2DataAvailable(false);
      setReportingExceptionReason(q3Value === "no" ? "NON_CONSOLIDATING" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const handleOnQ4Change = (q4Value: any) => {
    if (Q4 !== q4Value) {
      setQ4(q4Value);
      setIsLevel2DataAvailable(q4Value === "yes");
      console.log("q4Value:", q4Value, q4Value === "yes");
      setReportingExceptionReason(q4Value === "no" ? "NON_PUBLIC" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const l2Message = useMemo(() => {
    if (reportingExceptionReason === "" && isLevel2DataAvailable) {
      return "You need to provide Level 2 Data for this entity. Don't worry, once you have placed an order, we will contact you to clarify the details of the ownership structure. The fee (€30) for Level 2 Data processing will be added to your order.";
    } else if (reportingExceptionReason === "NON_PUBLIC") {
      return "Both your Direct and Ultimate Parent Information will have NON_PUBLIC as the exception reason. Use this NON_PUBLIC exception reason ONLY if one of the following use cases applies to the Legal Entity!";
    } else {
      return "";
    }
  }, [isLevel2DataAvailable, reportingExceptionReason]);

  useEffect(() => {
    requestCountries();
  }, [requestCountries]);

  useEffect(() => {
    let updatedPrice: number
    let updatedSurchargePrice: number

    if (selectedCountry === "CA" || selectedCountry === "US") {
      const selectedState = stateOptions.find(state => state.value === countryCode);
      updatedPrice = selectedState
        ? multiYearSupport * (selectedState.surcharge + multiYearPrice + entityTypeSurcharge + level2Surcharge)
        : 0;
      updatedSurchargePrice = selectedState ? multiYearSupport * selectedState.surcharge: 0;
      setSurchargePrice(updatedSurchargePrice);
      setPrice(updatedPrice);
    } else {
      const selectedCountry = countries.find(country => country.value === countryCode);
      updatedPrice = selectedCountry
        ? multiYearSupport * (selectedCountry.surcharge + multiYearPrice + entityTypeSurcharge + level2Surcharge)
        : 0;
      updatedSurchargePrice = selectedCountry
        ? multiYearSupport * selectedCountry.surcharge
        : 0;
      setSurchargePrice(updatedSurchargePrice);
      setPrice(updatedPrice);
    }

    console.log('---> price:', updatedPrice);

    setCompanyData(companyData => ({
      ...companyData,
      multi_year_support: multiYearSupport,
      reporting_exception_reason: reportingExceptionReason,
      entity_type: entityType,
      price: updatedPrice,
      multi_year_price: multiYearPrice,
      entity_type_surcharge: entityTypeSurcharge,
      level2_surcharge: level2Surcharge,
      surcharge_price: updatedSurchargePrice,
      is_level_2_data_available: isLevel2DataAvailable,
    }));
  }, [
    countryCode, entityType, entityTypeSurcharge, isLevel2DataAvailable, level2Surcharge,
    multiYearPrice, multiYearSupport, reportingExceptionReason,
    selectedCountry, stateOptions,
  ]);

  useEffect(() => {
    if (
      "company_name" in companyData
      && "company_number" in companyData
      && "entity_type" in companyData
      && "multi_year_support" in companyData
      && ("reporting_exception_reason" in companyData || isLevel2DataAvailable)
    ) {
      if (
        companyData["company_name"]
        && companyData["company_number"] !== "-"
        && companyData["entity_type"] !== "-"
        && companyData["multi_year_support"]
        && (companyData["reporting_exception_reason"] || isLevel2DataAvailable)
        && (companyData["reporting_exception_reason"] !== "NON_PUBLIC" || npUseCaseConfirmed)
      ) {
        setNextButtonEnabled(true);
        console.log("true");
      } else {
        setNextButtonEnabled(false);
      }
    } else {
      setNextButtonEnabled(false);
    }
    console.log("companyData", companyData)
  }, [companyData, isLevel2DataAvailable, npUseCaseConfirmed]);

  const checkCompany = async (row: any) => {
    const [resGleif, resAPI] = await Promise.all([
      SearchLeiCode.getLeiCompany(row["companyName"]),
      CreateLeiService.orderSubmitStatus({
        order_type: 'Registration',
        company_name: row['companyName'],
        company_number: row['companyNumber'],
        legal_jurisdiction: countryCode
      })
    ])

    const submitStatus = resAPI.data.data['status'];
    if (resGleif.data.data.length > 0 || submitStatus !== 'NO_ACTIVE_ORDERS') {
      const existingCompanyName = row["companyName"];
      const alertMessage = resGleif.data.data.length > 0 || submitStatus === 'ALREADY_REGISTERED'
        ? `LEI already exists for company '${existingCompanyName}', please select another company.`
        : `There are active orders for company '${existingCompanyName}', please select another company.`;;      
      alert(alertMessage);
      setCompanyStatus(alertMessage);
      const data = {
        company_name: "",
        company_number: "-",
        entity_type: entityType,
        multi_year_support: multiYearSupport,
        reporting_exception_reason: reportingExceptionReason,
        is_level_2_data_available: Boolean(companyData["is_level_2_data_available"]),
      };
      setCompanyData(data);
    } else {
      const data = {
        company_name: row["companyName"],
        company_number: row["companyNumber"],
        entity_type: entityType,
        multi_year_support: companyData["multi_year_support"],
        reporting_exception_reason: companyData["reporting_exception_reason"],
        is_level_2_data_available: Boolean(companyData["is_level_2_data_available"]),
      };
      setCompanyData(data);
      setCompanyNameList([{
        ...row,
        id: row.id + "s",
      }]);
      setCompanyStatus("");
    }
  };

  const selectCompany = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
    let isRowSelected = false;
    if (companyData && companyData['company_name'] && companyData['company_number']) {
      isRowSelected = companyData['company_name'] === row['companyName'] &&
        companyData['company_number'] === row['companyNumber'];
    }

    return (
      <div>
        <button 
          className={`${cl["button_select"]} ${isRowSelected ? cl['button_select_selected'] : ''}`}
          onClick={() => {
            if (!isRowSelected) {
              checkCompany(row);
            }
          }}
        >
          {isRowSelected ? 'Selected' : 'Select'}
        </button>
      </div>
    );
  };

  const columns = [
    {
      dataField: "companyName",
      text: "Legal Name",
      headerAlign: "center",
    },
    {
      dataField: "companyNumber",
      text: "Company Number",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "legalJurisdiction",
      text: "Country", 
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "selectCompany",
      text: "",
      formatter: selectCompany,
    }
  ]

  const handleNext = async () => {
    const data = companyData;
    setLoading(true);
    const preparedData = {
      ...data,
      legal_jurisdiction: countryCode,
      order_type: 'Registration'
    };
    console.log("data", preparedData);
    await dispatch(fetchDataForm(preparedData))

    navigate('/relationship', 
    {
      // state: {
      //   companyName: companyData["company_name"],
      //   companyNumber: companyData["company_number"],
      //   entityType: entityType,
      //   countryCode: countryCode,
      //   multiYearPrice: multiYearPrice,
      //   multiYearSupport: companyData["multi_year_support"],
      //   surchargePrice: surchargePrice,
      //   entityTypeSurcharge: entityTypeSurcharge,
      //   level2Surcharge: level2Surcharge,
      //   price: price,
      // },
    });
  };

  const isCompanySelected = useMemo(() => {
    return !loading && isSearchClicked && companyData && companyData.company_name;
  }, [companyData, isSearchClicked, loading]);

  return (
    <Container>
        <Helmet> {/* don't forget: import { Helmet } from "react-helmet"; */}
        <title>LEI Registration – Entity data</title>
        <meta 
          name="description" 
          content="LEI Registration Process – Entity data. LEIpapa is an official LEI Registration Agent of Ubisecure RapidLEI. Press Create LEI Application to Register, Renew or Transfer an LEI." 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <RegistrationLeiPage>
          <div className={cl["container_big"]}>
            <div className={cl["title_wrap"]}>
              <Title>LEI Registration – Entity data</Title>
            </div>

            <TabName className={cl["tab_name"]}>
              First, let's locate the details of your Company, Legal Entity or Fund <span className={cl["tooltip"]} data-tooltip="Please enter the officially registered Legal Entity name for your company (or the company number), the jurisdiction of incorporation, and the Entity type.">ℹ️</span>
            </TabName>
            <div className={cl["card_body"]}>
              <div className={cl["input_wrap"]}>
                <div>
                  <Input
                    placeholder="Registered Company Name or Number"
                    uiProps={{width: "100%", margin: "0px"}}
                    {...{
                      onChange:(data) => 
                      setCompanyName(data.target.value),
                    }}
                    isRequired
                  />
                </div>
                <div>
                  <label htmlFor="country">Country<span style={{color: 'red'}}> *</span></label>
                  <select id="country" className={cl["custom-select"]}
                    placeholder="Country"
                    defaultValue=""
                    onChange={handleCountryChange}
                  >
                    <option disabled value="">
                      Choose Country
                    </option>
                    {countries.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>

                  {showStates && (
                    <select required id="state" className={cl["custom-select"]}
                      placeholder="State"
                      value={selectedState}
                      onChange={handleStateChange}
                    >
                      <option disabled value="">
                        Select State
                      </option>
                      {stateOptions.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div>
                  <label htmlFor="entitytype">Entity Type <span className={cl["tooltip"]} data-tooltip="You need to click the Search button each time you change the value of this Entity Type field. ">ℹ️</span></label>
                  <select id="entitytype" className={cl["custom-select"]}
                    value={entityType}
                    onChange={(event) => setEntityType(event.target.value)}
                  >
                    {["GENERAL", "SOLE_PROPRIETOR", "FUND", "WILL_PENSION", "TRUST", "OTHER"].map((variant) => (
                      <option key={variant} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </select>

                </div>
                <button className={cl["button_search"]} onClick={handlePresearch}>
                  <span>Search</span>
                </button>
              </div>

              {isSearchClicked &&
                (
                  <>
                    <div className={cl["table_wrap"]}>
                      {loading ? <Loader /> : ""}
                      {companyNameList.length > 0 && (
                        <>
                          <DataTable value={companyNameList}>
                            {columns.map((col, colIndex) => (
                                <Column 
                                    key={col.dataField}
                                    field={col.dataField}
                                    header={col.text} 
                                    alignHeader={colIndex === 0 ? "left" : "center"}
                                    body={(rowData, { rowIndex }) => {
                                        return col.formatter 
                                            ? col.formatter(rowData[col.dataField], rowData, rowIndex, "") 
                                            : rowData[col.dataField];
                                    }}
                                    style={{ padding: '10px', textAlign: col.align as 'left' | 'center' | 'center' | 'center' }}
                                />
                            ))}
                          </DataTable>
                        </>
                      )}
                      {!isCompanySelected && !loading && (
                        <div className={cl["px-25"]}>
                          <p style={{ marginTop: "1.5rem", lineHeight: "1.5", textAlign: "justify" }}>The search results are provided for the {countryCode} jurisdiction.</p>
                          <p style={{ marginTop: "1.5rem", marginBottom: "1.5rem", lineHeight: "1.5", textAlign: "justify" }}>Not the results you expected? If your Company Name consists of more than one word, try writing the full Company Name for a more accurate query.</p>
                        </div>
                      )}
                      {!loading && companyNameList.length === 0 && (
                        <div>
                          <div className={cl["px-25"]}>
                            <p style={{ fontWeight: "bold", lineHeight: "1.5", textAlign: "justify" }}>No companies that meet the search criteria.</p>
                            <p style={{ marginTop: "1.5rem", lineHeight: "1.5", textAlign: "justify" }}>You can manually enter the details to proceed:</p>
                          </div>
                          <div className={cl["input_wrap_manual"]}>
                            <Input
                              type="text"
                              placeholder="Company Name"
                              value={manualCompanyName}
                              onChange={e => setManualCompanyName(e.target.value)}
                              isRequired
                            />
                            <Input
                              type="text"
                              placeholder="Company Number"
                              value={manualCompanyNumber}
                              onChange={e => setManualCompanyNumber(e.target.value)}
                              isRequired
                            />
                            <button 
                            className={`${cl["button_select_manual"]} ${isCompanySelected ? cl['button_select_manual_selected'] : ''}`}
                            onClick={() => {
                              if (manualCompanyName && manualCompanyNumber) {
                                setCompanyData({
                                  ...companyData,
                                  company_name: manualCompanyName,
                                  company_number: manualCompanyNumber
                                });
                                setShowManualEntry(false);
                              } else {
                                alert('Please fill in both fields to proceed.');
                              }
                            }}>
                              {isCompanySelected ? 'Selected' : 'Select'}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* {!isCompanySelected && !loading && (
                      <div className={cl["wrap_l2"]}>
                        <p style={{ lineHeight: "1.5" }}>The search results are provided for the {countryCode} jurisdiction.</p><br />
                        <p style={{ lineHeight: "1.5" }}>Not the results you expected? If your Company Name consists of more than one word, try writing the full Company Name for a more accurate query.
                        If the Company still does not appear, click <a href="https://tally.so/r/n0eX9A" title="Register an LEI manually">HERE</a> to manually fill in the data.</p>
                      </div>
                    )} */}

                    {isCompanySelected && (
                      <>
                        <div className={cl["input_wrap_years"]}>
                          <div>
                            <label htmlFor="multiyearsupport">Year to support <span className={cl["tooltip"]} data-tooltip="Specify for how many years you wish to register the LEI code.">ℹ️</span></label>
                            <select id="multiyearsupport" className={cl["custom-select"]}
                              value={multiYearSupport}
                              onChange={(event) => setMultiYearSupport(Number(event.target.value))}
                              
                            >
                              <option value="0" disabled>
                                Years to Support
                              </option>
                              {["1", "2", "3", "4", "5"].map((variant) => (
                                <option key={variant} value={variant}>
                                  {variant}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
    
                        <div className={cl["wrap_l2"]}>
                          <div className={cl["Q"]}>
                            <p>{"Is the Legal Entity controlled by only Private Individuals (Natural Persons)?"}</p>
                            <button className={(Q1 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ1Change("yes")}>Yes</button>
                            <button className={(Q1 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ1Change("no")}>No</button>  
                          </div>
                          {Q1 === "no" && (
                            <div className={cl["Q"]}>
                              <p>{"Is the Legal Entity owned by another legal entity? (Controlling share, typically > 50% shareholding)"}</p>
                              <button className={(Q2 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ2Change("yes")}>Yes</button>
                              <button className={(Q2 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ2Change("no")}>No</button>  
                            </div>
                          )}
                          {(Q2 === "yes") && (
                            <div className={cl["Q"]}>
                              <p>{"Does the Legal Entity consolidate financial statements into the controlling legal entity?"}</p>
                              <button className={(Q3 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ3Change("yes")}>Yes</button>
                              <button className={(Q3 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ3Change("no")}>No</button>  
                            </div>
                          )}
                          {(Q3 === "yes") && (
                            <div className={cl["Q"]}>
                              <p>
                                Are you able to make the Parental information for either your&nbsp;
                                <a
                                  href="https://www.gleif.org/en/lei-data/access-and-use-lei-data/level-2-data-who-owns-whom"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Direct or Ultimate Parent
                                </a>&nbsp;Public?&nbsp;
                                <span
                                  className={cl["tooltip"]}
                                  data-tooltip="Any company where your accounts are directly consolidated is known as your Direct Parent. A higher-level company that consolidates the accounts for the group would be an Ultimate Parent. (Note that they can be the same legal entity)"
                                >
                                  ℹ️
                                </span> 
                              </p>
                              <button className={(Q4 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ4Change("yes")}>Yes</button>
                              <button className={(Q4 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ4Change("no")}>No</button>  
                            </div>
                          )}
                          {l2Message && <div className={cl["l2_message"]}>{l2Message}</div>}
                          {reportingExceptionReason === "NON_PUBLIC" && (
                            <div className={cl["use-case-checkbox-wrapper"]}>
                              <input
                                id={`use-case-confirmed`}
                                type="checkbox"
                                name="useCaseConfirmed"
                                checked={npUseCaseConfirmed}
                                onChange={(e) => {
                                  setNpUseCaseConfirmed(e.target.checked);
                                }}
                              />
                              <label htmlFor={`use-case-confirmed`}>
                                <p><span style={{fontWeight: 'bold'}}>I confirm that at least one of the below cases is applicable to the Legal Entity I am applying for, and that is the reason for choosing the NON_PUBLIC exception reason.</span></p>
                                {/* Non Public: For all of the below use cases: */}
                              </label>
                            </div>
                          )}
                          {reportingExceptionReason === "NON_PUBLIC" && (
                            <ul className={cl["use-cases-wrapper"]}>
                              {NON_PUBLIC_USE_CASES.map((item, index) => (
                                <li key={`use-case-${index}`}>
                                  { item }
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
    
                        <Hr />
                        <div className={cl["wrap_l2_right"]}>
                          <div className={cl["price_text"]}>Order calculation</div>
                          <p>Years to support: {multiYearSupport}</p>
                          <p>Base price: €{multiYearPrice} x {multiYearSupport} = {multiYearPrice * multiYearSupport}</p>
                          {surchargePrice > 0 && (
                            <p>Jurisdictional surcharge: €{surchargePrice/multiYearSupport} x {multiYearSupport} = {surchargePrice}</p>
                          )}
                          {entityTypeSurcharge > 0 && (
                            <p>Entity type surcharge: €{entityTypeSurcharge} x {multiYearSupport} = {entityTypeSurcharge * multiYearSupport}</p>
                          )}
                          {level2Surcharge > 0 && (
                            <p>Level 2 surcharge: €{level2Surcharge} x {multiYearSupport} = {level2Surcharge * multiYearSupport}</p>
                          )}
                          <p>Total: €{price}</p>
                          {/* <p>Total: €{multiYearPrice * multiYearSupport + surchargePrice + entityTypeSurcharge + level2Surcharge}</p> */}
                          {/* <span className={cl["price_text"]}>Level 2 surcharge: €{level2Surcharge}</span> */}
                        </div>
                      </>
                    )}
                  </>
                )
              }
            </div>
            <Hr/>
            <div className={cl["title_inner-button"]}>
              <NavLink to="/">
                <button className={cl["button_cancel"]}>
                  <CancelIcon />
                  <span className={cl["button_text"]}>Cancel</span>
                </button>
              </NavLink>

              <button className={cl["button_next"]} onClick={handleNext} disabled={!nextButtonEnabled}>
                <NextIcon />
                <span className={cl["button_text"]}>Next</span>
              </button>
            </div>
          </div>
        </RegistrationLeiPage>
      )}
    </Container>
  );
};
