import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import Container from "../../components/ui/ui kit/Container";
import { useAuth } from "../../hooks/useAuth";
import cl from "./RegistrationSubmit.module.sass";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useForm } from "../../hooks/useForm";
import { ICreateLeiCode } from "../../types/registerLeiCode";
import Form from "../../components/functional/Form";
import Input from "../../components/ui/ui kit/Input";
import InputFile from "../../components/ui/ui kit/InputFile";
import { useNavigate } from "react-router-dom";
import { fetchDataForm } from "../../redux/action-creators/dataFormReducer";
import Loader from "../../components/ui/Loader";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import {
  RegistrationSubmitLeiPage,
  Title,
  NavLink,
  Hr,
  //SubTitle,
} from "./style";
import {CreateLeiService} from "../../api/services/create-lei";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { isValidEmail } from "../../utils/validators";
import { isValidPhoneNumber } from "libphonenumber-js";

// const UIInputProps = {
//   width: "500px",
//   margin: "10px 0px",
// };

// interface OrderParameters {
//   companyName: string,
//   companyNumber: string,
//   entityType: string,
//   countryCode: string, 
//   multiYearPrice: number, 
//   multiYearSupport: number, 
//   surchargePrice: number, 
//   entityTypeSurcharge: number, 
//   level2Surcharge: number,
//   price: number,
// };

const supportedFileTypes = ["PDF", "DOC", "DOCX", "XLS", "XLSX", "JPG", "JPEG", "PNG", "GIF", "BMP", "TIF", "TIFF", "TXT"];

export const RegistrationSubmit = () => {
  const isAuth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataDataForm /*, loadingDataForm, errorDataForm*/ } = useTypedSelector(
    (state) => state.dataForm
  );
  
  let price = 0;
  let orderType = "";
  let companyName = "";
  let companyNumber = "";
  let leiNumber = "";
  let countryCode = "";
  let entityType = "GENERAL";
  let multiYearSupport = 1;
  let multiYearPrice = 0;
  let entityTypeSurcharge = 0;
  let level2Surcharge = 0;
  let surchargePrice = 0;
  let reportingExceptionReason = "";
  let isLevel2DataAvailable = false;
  let isLevel1DataSame = false;
  
  if (dataDataForm) {
    orderType = dataDataForm["order_type"];
    price = dataDataForm["price"];
    companyName = dataDataForm["company_name"];
    companyNumber = dataDataForm["company_number"];
    leiNumber = dataDataForm["lei_number"];
    entityType = dataDataForm["entity_type"];
    countryCode = dataDataForm["legal_jurisdiction"];
    multiYearSupport = dataDataForm["multi_year_support"];
    multiYearPrice = dataDataForm["multi_year_price"];
    entityTypeSurcharge = dataDataForm["entity_type_surcharge"];
    level2Surcharge = dataDataForm["level2_surcharge"];
    surchargePrice = dataDataForm["surcharge_price"];
    reportingExceptionReason = dataDataForm["reporting_exception_reason"];
    isLevel2DataAvailable = dataDataForm["is_level_2_data_available"] || false;
    isLevel1DataSame = orderType === "Renew" && dataDataForm['is_level_1_data_same'];
  }

  const [agree0, setAgree0] = useState<boolean>(false);
  const [agree1, setAgree1] = useState<boolean>(false);
  const [agree2, setAgree2] = useState<boolean>(false);
  const [agree3, setAgree3] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loaFile, setLoaFile] = useState("");
  const [eoaFiles, setEoaFiles] = useState(["", "", "", "", ""]);
  const [eoaVisibility, setEoaVisibility] = useState([true, false, false, false, false]);
  // const [corroborating_file_1, setCorroboratingFile1] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { handleSubmit /*, register, resetForm*/ } = useForm<ICreateLeiCode>({
    preventDefault: true,
    submitValidate: true,
    defaultState: dataDataForm,
  });

  useEffect(() => {
    if (dataDataForm && orderType === "Renew") {
      setFirstName(dataDataForm["sa_first_name"]);
      setLastName(dataDataForm["sa_last_name"]);
      setEmail(dataDataForm["sa_email"] || '');
      setPhone(dataDataForm["sa_phone"] || '');
    }
  }, [dataDataForm, orderType]);

  const loaFileOnChange = (e:any) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const arr = file.name.split(".");
      const extension = arr[arr.length - 1].toUpperCase();

      if (!supportedFileTypes.includes(extension)) {
        alert("Unsupported file type. Allowed file types are PDF, DOC, DOCX, XLS, XLSX, JPG, JPEG, PNG, GIF, BMP, TIF, TIFF or TXT.");
        setLoaFile("");
        e.target.value = null;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const data = reader.result?.toString() || '';

        if (data.length > 5 * 1024 * 1024) {
          alert("File size is too large. Please select a smaller file");
          setLoaFile("");
          e.target.value = null;
          return
        }

        if (data && (data === loaFile || eoaFiles.includes(data))) {
          alert("The file was already selected. Please chose another file.");
          setLoaFile("");
          e.target.value = null;
          return
        }

        setLoaFile(data);
      };

      reader.onerror = () => {
        console.log('Error in reading a file:', file.name);
        setLoaFile("");
        e.target.value = null;
      }
    } else {
      setLoaFile("");
    }
  };

  const eoaFileOnChange = (e: any, index: number) => {
    // console.log('-> eoaFileOnChange:', e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const arr = file.name.split(".");
      const extension = arr[arr.length - 1].toUpperCase();

      if (!supportedFileTypes.includes(extension)) {
        alert("Unsupported file type. Allowed file types are PDF, DOC, DOCX, XLS, XLSX, JPG, JPEG, PNG, GIF, BMP, TIF, TIFF or TXT.");
        eoaFiles[index] = "";
        setEoaFiles(eoaFiles);

        e.target.value = null;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const data = reader.result?.toString() || '';
        console.log('-> data length:', data.length, file);
        if (data.length > 5 * 1024 * 1024) {
          alert("File size is too large. Please select a smaller file");
          eoaFiles[index] = "";
          setEoaFiles(eoaFiles);

          e.target.value = null;
          return
        }

        if (data && (data === loaFile || eoaFiles.includes(data))) {
          alert("The file was already selected. Please chose another file.");
          eoaFiles[index] = "";
          setEoaFiles(eoaFiles);

          e.target.value = null;
          return
        }

        eoaFiles[index] = data;
        setEoaFiles(eoaFiles);
  
        if (data) {
          if (data && index < 4 && !eoaVisibility[index + 1]) {
            eoaVisibility[index + 1] = true;
            setEoaVisibility([...eoaVisibility]);
          }
        } else {
          e.target.value = null;
        }
      };

      reader.onerror = () => {
        console.log('Error in reading a file:', file.name);
        eoaFiles[index] = "";
        setEoaFiles(eoaFiles);
        e.target.value = null;
      }
    } else {
      eoaFiles[index] = "";
      setEoaFiles(eoaFiles);
    }
  };

  const onSubmit = async (data: ICreateLeiCode) => {
    try {
      setLoading(true);

      const originalFirstName = (dataDataForm["sa_first_name"] || "").trim();
      const originalLastName = (dataDataForm["sa_last_name"] || "").trim();

      const preparedData = {
        ...dataDataForm,
        loa_file: loaFile,
        corroborating_file_0: eoaFiles[0],
        corroborating_file_1: eoaFiles[1],
        corroborating_file_2: eoaFiles[2],
        corroborating_file_3: eoaFiles[3],
        corroborating_file_4: eoaFiles[4],
        sa_first_name: firstName.trim(),
        sa_last_name: lastName.trim(),
        is_level_2_data_available: isLevel2DataAvailable,
        sa_email: email.trim(),
        sa_phone: phone.trim(),
        sa_changed: originalFirstName !== firstName.trim() || originalLastName !== lastName.trim()
      };
      console.log("data", preparedData);
      await dispatch(fetchDataForm(preparedData));

      if (orderType === "Registration" || orderType === "Renew") {
        const res = await CreateLeiService.createPaymentLink(preparedData);
  
        window.location.href = res.data.payment_link;
      } else if (orderType === "Transfer" || orderType === "RA-to-RA Transfer") {
        navigate("/transfer-request");
      }

    } catch (e: any) {
      // const {} = e.response.data;
      
    } finally {
      setLoading(false);
    }
  };

  // const location = useLocation();
  // const { 
  //   companyName,
  //   companyNumber,
  //   entityType,
  //   countryCode, 
  //   multiYearPrice, 
  //   multiYearSupport, 
  //   surchargePrice, 
  //   entityTypeSurcharge, 
  //   level2Surcharge,
  //   price,
  // } = location.state as OrderParameters;
  //const { companyName, companyCountry } = location.state as State;

  //Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const isEmailValid = useMemo(() => {
    const netEmail = (email || '').trim();
    if (!netEmail) {
      return true;
    }

    return isValidEmail(netEmail);
  }, [email]);

  const isPhoneValid = useMemo(() => {
    const netPhone = (phone || "").trim();
    if (!netPhone) {
      return true;
    }

    return isValidPhoneNumber(netPhone, 'US');
  }, [phone]);
  
  return (
    <Container>
      <Helmet> {/* don't forget: import { Helmet } from "react-helmet"; */}
        <title>LEI {orderType} – Submit Data</title>
        <meta 
          name="description" 
          content={`LEI ${orderType} Process - Submit Data. LEIpapa is an official LEI Registration Agent of Ubisecure RapidLEI. Press Create LEI Application to Register, Renew or Transfer an LEI.`}
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <RegistrationSubmitLeiPage>
          <Form onSubmit={handleSubmit(onSubmit)} uiProps={{ width: "100%" }}>
            <div className={cl["title_wrap"]}>
              <Title>LEI {orderType} – Submit</Title>
            </div>

            {loading && (<div className={cl["inner__items"]}>
              {<Loader />}
            </div>)}
            
            <div className={cl["card_body"]}>
              {(orderType !== "Renew" || !isLevel1DataSame) && (
                <>
                  <div className={cl["sa_wrap"]}>
                    <p>Please provide the <span className={cl["tooltip"]} data-tooltip="A Signing Authority is someone who is authorized to sign on behalf of a Legal Entity – usually an owner, beneficiary, director, executive, lawyer, etc. When you request an LEI, we must check your ability to sign directly on behalf of each Legal Entity or Fund you need the LEI for.">Signing Authority</span>
                      &nbsp;name for {companyName}. 
                      If you have authority then just specify your First Name and Last Name, and the account name will suffice, but if you don't, please enter the name of the person who gave you permission and confirm by uploading the Letter of Authorization (LoA) below.
                    </p>
                  </div>
                  <div className={cl["input_wrap"]}>
                    <Input
                      {...{
                        onChange: (data) => 
                        setFirstName(data.target.value),
                      }}
                      value={firstName}
                      placeholder="First Name"
                      uiProps={{width: "100%", margin: "0px"}}
                      isRequired
                    />
                    <Input
                      {...{
                        onChange: (data) => 
                        setLastName(data.target.value),
                      }}
                      value={lastName}
                      placeholder="Last Name"
                      uiProps={{width: "100%", margin: "0px"}}
                      isRequired
                    />
                    <Input
                      {...{
                        onChange: (data) => 
                        setEmail(data.target.value),
                      }}
                      value={email}
                      placeholder="Company Email"
                      uiProps={{width: "100%", margin: "0px"}}
                      isError={!isEmailValid}
                      errorMessage="Invalid email"
                    />
                    <Input
                      {...{
                        onChange: (data) => 
                        setPhone(data.target.value),
                      }}
                      value={phone}
                      placeholder="Company Phone (+ country code)"
                      uiProps={{width: "100%", margin: "0px"}}
                      isError={!isPhoneValid}
                      errorMessage="Invalid phone number"
                    />
                  </div>
                </>
              )}
              <div className={cl["input_file_wrap"]}>
                <div>
                  <form>
                    <InputFile
                      placeholder={
                        (orderType === "Transfer" || orderType === "RA-to-RA Transfer")
                        ? "Letter of Authorization (LoA) *"
                        : "Letter of Authorization (LoA)"
                    }
                      type="file" 
                      onChange={loaFileOnChange}
                      accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif,.bmp,.tif,.tiff,.txt"
                      isRequired={orderType === "Transfer" || orderType === "RA-to-RA Transfer"}
                    />
                  </form>
                </div>
                <div>
                  {
                    (orderType === "Transfer")
                    ? 
                    <p style={{ textAlign: 'justify', lineHeight: '1.5', fontSize: '0.8em' }}>
                    According to regulations, you should provide the official transfer request. Authorized signatories from the legal entity shall sign the Letter of Authorization (LoA) via legally compliant formats in the respective jurisdiction.
                    You can <a style={{ fontSize: '1em' }} href="https://leipapa.com/loa/Letter-of-Authorisation-template-RA-external-transfers.docx" title="Download Transfer Request (LoA)" target="_blank" rel="noreferrer">download the template</a> right away, sign it, and upload it here.
                    </p>
                    : 
                    (orderType === "RA-to-RA Transfer") 
                    ?
                    <p style={{ textAlign: 'justify', lineHeight: '1.5', fontSize: '0.8em' }}>
                    According to regulations, you should provide the official transfer request. Authorized signatories from the legal entity shall sign the Letter of Authorization (LoA) via legally compliant formats in the respective jurisdiction.
                    You can <a style={{ fontSize: '1em' }} href="https://leipapa.com/loa/Letter-of-Authorisation-template-RA-Internal-transfers.docx" title="Download Transfer Request (LoA)" target="_blank" rel="noreferrer">download the template</a> right away, sign it, and upload it here.
                    </p>
                    :
                    <p style={{ textAlign: 'justify', lineHeight: '1.5', fontSize: '0.8em' }}>
                    Letter of Authorization (LoA) is used to give you permission from a Signing Authority. Simply put, LoA is a letter from the Company director granting you permission to act on behalf of the Company. 
                    If you have authority then you don't need to upload the LoA, as the account name will suffice, but if you don't please confirm who gave you permission by uploading the LoA.
                    If you don't have an LoA to upload now, don't worry; you can send us LoA by emailing us after placing the order. 
                    You can <a style={{ fontSize: '1em' }} href="https://leipapa.com/docs/lei-applications-requiring-a-letter-of-authorization/" title="Download Letter of Authorization (LoA)" target="_blank" rel="noreferrer">download the LoA template</a> right away.
                  </p>
                  }
                  <p style={{ textAlign: 'justify', lineHeight: '1.5', fontSize: '0.8em', marginTop: '0.8em' }}>
                    Allowed file types: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff, .txt. <br />
                    Max file size: 800 Kb.                 
                  </p>
                </div>  
              </div>
              <div className={cl["input_file_wrap"]}>
                <div>
                  <form>
                    {eoaVisibility.map((visible, index) => (
                      <div className={visible ? "" : cl["input_file_container_invisible"]} key={`${index}`}>
                        <InputFile
                          type="file"
                          placeholder={index === 0 ? "Evidence of Authority (EoA)" : undefined}
                          onChange={e => eoaFileOnChange(e, index)}
                          accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif,.bmp,.tif,.tiff,.txt"
                        />
                      </div>
                    ))}
                  </form>
                </div>
                <div>
                  <p style={{ textAlign: 'justify', lineHeight: '1.5', fontSize: '0.8em' }}>
                    Evidence of Authority (EoA) is generally used for Entity Supplied Only LEIs such as Funds/Trusts/Wills/Pensions and usually takes the form of a copy of the deeds. However, in some cases even for a Legal Entity, the Business Registry does not publicly highlight the name of a signing authority, so we would need a copy of the Articles of Incorporation or a copy of the Memorandum of Articles to highlight who is able to sign. You can upload up to 5 files.
                  </p>
                  <p style={{ textAlign: 'justify', lineHeight: '1.5', fontSize: '0.8em', marginTop: '0.8em' }}>
                    Allowed file types: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff, .txt. <br />
                    Max file size: 800 Kb.                 
                  </p>
                </div>
              </div>
            </div>

            <div className={cl["submit-wrap"]}>
              <div className={cl["submit_title"]}>
                I HEREBY (All checkboxes are mandatory)
              </div>
              <div className={cl["submit_description"]}>
                <div className={cl["checkboxes"]}>
                  <label className={cl["label"]} htmlFor="checkbox0">
                    <Input
                      id="checkbox0"
                      type="checkbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree0(e.target.checked)}
                      isRequired
                    />
                    <span>
                      Confirm and guarantee the accuracy and veracity of all the above stated data and accept LEIpapa's <a href="https://leipapa.com/pages/terms/" title="Terms of Service" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://leipapa.com/pages/privacy-policy/" title="Privacy Policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> which I have read.
                    </span>
                  </label>

                  <label className={cl["label"]} htmlFor="checkbox1">
                    <Input
                      id="checkbox1"
                      type="checkbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree1(e.target.checked)}
                      isRequired
                    />
                    <span>
                      Confirm that the Legal Entity I represent with the above stated data has not submitted LEI porting and LEI renewal request in other LOU;
                    </span>
                  </label>

                  <label className={cl["label"]} htmlFor="checkbox2">
                    <Input
                      id="checkbox2"
                      type="checkbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree2(e.target.checked)}
                      isRequired
                    />
                    <span>
                      Confirm that I represent and warrant that the LEI applicant is not designated on an economic sanctions or restricted party list maintained by the United Nations Security Council, the European Union or any member state, the United Kingdom, or the U.S. Government;
                    </span>
                  </label>

                  <label className={cl["label"]} htmlFor="checkbox3">
                    <Input
                      id="checkbox3"
                      type="checkbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree3(e.target.checked)}
                      isRequired
                    />
                    <span>
                      Confirm that no Restricted Party or Restricted Parties owns, individually or in the aggregate, 25% or more of its outstanding share capital.
                    </span>
                  </label>
                </div>
                <div className={cl["submit_subtitle"]}>
                  Information regarding processing of personal data
                </div>
                <div className={cl["submit_text"]}>
                  The personal data provided in the Application will be subject to processing 
                  by LEIpapa OÜ ("LEIpapa"). <br />
                  LEIpapa may collect and use personal data about the individual named herein for the purposes of 
                  issuing LEI by its LOA, Ubisecure Oy ("RapidLEI"). Any personal data that is collected will be used solely for the 
                  issuing and administration of LEI and will not be used for any other reason. <br />
                  LEIpapa may utilize third parties for the purpose of collecting relevant Personal 
                  Data; all such third parties will be subject to appropriate non-disclosure 
                  obligations and have in place appropriate security for such personal data. 
                  Any personal data that is collected will be retained for the duration of the 
                  contractual relationship between LEIpapa and your company and for a period of 
                  five (5) years in accordance with our records retention requirements following 
                  the termination of such contractual relationship. 
                </div>
                <Hr />
                <div className={cl["wrap_l2_right"]}>
                  <div className={cl["price_text"]}>Review Your Order</div><br />
                    <p>Order Type: {orderType}</p>
                    <p>Legal Entity: {companyName}, {companyNumber}, {countryCode}</p>
                    {leiNumber && (
                      <p>LEI Number: {leiNumber}</p>
                    )}
                    <p>Entity Type: {entityType}</p>
                    <p>Reporting Exception Reason: {reportingExceptionReason}</p>
                    <p>Years to support: {multiYearSupport}</p>
                    <p>Base price: €{multiYearPrice} x {multiYearSupport} = {multiYearPrice * multiYearSupport}</p>
                    {surchargePrice > 0 && (
                      <p>Jurisdictional surcharge: €{surchargePrice/multiYearSupport} x {multiYearSupport} = {surchargePrice}</p>
                    )}
                    {entityTypeSurcharge > 0 && (
                      <p>Entity type surcharge: €{entityTypeSurcharge} x {multiYearSupport} = {entityTypeSurcharge * multiYearSupport}</p>
                    )}
                    {level2Surcharge > 0 && (
                      <p>Level 2 surcharge: €{level2Surcharge} x {multiYearSupport} = {level2Surcharge * multiYearSupport}</p>
                    )}
                    <p className={cl["price_text_total"]}>Total: €{price}</p><br />
                    <p className={cl["price_text_total"]}>Please note: </p>
                    <p style={{ marginTop: '0.5em' }}>All prices exclude VAT/GST. 
                      Applicability will be determined during the payment processing, 
                      where you will have the ability to enter and verify your VAT number. 
                      Any appropriate VAT amount will be shown on the final invoice.</p>
                    <p style={{ marginTop: '0.5em' }}>We reserve the right to ask you to confirm the Legal Entity Reference Data (LE-RD).</p>
                    <p style={{ marginTop: '0.5em' }}>Please refer to the <a href="https://leipapa.com/pages/terms/" title="Terms of Service" target="_blank" rel="noopener noreferrer">Terms of Service</a> for the full terms and conditions applied. Your continued use of the LEIpapa website and the LEI application submission constitutes your acknowledgment and consent to the Terms of Service. If you have any questions or concerns about the Terms of Service, please <a href="https://leipapa.com/contact/" title="Contact LEIpapa" target="_blank" rel="noopener noreferrer">Contact Us</a>.</p>
                </div>
              </div>
            </div>

            <Hr />
            <div className={cl["title_inner-button"]}>
              <NavLink
                to={
                  orderType === "Registration"
                    ? "/create"
                    : (orderType === "Transfer" || orderType === "RA-to-RA Transfer")
                      ? "/transfer"
                      : "/renew"
                }
              >
                <button className={cl["button_back"]}>
                  <NextIcon className={cl["button_next-rotate"]} />
                  <span className={cl["button_text"]}>Back</span>
                </button>
              </NavLink>
              <NavLink to="/">
                <button className={cl["button_cancel"]}>
                  <CancelIcon />
                  <span className={cl["button_text"]}>Cancel</span>
                </button>
              </NavLink>

              <button
                className={cl["button_next"]}
                type="submit"
                disabled={
                  !agree0 || !agree1 || !agree2 || !agree3 || !firstName || !lastName || loading ||
                  !isEmailValid || !isPhoneValid || 
                  ((orderType === "Transfer" || orderType === "RA-to-RA Transfer") && !loaFile)
                }
              >
                {loading ? <LoadingIcon /> : <NextIcon />}
                <span className={cl["button_text"]}>
                  {orderType === "Transfer" || orderType === "RA-to-RA Transfer"
                    ? "Go to Transfer Request Form"
                    : "Submit and go to Checkout"
                  }
                </span>
              </button>
            </div>
          </Form>
        </RegistrationSubmitLeiPage>
      )}
    </Container>
  );
};
