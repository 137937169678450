import styled from "styled-components";

export const LoupeButton = styled.button`
    width: 3em;
    height: 3em;
    background-color: #4B7196;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    transition: all 0.3s;
    margin-left: 0.5em;
    display: flex;             // Added flexbox
    justify-content: center;  // Center horizontally
    align-items: center;      // Center vertically
    &:hover {
        background-color: #2B3956;
    }
    @media (max-width: 900px) {
        margin-left: 0em;
        margin-top: 0.5em;
        width: 100%;
    }
`;


export const Hr = styled.hr`
      margin-top: 30px;
      opacity: .4;
      @media (max-width: 1100px) {
        display: none;
    }
    `
