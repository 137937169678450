import { useForm } from "../../../hooks/useForm"
import Input from "../../ui/ui kit/Input"
import Form from "../Form"
import regulars from "../../../constants/regulars"
import Button from "../../ui/ui kit/Button"
import cl from "./RegistrationForm.module.sass"
import { IRegistration } from "../../../types/user"
import User from "../../../api/services/users"
import { MouseEvent, useState } from "react"
import Loader from "../../ui/Loader"
import { useAuth } from "../../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import InputError from "../../ui/ui kit/InputError"


//margin between fields
const UIInputProps = {
    margin: "20px 0px 0px"
}

const defaultErrors = {
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    re_password: ""
}

const TermsAndConditionsLink = () => {
    return (
      <p>Accept <a href="//leipapa.com/pages/terms" title="LEIpapa Terms & Conditions" target="_blank" rel="noopener noreferrer">
        Terms & Conditions
      </a></p>
    );
  };

export const RegistrationForm = () => {
    const { setAuth } = useAuth()

    const { register, handleSubmit, resetForm } = useForm<IRegistration>({
        preventDefault: true,
        submitValidate: true
    })
    const [errors, setErrors] = useState<IRegistration>(defaultErrors)
    const [agree, setAgree] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    const onChange = (name: string) => {
        setErrors(prev => ({...prev, [name]: ""}))
    }
    

    const onSubmit = async (data: IRegistration) => {
        try {
            setLoading(true)
            const user = await User.register(data)
            if(user.status === 201){
                resetForm()
                const token = await User.login({email: data.email, password: data.password})

                localStorage.setItem("token", token.data.auth_token)
                setAuth(true)
                navigate("/")
            }
        }catch(e:any) {
            const { email, first_name, last_name, password, re_password  } = e.response.data
            if(email){
                setErrors(prev => ({...prev, email: email[0]}))
            }
            if(first_name){
                setErrors(prev => ({...prev, first_name: first_name[0]}))
            }
            if(last_name){
                setErrors(prev => ({...prev, last_name: last_name[0]}))
            }
            if(password){
                setErrors(prev => ({...prev, password: password[0]}))
            }
            if(re_password){
                setErrors(prev => ({...prev, re_password: re_password[0]}))
            }
        }
        finally{
            setLoading(false)
        }
        
    }

    const onClickLoginWithGoogle = async (event: MouseEvent<HTMLAnchorElement>) => {
        const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    
        const scope = [
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/userinfo.profile",
        ].join(" ");
    
        // TODO: Надо что-то с этим придумать, код дублируется
        const params = new URLSearchParams({
          response_type: "code",
          client_id: "720644816731-fm4p4ej3jovrq4fddnpqirl6vbp2tg4p.apps.googleusercontent.com",
          redirect_uri: "https://app.leipapa.com/google",
          prompt: "select_account",
          access_type: "offline",
          scope,
        });
    
        window.location.href = `${googleAuthUrl}?${params}`;
    }

    return (
        //form internal values
        <div className={cl["container"]}>
        <div className={cl["box-left"]}>
        <Form onSubmit={handleSubmit(onSubmit)} uiProps={{width: "100%", margin: "40px 0"}}>
            
            <h1 className={cl["title"]}>Create an Account</h1>
            <h2 className={cl["subtitle"]}>To apply for, renew, or transfer an LEI</h2>            
            
            <div className={cl["form-text"]}>
            <Input 
                {...register("email", {
                    
                    errorMessage: "Incorrect Email",
                    onChange
                })}
                placeholder="Email"
                uiProps={UIInputProps}
            />
            {errors.email && <InputError>{errors.email}</InputError>}
            </div>

            <div className={cl["form-text"]}>
            <Input 
                {...register("first_name", {
                    validation: (value) => value.length > 0,
                    errorMessage: "Please, specify your First name",
                    onChange
                })}                
                placeholder="First name"
                uiProps={UIInputProps}
            />
            {errors.first_name && <InputError>{errors.first_name}</InputError>}
            </div>

            <div className={cl["form-text"]}>
            <Input 
                {...register("last_name", {
                    validation: (value) => value.length > 0,
                    errorMessage: "Please, specify your Last name",
                    onChange
                })}                
                placeholder="Last name"
                uiProps={UIInputProps}
            />
            {errors.last_name && <InputError>{errors.last_name}</InputError>}
            </div>

            <div className={cl["form-text"]}>
            <Input 
                {...register("password", {
                    validation: (value) => value.length >= 8,
                    errorMessage: "Password should be more than 8 symbols",
                    onChange
                })}                
                placeholder="Password"
                uiProps={UIInputProps}
                type="password"
            />
            {errors.password && <InputError>{errors.password}</InputError>}
            </div>

            <div className={cl["form-text"]}>
            <Input 
                {...register("re_password", {
                    validation: (value, form:any) => value === form.password,
                    errorMessage: "Passwords do not match",
                    onChange
                })}                
                placeholder="Repeat password"
                uiProps={UIInputProps}
                type="password"
            />
            {errors.re_password && <InputError>{errors.re_password}</InputError>}
            </div>
            
            <div className={cl["form-text-accept"]}>
                <div style={{ display: "flex" }}>
                    <Input 
                        uiProps={{margin: "5px 0 0 0"}}
                        type="checkbox"    
                        value={String(agree)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree(e.target.checked)}
                    />
                    <label>
                        {agree ? "Accepted" : <TermsAndConditionsLink />}
                    </label>
                </div>
            </div>

            <footer className={cl["footer"]}>
                <Button type="submit" styleType="primary" width="100%" disabled={loading || !agree} >
                   {loading ? <Loader /> : "Register"} 
                </Button>
                <div className={cl["pm-awesome-divider"]} data-label="or"></div>
                <a onClick={onClickLoginWithGoogle} className={cl["google-button"]}>
                    <div className={cl["google-logo"]}>
                        <img src="https://assets.getpostman.com/common-share/google-logo-icon-sign-in.svg" width="38px" height="38px"/>
                    </div>
                    <div className={cl["google-text"]}>Register with Google</div>
                </a>
                <p className={cl["footer-text"]}>Already have an account? <a href="/authorization" title="Log In">Log In</a></p>
                <p className={cl["footer-text-agree"]}>By creating an account you agree to our <a href="//leipapa.com/pages/terms/" title="LEIpapa Terms & Conditions" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="//leipapa.com/pages/privacy-policy" title="LEIpapa Privacy Policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
            </footer>
        </Form>
        </div>
        <div className={cl["box-right"]}>
            <p className={cl["box-right-title"]}>All legal entities participating in the financial markets are obliged to have an LEI code starting from January 3, 2018.</p>
            <p className={cl["box-right-text"]}><a href="//leipapa.com/docs/who-needs-an-lei-number/" title="Who Needs an LEI Number?" target="_blank" rel="noopener noreferrer">Learn More</a></p>
        </div>
        </div>
    )   
}