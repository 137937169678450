import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import Container from "../../components/ui/ui kit/Container";
import { useAuth } from "../../hooks/useAuth";
import cl from "./TransferRequest.module.sass";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useForm } from "../../hooks/useForm";
import { ICreateLeiCode } from "../../types/registerLeiCode";
import Form from "../../components/functional/Form";
import Input from "../../components/ui/ui kit/Input";
// import { useNavigate, useLocation } from "react-router-dom";
import { fetchDataForm } from "../../redux/action-creators/dataFormReducer";
import Loader from "../../components/ui/Loader";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { isValidPhoneNumber } from "libphonenumber-js";

import {
  TransferRequestPage,
  Title,
  NavLink,
  Hr,
  TabName,
  //SubTitle,
} from "./style";
import {CreateLeiService} from "../../api/services/create-lei";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { SearchLeiCode } from "../../api/services/search-lei";
import { isValidEmail } from "../../utils/validators";

export const TransferRequest = () => {
  const isAuth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { dataDataForm } = useTypedSelector(
    (state) => state.dataForm
  );

  let leiNumber = "";
  let companyStatus = "";
  let managingLou = "";
  
  if (dataDataForm) {
    leiNumber = dataDataForm["lei_number"];
    companyStatus = dataDataForm["reg_status"];
    managingLou = dataDataForm["managing_lou"];
  }

  const [agree0, setAgree0] = useState<boolean>(false);
  const [agree1, setAgree1] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [louName, setLouName] = useState("");

  const { handleSubmit /*, register, resetForm*/ } = useForm<ICreateLeiCode>({
    preventDefault: true,
    submitValidate: true,
    defaultState: dataDataForm,
  });

  useEffect(() => {
    const getManagingLou = async (lei: string) => {
      try {
        const response = await SearchLeiCode.getManagingLou(lei);

        if (response.data) {
          // Access the legal name
          const LouName = response.data.data.attributes.entity.legalName.name;

          // Update the LouName state
          setLouName(LouName);
        } else {
          console.log("No data for this LEI's managing Lou.");
          setLouName("No data");
        }
      } catch (error) {
        console.error("Error fetching managing Lou:", error);
      }
    };

    // Call the async function
    if (leiNumber) {
      getManagingLou(leiNumber);
    }
  }, [leiNumber]); // Re-run this effect whenever ctx changes

  const onSubmit = async (data: ICreateLeiCode) => {
    try {
      setLoading(true);
      const preparedData = {
        ...dataDataForm,
        applicant_first_name: firstName,
        applicant_last_name: lastName,
        applicant_email: email,
        applicant_phone: phone,
      };
      console.log("data", preparedData);
      await dispatch(fetchDataForm(preparedData))

      const res = await CreateLeiService.createPaymentLink(preparedData);

      window.location.href = res.data.payment_link;

    } catch (e: any) {
      // const {} = e.response.data;
      
    } finally {
      setLoading(false);
    }
  };

  //Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const isEmailValid = useMemo(() => {
    const netEmail = (email || '').trim();
    if (!netEmail) {
      return true;
    }

    return isValidEmail(netEmail);
  }, [email]);

  const isPhoneValid = useMemo(() => {
    const netPhone = (phone || "").trim();
    if (!netPhone) {
      return true;
    }

    return isValidPhoneNumber(netPhone, 'US');
  }, [phone]);
  
  return (
    <Container>
      <Helmet> {/* don't forget: import { Helmet } from "react-helmet"; */}
        <title>LEI Transfer – Submit Data</title>
        <meta
          name="description" 
          content="LEI Transfer Process - Submit Data. LEIpapa is an official LEI Registration Agent of Ubisecure RapidLEI. Press Create LEI Application to Register, Renew or Transfer an LEI." 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <TransferRequestPage>
          <Form onSubmit={handleSubmit(onSubmit)} uiProps={{ width: "100%" }}>
            <div className={cl["title_wrap"]}>
              <Title>Transfer Request Form</Title>
            </div>

            <TabName className={cl["tab_name"]}>
              Please complete this form so that we may submit a request to transfer the LEI from the current managing LEI Issuer/LOU (Local Operating Unit).
            </TabName>

            {loading && (<div className={cl["inner__items"]}>
              {<Loader />}
            </div>)}
            
            <div className={cl["card_body"]}>
              <div className={cl["section_title"]}>
                Company Details
              </div>
              <div className={cl["details_wrap"]}>
                <p>LEI Number: {leiNumber}</p>
                <p>Company Status: {companyStatus}</p>
                <p>Current Managing LOU: {louName}{louName ? ", " : ""}{managingLou}</p>
              </div>

              <div className={cl["section_title"]}>
                Details of the person making the transfer request
              </div>
              <div className={cl["input_wrap"]}>
                <Input
                  {...{
                    onChange: (data) => 
                    setFirstName(data.target.value),
                  }}
                  placeholder="First Name"
                  uiProps={{width: "100%", margin: "0px"}}
                  isRequired
                />
                <Input
                  {...{
                    onChange: (data) => 
                    setLastName(data.target.value),
                  }}
                  placeholder="Last Name"
                  uiProps={{width: "100%", margin: "0px"}}
                  isRequired
                />
                <Input
                  {...{
                    onChange: (data) => 
                    setEmail(data.target.value),
                  }}
                  placeholder="Email"
                  uiProps={{width: "100%", margin: "0px"}}
                  isRequired
                  isError={!isEmailValid}
                  errorMessage="Invalid email"
                />
                <Input
                  {...{
                    onChange: (data) => 
                    setPhone(data.target.value),
                  }}
                  placeholder="Phone (+ country code)"
                  uiProps={{width: "100%", margin: "0px"}}
                  isRequired
                  isError={!isPhoneValid}
                  errorMessage="Invalid phone number"
                />
              </div>

              <div className={cl["section_title"]}>
                Receiving LEI Issuer / LOU (Local Operating Unit)
              </div>
              <div className={cl["details_wrap"]}>
                <p>LEI Number: 529900T8BM49AURSDO55</p>
                <p>Name: Ubisecure Oy</p>
              </div>

              <div className={cl["section_title"]}>
                Receiving LEI Registration Agent
              </div>
              <div className={cl["details_wrap"]}>
                <p>LEI Number: 9845005FAED41C103C69</p>
                <p>Name: LEIpapa OÜ</p>
              </div>

              <Hr />

              <div className={cl["checkboxes"]}>
                <div className={cl["checkbox_wrapper"]}>
                  <Input
                    id="checkbox0"
                    name="checkbox0"
                    type="checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree0(e.target.checked)}
                    isRequired
                  />
                  <label className={cl["label"]} htmlFor="checkbox0">
                    I accept the  <a href="https://leipapa.com/pages/terms/" title="Terms and Conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
                  </label>
                </div>

                <div className={cl["checkbox_wrapper"]}>
                  <Input
                    id="checkbox1"
                    name="checkbox1"
                    type="checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgree1(e.target.checked)}
                    isRequired
                  />
                  <label className={cl["label"]} htmlFor="checkbox1">
                    I also understand and accept, that my contact details including my contact number must be passed on to the proposed LEI Issuer/LOU (Local Operating Unit) for validation purposes as mandated by the LEI transfer protocol.
                  </label>
                </div>
              </div>
            </div>

            <div className={cl["title_inner-button"]}>
              <NavLink to="/transfer">
                <button className={cl["button_back"]}>
                  <NextIcon className={cl["button_next-rotate"]} />
                  <span className={cl["button_text"]}>Back</span>
                </button>
              </NavLink>
              <NavLink to="/">
                <button className={cl["button_cancel"]}>
                  <CancelIcon />
                  <span className={cl["button_text"]}>Cancel</span>
                </button>
              </NavLink>

              <button
                className={cl["button_next"]}
                type="submit"
                disabled={
                  !agree0 || !agree1 || !firstName || !lastName ||
                  !email || !phone || !isEmailValid || !isPhoneValid || loading
                }
              >
                {loading ? <LoadingIcon /> : <NextIcon />}
                <span className={cl["button_text"]}>Submit and go to Checkout</span>
              </button>
            </div>
          </Form>
        </TransferRequestPage>
      )}
    </Container>
  );
};
