import { ReactComponent as LeiPapaIcon } from "../../assets/icons/footer/leiPapa.svg";
// import { ReactComponent as GLEIFlogo } from "../../assets/icons/footer/gleif-logo.svg";
// import { ReactComponent as RapidLEIlogo } from "../../assets/icons/footer/rapidlei-ubisecure-logo.svg";
import { ReactComponent as LocationLogo } from "../../assets/icons/footer/location.svg";
import { ReactComponent as PhoneLogo } from "../../assets/icons/footer/phone.svg";
import { ReactComponent as EnvelopeLogo } from "../../assets/icons/footer/envelope.svg";
import { ReactComponent as BioLink } from "../../assets/icons/footer/biolink.svg";
import { ReactComponent as RSymbol } from "../../assets/icons/footer/RSymbol.svg";
import { ReactComponent as RSSlogo } from "../../assets/icons/footer/rss.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/footer/linkedin.svg";
import { ReactComponent as Instagram } from "../../assets/icons/footer/instagram.svg";
import { ReactComponent as TwitterX } from "../../assets/icons/footer/twitter-x.svg";
import { ReactComponent as TrustPilot } from "../../assets/icons/footer/trustpilot.svg";
import cl from "./footer.module.sass";
import { FooterPage, Ul, NavLink, Li, Text } from "./style";
// import { joinClasses } from "../../utils/joinClasses";

export const Footer = () => {
  
  const bottomToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <FooterPage>
      <nav>
        <Ul>

          <Li>
            
            <div className={cl["footer-head-left"]}>LEIpapa, the Official LEI Registration Agent of Ubisecure RapidLEI</div>
            
            <div className={cl["left__wrap"]}>

              <a className={cl["footer-link"]} href="https://search.gleif.org/#/record/9845005FAED41C103C69" rel="nofollow noopener noreferrer" title="LEIpapa LEI Reference Data at GLEIF" target="_blank">LEIpapa OÜ, LEI 9845005FAED41C103C69</a>
              

              <div className={cl["left__logo"]}>
              <a rel="nofollow noreferrer" href="https://rapidlei.com/partners/9845005FAED41C103C69/" target="_blank" title="RapidLEI RA Partner Seal">
                <img src="/leipapa-seal.png" width="250px" alt="LEIpapa - RapidLEI RA Partner Seal" title="LEIpapa - RapidLEI RA Partner Seal"></img>
              </a>
              </div>

            </div>
            <div style={{marginTop: "2rem"}}>
              <span className={cl["footer-head-center-second"]}>LEI Issuer</span>
            </div>

            <div className={cl["left__wrap"]}>
            <a className={cl["footer-link"]} href="https://rapidlei.com" rel="nofollow noreferrer" title="RapidLEI Home Page" target="_blank">Ubisecure RapidLEI</a>
            <a className={cl["footer-link"]} href="https://search.gleif.org/#/record/529900T8BM49AURSDO55" rel="nofollow noreferrer" title="Ubisecure RapidLEI LE Reference Data at GLEIF" target="_blank">LEI 529900T8BM49AURSDO55</a>
            </div>

            <div style={{marginTop: "2rem"}}>
              <span className={cl["footer-head-center-second"]}>The Foundation</span>
            </div>

            <div className={cl["left__wrap"]}>
            <a className={cl["footer-link"]} href="https://gleif.org" rel="nofollow noreferrer" title="GLEIF Website" target="_blank">GLEIF – Global Legal Entity Identifier Foundation</a>
            <a className={cl["footer-link"]} href="https://search.gleif.org/#/record/506700GE1G29325QX363" rel="nofollow noreferrer" title="GLEIF LE Reference Data at GLEIF" target="_blank">LEI 506700GE1G29325QX363</a>
            </div>

          </Li>

          <Li>
            <div className={cl["footer-head-center"]}>
              Legal
            </div>
            
            <div className={cl["centr__wrap"]}>
              
              <a className={cl["footer-link"]} href="//leipapa.com/pages/terms" title="LEIpapa Terms & Conditions" target="_blank">Terms & Conditions</a>
              <a className={cl["footer-link"]} href="//leipapa.com/pages/privacy-policy" title="LEIpapa Privacy Policy" target="_blank">Privacy Policy</a>
              <a className={cl["footer-link"]} href="https://leipapa.com/docs/lei-applications-requiring-a-letter-of-authorization/" title="Letters of Authorization" target="_blank">Letters of Authorization</a>
              <a className={cl["footer-link"]} href="//leipapa.com/pages/disclaimer" title="LEIpapa Disclaimer" target="_blank">Disclaimer</a>
              <a className={cl["footer-link"]} href="//leipapa.com/pages/payment-information" title="LEIpapa Payment Information" target="_blank">Payment Information</a>
            </div>
            
            <div className={cl["footer-head-center-second"]}>
              Navigation
            </div>

            <div>

              <a className={cl["footer-link"]} href="/search" title="LEI Search"><h2 className={cl["footer-link"]}>LEI Search</h2></a>

              <a className={cl["footer-link"]} href="//leipapa.com/lei-pricing/" title="LEIpapa Pricing" target="_blank">LEI Pricing</a>

              <a className={cl["footer-link"]} href="//leiprofile.com" title="LEIprofile Global Corporate Index" target="_blank">LEI Profile</a>

              <a className={cl["footer-link"]} href="https://leipapa.com/docs/" title="LEIpapa Documentation" target="_blank">Docs</a>
              
              <a className={cl["footer-link"]} href="https://leipapa.com/blog" title="LEIpapa Blog" target="_blank">Blog</a>
            </div>
          </Li>

          <Li>

            <div className={cl["footer-head-center"]}>
              Contacts
            </div>

              <div className={cl["right__wrap"]}>
                <span className={cl["social-item-link-footer"]}>📢</span>
                <a className={cl["social-item-link-footer"]} title="Contact Page" href="https://leipapa.com/contact">Contact page</a>
              </div>

              <div className={cl["right__wrap"]}>
                <span className={cl["social-item-link-footer"]}><LocationLogo /></span>
                <a className={cl["social-item-link-footer"]} title="LEIpapa Address" href="https://goo.gl/maps/fkv6mftJRDHnegdo8" rel="nofollow">Narva mnt. 13-30, Tallinn, 10151 EE</a>
              </div>
                
              <div className={cl["right__wrap"]}>
                <span className={cl["social-item-link-footer"]}><PhoneLogo /></span>
                <a className={cl["social-item-link-footer"]} title="LEIpapa Phone Number" href="callto:+3726138888">+3726138888</a>
              </div>
                
              <div className={cl["right__wrap"]}>
                <span className={cl["social-item-link-footer"]}><EnvelopeLogo /></span>
                <a className={cl["social-item-link-footer"]} title="LEIpapa Email Address" href="mailto:info@leipapa.com">info@leipapa.com</a>
              </div>

              <div className={cl["right__logo"]}>
                <LeiPapaIcon />
              </div>
              <div className={cl["right__text"]}>

                  All Rights Reserved © 2021 - {new Date().getFullYear()} 
                  <br />
                  LEIpapa is a registered TM®
                  <br />
                  LEIpapa OÜ (Reg. No. 16283000) 
                  <br />
                  Made with ❤️️ in Estonia 🇪🇪

              </div>
              <div className={cl["right__logo"]}>
              <a className={cl["status__page"]} href="https://status.leipapa.com" target="_blank"
                  rel="nofollow noopener noreferrer" title="LEIpapa Services Status Page">Service Status Page
                </a>
              </div>

              <div className={cl["right__logo"]}>
                {/* Twitter X */}
                <a className={cl["social__logo"]} href="https://twitter.com/leipapa_com" target="_blank"
                  rel="nofollow noopener noreferrer" aria-label="Twitter" title="Follow LEIpapa on X (former Twitter)"><TwitterX />
                </a>
                {/* LinkedIn */}
                <a className={cl["social__logo"]} href="https://linkedin.com/in/leipapa" target="_blank"
                  rel="nofollow noopener noreferrer" aria-label="LinkedIn" title="Follow LEIpapa on LinkedIn"><LinkedIn />
                </a>
                {/* Instagram */}
                <a className={cl["social__logo"]} href="https://instagram.com/leipapa_com" target="_blank"
                  rel="nofollow noopener noreferrer" aria-label="Instagram" title="Follow LEIpapa on Instagram"><Instagram />
                </a>
                {/* Rarible */}
                <a className={cl["social__logo"]} href="https://rarible.com/leipapa" rel="nofollow noopener noreferrer" target="_blank" title="Follow LEIpapa on Rarible"><RSymbol /></a>
                {/* Bio Link */}
                <a className={cl["social__logo"]} href="https://bio.link/leipapa" target="_blank"
                  rel="nofollow noopener noreferrer" aria-label="BioLink" title="LEIpapa on Bio Link"><BioLink />
                </a>
                {/* RSS */}
                <a className={cl["social__logo"]} href="https://leipapa.com/index.xml" title="Sign Up for LEIpapa RSS"><RSSlogo /></a>
              </div>
              {/* Trustpilot */}
              <div className={cl["right__logo"]}>
              <a className={cl["social__logo"]} href="https://www.trustpilot.com/review/leipapa.com" rel="nofollow noopener noreferrer" target="_blank" title="Follow LEIpapa on Rarible"><TrustPilot /></a>
              </div>
          </Li>

        </Ul>
      </nav>
    </FooterPage>
  );
};
