import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';
import { theme } from "../../constants/theme";

export const SearchPage = styled.div`
    max-width: 100%;
    color: ${theme.colors.input.black};
`;

export const NavLink = styled(Link)`
    color: hsla(0,0%,100%,.5);
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
        color: hsla(0,0%,100%,1);
    }
`;

export const Title = styled.h1`
    display: contents;  
    margin: 2rem auto 0;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    // line-height: 1.2;
    @media(max-width: 768px) {
        display: block;
        text-align: center;
        margin-top: 0
    }
    `

export const SubTitle = styled.p`
    margin: 10px auto 0 auto;
    // color: ${theme.colors.input.black};
    text-align: justify;
    // font-size: 1rem;
    // line-height: 1.5;
    `

export const List = styled.ol`
    margin: 20px 0 0 20px
    `

export const Li = styled.li`
    margin: 0 auto;
    color: ${theme.colors.input.black};
    text-align: left;
    font-size: 1rem;
    `
/*
export const LoupeButton = styled.button`
    width: 2em;
    height: 2em;
    background-color: ${theme.colors.blue.normal};
    cursor: pointer;
    border-radius: 5px;
    border: none;
    transition: all 0.3s;
    &:hover {
        background-color: ${theme.colors.blue.dark};
      }
  `;
  

export const Hr = styled.hr`
      margin-top: 30px;
      opacity: .4;
      @media (max-width: 1100px) {
        display: none;
    }
    `
*/