import $api from "../../index";
import { AxiosResponse } from "axios";
import { IRegistration, IAuthorization } from '../../../types/user';

class User {
    static async register(user: IRegistration): Promise<AxiosResponse> {
      return $api.post("/users/", user);
    }

    static async login(
      user: IAuthorization
    ): Promise<AxiosResponse<{ auth_token: string }>> {
      return await $api.post("/token/login/", user);
    }
    static async google_login(code: string): Promise<AxiosResponse<{ auth_token: string }>> {
      return await $api.get(`/user/auth/login/google/?code=${code}`);
    }
    static async getMe(){
      return await $api.get("/users/me/")
    }
    static async updateEmail(email: string, pass: string){
      return await $api.post("users/set_email/", {
        new_email: email,
        current_password: pass
      })
    }
    static async updateUserData(email: string, first_name: string, last_name: string, id: string){
      // return await $api.put(`users/update/${id}/`, {
      return await $api.put(`users/${id}/`, {
        email: email,
        first_name: first_name,
        last_name: last_name,
      })
    }
    static async updatePassword(new_pass: string, pass: string){
      return await $api.post("users/set_password/", {
        new_password: new_pass,
        current_password: pass
      })
    }
    static async resetPassword(email: string){
      return await $api.post("utils/reset_password/", {
        email: email,
      })
    }


    // static async logout(
    //   user: IAuthorization
    // ): Promise<AxiosResponse<{ auth_token: string }>> {
    //   return await $api.post("/token/logout/", );
    // }

}

export default User;