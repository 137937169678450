import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';
import { theme } from "../../constants/theme";

export const HomePage = styled.div`
    max-width: 100%;
    color: ${theme.colors.input.black};
`;

export const Title = styled.h1`
    max-width: 960px;
    margin: 0 auto;
    color: ${theme.colors.blue.dark};
    text-align: center;
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: bold;
    // font-family: Roboto, sans-serif;
    
    @media (max-width: 1100px) {
        max-width: 100%;
        font-size: 2rem;
    }
    @media (max-width: 600px) {
        max-width: 100%;
        font-size: 2rem;
    }
    `
export const TextLarge = styled.div`
    display:grid;
    grid-template-columns: auto 80%;
    text-align: justify;
    margin-top: 2em;
    margin-bottom: 10px;
    font-size: 1rem;
    color: ${theme.colors.input.black};
    

    @media (max-width: 1100px) {
        display:grid;
        grid-template-columns: 100%;
    }
`

export const Text = styled.p`
    display:grid;
    grid-template-columns: 25% auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 10px;
    font-size: 1rem;
    word-wrap: break-word;
`

export const NavLink = styled(Link)`
    // color: hsla(0,0%,100%,.5);
    text-decoration: none;
    transition: all 0.3s;
    // &:hover {
    //     color: hsla(0,0%,100%,1);
    // }
`;