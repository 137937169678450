import cl from "./PasswordReset.module.sass"
import { Title } from "./style"
import PasswordResetForm from "../../components/functional/PasswordResetForm"

export const PasswordReset = () => {
    return (
        <div className={cl["page"]}>
            <div>
                {/* <Title>Password Reset</Title> */}
            <PasswordResetForm />
            </div>
        </div>
    )
}