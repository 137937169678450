import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle
} from "react";
import { LoupeButton, Hr } from "./style";
import cl from "./SearchLei.module.scss";
import Input from "../../../components/ui/ui kit/input search";
import { ReactComponent as LoupeIcon } from "../../../assets/icons/search/loupe.svg";
import ReactPaginate from "react-paginate";
import { ILei } from "../../../types/lei";
import { joinClasses } from "../../../utils/joinClasses";
import { useDebounce } from "../../../hooks/debounce";
import { SearchLeiCode } from "../../../api/services/search-lei";
import { transformLei } from "../../../utils/lei";
import Loader from "../../ui/Loader";

export const SearchLei = forwardRef<
  unknown,
  {
    requestLeis: (page: number) => Promise<ILei[]>,
    isPagination: boolean,
    renderedLei: (items: ILei[]) => any[],
    titles: string[],
    enableInput: boolean,
    headClassName?: string,
  }
>(({
  requestLeis,
  isPagination,
  renderedLei,
  titles,
  enableInput,
  headClassName = ''
}, ref) => {
  const [leis, setLeis] = useState<ILei[]>([]);
  const [isLoading, setLoading] = useState(false);
  const pageCount = 499;
  const page = useRef(1);
  const [isSearched, setSearched] = useState(false);
  const [value, setValue] = useState("");
  const debounced = useDebounce(value);

  useImperativeHandle(ref, () => ({
    onLeiChanged(item: ILei, index: number) {
      if (index >= 0 && index < leis.length && item) {
        const newLeis = [...leis];
        newLeis.splice(index, 1, item);
        setLeis(newLeis);
      }
    }
  }));

  const requestLei = useCallback(async (page: number) => {
    try {
      setLoading(true);
      const res = await requestLeis(page);
      setLeis(res);
    } catch (error) {
      console.log('--> Error in getting LEIs:', error);
    } finally {
      setLoading(false);
    }
  }, [requestLeis]);

  const pageChangeHandler = async ({ selected }: { selected: number }) => {
    requestLei(selected + 1)
    page.current = selected + 1;
  };

  useEffect(() => {
    requestLei(page.current);
  }, [requestLei]);

  useEffect(() => {
    if (debounced.length > 0) {
      setSearched(true);
    } else {
      requestLei(page.current);
      setSearched(false);
    }
  }, [requestLei, debounced]);

  // const filter = (value: string) => {
  //   return leis.filter((el: any) => {
  //     const correctValue = value.toLocaleLowerCase();
  //     return Object.keys(el).some((item) =>
  //       el[item].toLocaleLowerCase().includes(correctValue)
  //     );
  //   });
  // };

  const changeHandler = (e: any) => {
    const value = e.target.value;
    setValue(value);
    if (value !== "") {
      // setLeis(filter(debounced));
    } else {
      requestLei(page.current)
    }
  };

  const searchByLei = useCallback(async () => {
    if (debounced.length > 2) {
      setLoading(true);
      const res = await SearchLeiCode.searchLei(debounced);
      Promise.all(res.data.data.map(async (el: any) => {
        if(!el.relationships) return;
        return await SearchLeiCode.getLei(el.relationships["lei-records"].data.id);
      })).then(el => {
        const lei = transformLei(el.filter(element => element !== undefined).map(el => el.data.data));
        setLeis(lei);
      }).catch(console.log).finally(() => setLoading(false));
    }
  }, [debounced]);

  useEffect(() => {
    searchByLei();
  }, [searchByLei]);

  const rendered = renderedLei(leis);

  return (
    <>
      {enableInput && (
        <div className={cl["search__input"]}>
          <Input
            name="search"
            type="text"
            value={value}
            placeholder="Search for an LEI"
            onChange={changeHandler}
          />
          <LoupeButton>
            <LoupeIcon />
          </LoupeButton>
        </div>
      )}
      <Hr />
      <div className={cl["grid"]}>
        <div className={[cl["line"], headClassName].join(" ")}>
          {titles.map((el, index) => (
            <div className={joinClasses(cl["item"], cl[el])} key={`${index}`}>
              {el}
            </div>
          ))}
        </div>
        <div className={cl["inner__items"]}>
          {isLoading
            ? <Loader />
            : leis.length > 0
              ? rendered
              : <div className={cl["not-found"]}>No data found</div>
          }
        </div>

        {isPagination && pageCount && !isSearched && (
          <ReactPaginate
            breakLabel="&nbsp;...&nbsp;"
            nextLabel="&nbsp;Next >&nbsp;"
            pageRangeDisplayed={3}
            pageCount={pageCount}
            forcePage={page.current - 1}
            onPageChange={pageChangeHandler}
            breakClassName={cl["break__class-name"]}
            containerClassName={cl["container__paginate"]}
            previousLabel="&nbsp;<&nbsp;"
            pageClassName={cl["page__class-name"]}
            previousClassName={cl["previous__class-name"]}
            nextClassName={cl["next__class-name"]}
            activeClassName={cl["active__class-name"]}
          />
        )}
      </div>
    </>
  );
});
