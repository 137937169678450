import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';
import { theme } from "../../constants/theme";

export const FaqPage = styled.div`
    max-width: 100%;
    color: black;
    font-weight: 300;
    font-family: Roboto, sans-serif;
`;

export const NavLink = styled(Link)`
    color: hsla(0,0%,100%,.5);
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
        color: hsla(0,0%,100%,1);
    }
`;

export const Title = styled.h1`
    margin: 0 auto;
    color: ${theme.colors.blue.dark};
    text-align: left;
    margin-top: 40px;
    font-size: 3.5rem;
    @media (max-width: 1100px) {
        text-align: center;
        font-size: 2.5rem;
    }
    `

export const SubTitle = styled.p`
    margin: 0 auto;
    color: ${theme.colors.input.black};
    text-align: justify;
    word-wrap: break-word;
    margin-top: 40px;
    font-size: 1rem;
    line-height: 1.5;
    `

export const List = styled.ol`
    margin: 20px 0 0 20px
    `

export const Li = styled.li`
    margin: 0 auto;
    color: ${theme.colors.input.black};
    text-align: justify;
    font-size: 1rem;
    word-wrap: break-word;
    line-height: 1.5;
    `

export const LoupeButton = styled.button`
    width: 46px;
    height: 46px;
    background-color: #17a2b8;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    transition: all 0.3s;
    &:hover {
        background-color: #1c8b9c;
      }
  `;

export const Hr = styled.hr`
      margin-top: 30px;
      opacity: .4;
    `
