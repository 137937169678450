export interface LeiCodeState {
  dataLeiCode: any;
  loadingLeiCode: boolean;
  errorLeiCode: null | string;
}

export enum LeiCodeTypes {
  FETCH_LEICODE = "FETCH_LEICODE",
  FETCH_LEICODE_SUCCESS = "FETCH_LEICODE_SUCCESS",
  FETCH_LEICODE_ERROR = "FETCH_LEICODE_ERROR",
}

interface FetchLeiCodeAction {
  type: LeiCodeTypes.FETCH_LEICODE;
}

interface FetchLeiCodeSuccessAction {
  type: LeiCodeTypes.FETCH_LEICODE_SUCCESS;
  payload: any[];
}

interface FetchLeiCodeErrorAction {
  type: LeiCodeTypes.FETCH_LEICODE_ERROR;
  payload: string;
}

export type LeiCodeAction =
  | FetchLeiCodeAction
  | FetchLeiCodeSuccessAction
  | FetchLeiCodeErrorAction;
